import { useRef, FC } from "react";

import { Box, ScrollArea, createStyles } from "@mantine/core";

import { type MarketMapBE } from "@Lib/types/sectorCompass";

import MapLegend from "./MapLegend";
import MapRow from "./MapRow";

const useStyles = createStyles((theme, num_columns: number) => ({
  root: {
    flex: 1,
    padding: theme.spacing.xs,
    fontSize: theme.fontSizes.xs,
    backgroundColor: theme.white,
  },
  grid: {
    display: "grid",
    gridGap: theme.spacing.xs,
    gridTemplateColumns: `repeat(${num_columns}, auto)`,
  },
}));

type MarkepMapProps = {
  downloadId: string;
  data: MarketMapBE;
};

const MarkepMap: FC<MarkepMapProps> = ({ downloadId, data }) => {
  const { classes } = useStyles(data.num_columns);
  const startColRef = useRef(1);

  return (
    <Box id={downloadId} className={classes.root}>
      <ScrollArea>
        <Box className={classes.grid}>
          {data.rows.map(row => {
            startColRef.current = 1;

            return row.map(rowItem => {
              const startCol = startColRef.current;
              startColRef.current = startColRef.current + rowItem.col_width;

              return <MapRow key={rowItem.id} data={rowItem} startCol={startCol} />;
            });
          })}
        </Box>
      </ScrollArea>
      <MapLegend />
    </Box>
  );
};

export default MarkepMap;
