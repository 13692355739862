import { useState, useEffect } from "react";

import { type BaseChartItemUI, type DatasetTypes, type ChartDataTypes } from "@Lib/types/deals";
import { getComulativeData, getPercentageData } from "@Lib/utils/chart";

type HookInput<T> = {
  fundingDataset: T[];
  datasetType: DatasetTypes;
  chartDataType: ChartDataTypes;
  xRangeValue?: [number, number];
};

export const useDealsChartData = <T extends BaseChartItemUI>({
  fundingDataset,
  datasetType,
  chartDataType,
  xRangeValue,
}: HookInput<T>) => {
  const [dataset, setDataset] = useState(fundingDataset);

  useEffect(() => {
    let dataset = fundingDataset.slice();

    switch (chartDataType) {
      case "cumulative":
        dataset = getComulativeData(dataset);
        break;
      case "percentage":
        dataset = getPercentageData(dataset);
        break;
    }

    if (xRangeValue) {
      setDataset(dataset.slice(xRangeValue[0], xRangeValue[1] + 1));
      return;
    }

    setDataset(dataset);
    // datasetType dependency forces the chart to redraw with correct dataset
  }, [fundingDataset, datasetType, chartDataType, xRangeValue]);

  return dataset;
};
