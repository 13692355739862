import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import { type TranslationKeys } from "@Lib/i18n/config";
import { type FilterUI } from "@Lib/types/base";

/**
 * Custom hook that translates the given data array into an array of FilterUI objects.
 * Each FilterUI object contains a translated label based on the provided label key.
 *
 * @param data - The data array to be translated.
 * @returns An array of FilterUI objects with translated labels.
 */
export const useGetFilterUI = (data: Array<{ value: string; labelKey: TranslationKeys }>) => {
  const { t } = useTranslation();

  return useMemo<FilterUI[]>(
    () =>
      data.map(({ value, labelKey }) => ({
        value,
        label: t(labelKey),
      })),
    []
  );
};
