import { FC, CSSProperties } from "react";

import { Tooltip, Text, createStyles } from "@mantine/core";

import { PROJECTS_PROGRESS_CHART_CONFIG } from "@Components/charts/config";
import TrackRouteLink from "@Components/TrackRouteLink/TrackRouteLink";
import { Z_INDEX_MAP } from "@Lib/constants/ui";
import { type RaceToScaleCompanyUI } from "@Lib/types/sectorCompass";
import { generateLinkUrl } from "@Lib/utils/routes";

const { capacityFormatter } = PROJECTS_PROGRESS_CHART_CONFIG;

const useStyles = createStyles(
  (
    theme,
    { capacityOffset, statusColor, dotDiameter }: { capacityOffset: number; statusColor: string; dotDiameter: number }
  ) => ({
    dot: {
      position: "absolute",
      top: "50%",
      left: `${capacityOffset * 100}%`,
      transform: "translateY(-50%)",
      display: "inline-block",
      width: dotDiameter,
      height: dotDiameter,
      borderRadius: "50%",
      backgroundColor: statusColor,
      cursor: "pointer",
      transition: "box-shadow 0.2s ease-in-out",
      zIndex: Z_INDEX_MAP.raceToScale.dot,
      "&:hover": {
        zIndex: Z_INDEX_MAP.raceToScale.dotHover,
        boxShadow: `0 0 8px 6px ${statusColor}3c`,
      },
    },
  })
);

type ProjectDotProps = {
  project: RaceToScaleCompanyUI["projects"][0];
  dotDiameter: number;
  tooltipStyle: CSSProperties;
  handleMouseEnter: () => void;
  handleMouseLeave: () => void;
};

const ProjectDot: FC<ProjectDotProps> = ({
  project: { id, name, capacityOffset, statusColor, capacityValue, capacityUnits },
  dotDiameter,
  tooltipStyle,
  handleMouseEnter,
  handleMouseLeave,
}) => {
  const { classes } = useStyles({
    capacityOffset: capacityOffset === null ? 0.5 : capacityOffset,
    statusColor,
    dotDiameter,
  });

  const tooltipSuffix = capacityUnits ? `(${capacityFormatter(capacityValue)}${capacityUnits})` : "";

  return (
    <Tooltip
      offset={8}
      label={
        <Text weight={600}>
          {name}
          {tooltipSuffix.length > 0 && (
            <Text span weight={400}>
              &nbsp;{tooltipSuffix}
            </Text>
          )}
        </Text>
      }
      position="left"
      radius="sm"
      withArrow
      arrowPosition="side"
      arrowSize={8}
      style={tooltipStyle}
    >
      <TrackRouteLink
        to={generateLinkUrl("projectById", id)}
        className={classes.dot}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </Tooltip>
  );
};

export default ProjectDot;
