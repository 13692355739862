import { FC } from "react";

import { Menu } from "@mantine/core";
import { useTranslation } from "react-i18next";

import Dropdown from "@Components/Dropdown/Dropdown";
import { DOWNLOAD_IDS } from "@Lib/constants/ui";
import { useDownloadHTMLChunk } from "@Lib/hooks/common";

type DownloadButtonProps = {
  sectorId: number;
  marketName: string;
};

const DownloadButton: FC<DownloadButtonProps> = ({ marketName }) => {
  const { t } = useTranslation();

  const { handleDownload: handleProgressDownload, isLoading: isProgressLoading } = useDownloadHTMLChunk({
    elementId: DOWNLOAD_IDS.projectsProgressId,
    fileName: `${marketName} (pipeline by status chart)`,
    branded: true,
  });
  const { handleDownload: handleScaleDownload, isLoading: isScaleLoading } = useDownloadHTMLChunk({
    elementId: DOWNLOAD_IDS.projectsScaleId,
    fileName: `${marketName} (pipeline by scale chart)`,
    branded: true,
  });
  const { handleDownload: handleTechnologyDownload, isLoading: isTechnologyLoading } = useDownloadHTMLChunk({
    elementId: DOWNLOAD_IDS.projectsTechnologyId,
    fileName: `${marketName} (pipeline by technology chart)`,
    branded: true,
  });
  const { handleDownload: handleCountryDownload, isLoading: isCountryLoading } = useDownloadHTMLChunk({
    elementId: DOWNLOAD_IDS.projectsCountryId,
    fileName: `${marketName} (pipeline by geography chart)`,
    branded: true,
  });
  const { handleDownload: handleOperationalDateDownload, isLoading: isOperationalDateLoading } = useDownloadHTMLChunk({
    elementId: DOWNLOAD_IDS.projectsOperationalDateId,
    fileName: `${marketName} (pipeline by operational date chart)`,
    ignoreElements: true,
    branded: true,
  });
  const { handleDownload: handleRaceToScaleDownload, isLoading: isRaceToScaleLoading } = useDownloadHTMLChunk({
    elementId: DOWNLOAD_IDS.projectsRaceToScaleId,
    fileName: `${marketName} (race to scale by project chart)`,
    ignoreElements: true,
    branded: true,
  });
  const { handleDownload: handleRaceToScaleCompanyDownload, isLoading: isRaceToScaleCompanyLoading } =
    useDownloadHTMLChunk({
      elementId: DOWNLOAD_IDS.companiesRaceToScaleId,
      fileName: `${marketName} (race to scale by company chart)`,
      ignoreElements: true,
      branded: true,
    });

  const isLoading =
    isProgressLoading ||
    isScaleLoading ||
    isTechnologyLoading ||
    isCountryLoading ||
    isOperationalDateLoading ||
    isRaceToScaleLoading ||
    isRaceToScaleCompanyLoading;

  return (
    <Dropdown btnText={t("download.btnText")} position="bottom-end" isLoading={isLoading}>
      <Menu.Item onClick={handleProgressDownload}>
        {t("download.fileNames.sectorCompass.projectsProgressChart", {
          type: "jpg",
        })}
      </Menu.Item>
      <Menu.Item onClick={handleScaleDownload}>
        {t("download.fileNames.sectorCompass.projectsScaleChart", {
          type: "jpg",
        })}
      </Menu.Item>
      <Menu.Item onClick={handleTechnologyDownload}>
        {t("download.fileNames.sectorCompass.projectsTechnologyChart", {
          type: "jpg",
        })}
      </Menu.Item>
      <Menu.Item onClick={handleCountryDownload}>
        {t("download.fileNames.sectorCompass.projectsCountryChart", {
          type: "jpg",
        })}
      </Menu.Item>
      <Menu.Item onClick={handleOperationalDateDownload}>
        {t("download.fileNames.sectorCompass.projectsOperationalDateChart", {
          type: "jpg",
        })}
      </Menu.Item>
      <Menu.Item onClick={handleRaceToScaleDownload}>
        {t("download.fileNames.sectorCompass.projectsRaceToScaleChart", {
          type: "jpg",
        })}
      </Menu.Item>
      <Menu.Item onClick={handleRaceToScaleCompanyDownload}>
        {t("download.fileNames.sectorCompass.companiesRaceToScaleChart", {
          type: "jpg",
        })}
      </Menu.Item>
    </Dropdown>
  );
};

export default DownloadButton;
