import { useQuery } from "@tanstack/react-query";

import { QUERIES } from "@Lib/constants";
import { getProjectScaleFilterData } from "@Lib/services/filters";
import { mapEntityBEToFilterUI } from "@Lib/utils/queries";

export const useGetProjectScaleFilterData = () =>
  useQuery({
    queryKey: QUERIES.getFilterProjectScale(),
    queryFn: getProjectScaleFilterData,
    select: mapEntityBEToFilterUI,
    staleTime: Infinity,
  });
