import { FC } from "react";

import { Box, Text, createStyles } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { type TooltipProps } from "recharts";

import { type ProjectsChartTooltip } from "@Lib/types/sectorCompass";

import TooltipRow from "./TooltipRow";
import { getProjectsCountKey, extartKeyFromTotalKey } from "./utils";

const useStyles = createStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing.xs,
    border: theme.other.getDefaultBorder(theme),
    borderRadius: theme.radius.xs,
    background: theme.white,
    padding: theme.other.extraSpacing.xxs,
    fontSize: theme.fontSizes.xs,
    minWidth: "20ch",
  },
  title: {
    lineHeight: 1.2,
    fontWeight: theme.other.fontWeight.semiBold,
  },
}));

type TooltipContentProps = TooltipProps<number, string> & {
  withTitle: boolean;
  units: string;
  hideProjectsCount?: boolean;
  formatter: (value: number) => string;
  payload?: ProjectsChartTooltip[];
};

const TooltipContent: FC<TooltipContentProps> = ({
  withTitle,
  units,
  hideProjectsCount = false,
  formatter,
  payload,
  label,
}) => {
  const { t } = useTranslation();

  const { classes } = useStyles();

  if (!payload) return null;

  return (
    <Box className={classes.root}>
      {withTitle && <Text className={classes.title}>{label}</Text>}
      {payload.map(({ name, value, payload, color = "" }) => {
        if (!payload || !name || value === undefined) return null;
        const projectsCount = payload[getProjectsCountKey(name)];

        if (projectsCount === 0 && value === 0) {
          return null;
        }

        const foramtedValue = hideProjectsCount
          ? `${formatter(value)}${units}`
          : `${formatter(value)}${units} (${t("sectorCompassPage.sections.deployment.tooltipCount", {
              count: projectsCount,
            })})`;

        return <TooltipRow key={name} name={extartKeyFromTotalKey(name)} value={foramtedValue} borderColor={color} />;
      })}
    </Box>
  );
};

export default TooltipContent;
