import { useCallback, FC } from "react";

import DealsToggleBase from "@Components/charts/common/controls/DealsToggleBase";
import { useStageChartStore, useSectorChartStore } from "@Lib/store/sectorCompass/investment";

const DealsToggle: FC = () => {
  const countVisibleStage = useStageChartStore(state => !state.hiddenDataKeys.includes("dealsCount"));
  const countVisibleSector = useSectorChartStore(state => !state.hiddenDataKeys.includes("dealsCount"));
  const isCheckd = countVisibleStage && countVisibleSector;

  const setStageHiddenDataKey = useStageChartStore(state => state.setHiddenDataKey);
  const setSectorHiddenDataKey = useSectorChartStore(state => state.setHiddenDataKey);

  const handleOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setStageHiddenDataKey("dealsCount", !event.target.checked);
    setSectorHiddenDataKey("dealsCount", !event.target.checked);
  }, []);

  return <DealsToggleBase isCheckd={isCheckd} handleOnChange={handleOnChange} />;
};

export default DealsToggle;
