export const CHART_PAST_DATE_LIMIT = "2020-01-01";
export const SECTOR_COMPASS_PROJECT_OBSERVED_STATUSES = [1, 3, 4];

export enum TableSortDirEnum {
  asc = "ASC",
  desc = "DESC",
}

export enum DownloadFileTypesEnum {
  jpegImage = "JPEG",
  pdf = "PDF",
  excel = "XLSX",
}

export enum BEDataFormatEnum {
  json = "json",
  csv = "csv",
  excel = "xlsx",
}

/** Charts */
export enum DatasetTypesEnum {
  quarterYear = "quarter",
  halfYear = "half",
  year = "year",
  config = "all_time",
}

export enum DealsGroupByEnum {
  stage = "deal_type",
  sector = "sector",
}

export enum ProjectsGroupByEnum {
  country = "country",
  progress = "progress",
  scale = "scale",
  technology = "technology",
}
