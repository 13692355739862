import createStore from "./createStore";

type GlobalSearchStoreState = {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
};

export const useGlobalSearchStore = createStore<GlobalSearchStoreState>(
  set => ({
    searchTerm: "",
    setSearchTerm: searchTerm =>
      set(() => ({ searchTerm }), false, {
        type: "Global Search: setSearchTerm",
        payload: searchTerm,
      }),
  }),
  "Global Search Store"
);
