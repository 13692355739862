import { FC } from "react";

import { Text, Box, Group } from "@mantine/core";
import { useTranslation } from "react-i18next";

import { ReactComponent as Logo } from "@Assets/logo-sightline.svg";

const MapLegend: FC = () => {
  const { t } = useTranslation();

  return (
    <Group position="apart" mt={"sm"} mb={"xs"}>
      <Group spacing={"xs"}>
        <Text>{t("sectorCompassPage.sections.map.legend.title")}</Text>
        <Logo height={18} width={46} />
      </Group>
      <Group spacing={"sm"}>
        <Group spacing={4}>
          <Box bg={"indigo"} w={16} h={12} />
          <Text>{t("sectorCompassPage.sections.map.legend.areaLabel")}</Text>
        </Group>
        <Group spacing={4}>
          <Box
            sx={theme => ({
              backgroundColor: theme.other.compassSolutionColor,
            })}
            w={16}
            h={12}
          />
          <Text>{t("sectorCompassPage.sections.map.legend.solutionLabel")}</Text>
        </Group>
      </Group>
    </Group>
  );
};

export default MapLegend;
