import { createContext, useContext } from "react";

export type NavBarContextValues = {
  opened: boolean;
  toggleNavBar: () => void;
  openNavBar: () => void;
  closeNavBar: () => void;
};

export const NavBarContext = createContext<NavBarContextValues>(null!);

NavBarContext.displayName = "NavBarContext";

export const useNavBarContext = () => useContext(NavBarContext);
