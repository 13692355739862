import { forwardRef } from "react";

import { useLocation, useParams, Link, LinkProps } from "react-router-dom";

import { type LocationTrackRouteState } from "@Lib/types/base";

type TrackRouteLinkProps = LinkProps & {
  routeTitle?: string;
};

const TrackRouteLink = forwardRef<HTMLAnchorElement, TrackRouteLinkProps>(({ routeTitle, ...props }, ref) => {
  const { pathname } = useLocation();
  const params = useParams();

  const locationState: LocationTrackRouteState<typeof params> = {
    prevPath: pathname,
    params,
  };

  if (routeTitle) {
    locationState.prevPathTitle = routeTitle;
  }

  return <Link ref={ref} {...props} state={locationState} />;
});

TrackRouteLink.displayName = "TrackRouteLink";

export default TrackRouteLink;
