import { AxiosInstance, InternalAxiosRequestConfig, AxiosError } from "axios";

import { firebaseAuth } from "@Lib/config/firebase";
import { CustomError, type CustomErrorArgs } from "@Lib/types/error";
import { getAxiosHeaders } from "@Lib/utils/api";

export const handleOnPublicRequestFulfilled = async (req: InternalAxiosRequestConfig) => {
  return {
    ...req,
    headers: getAxiosHeaders(req),
  };
};

export const handleOnRequestFulfilled = async (req: InternalAxiosRequestConfig) => {
  const user = firebaseAuth.currentUser;

  if (!user) {
    throw new CustomError({
      type: "client_error",
      errors: [
        {
          code: "not_authenticated",
          detail: "Session is unauthorized",
        },
      ],
    });
  }

  const token = await user.getIdToken();

  return {
    ...req,
    headers: getAxiosHeaders(req, token),
  };
};

export const throwOnRejectedError = (err: AxiosError<CustomErrorArgs>) => {
  if (err?.response?.data) {
    if (err.request.responseType === "blob") {
      throw new CustomError({ errors: [{ code: "download", detail: "Download failed" }] });
    }
    throw new CustomError(err.response.data);
  }

  if (!err) {
    throw new Error("Unknown error occurred");
  }
};

export const getResponseOnRejected =
  (api: AxiosInstance) => async (err: AxiosError<CustomErrorArgs, { _retry?: boolean }>) => {
    throwOnRejectedError(err);

    if (err.status === 401 && err.config && (err.config.data === undefined || err.config.data._retry !== true)) {
      err.config.data = {
        ...(err.config.data ? err.config.data : {}),
        _retry: true,
      };

      const user = firebaseAuth.currentUser;

      if (user) {
        const token = await user.getIdToken();

        err.config.headers["Authorization"] = `Bearer ${token}`;

        return api.request(err.config);
      }
    }

    throw err;
  };
