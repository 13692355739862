import { type ChartDataKeys } from "@Lib/types/deals";
import { type ChartsStoreFilters } from "@Lib/types/sectorCompass";

import { getChartStore, getChartControlsStore } from "../factories/chart";
import { getFiltersStore, getAPIFiltersSelector } from "../factories/filters";

export const useChartsFilterStore = getFiltersStore<ChartsStoreFilters>("CS Charts Filters");
export const useStageChartStore = getChartStore<ChartDataKeys>("CS Stage Chart");
export const useSectorChartStore = getChartStore<ChartDataKeys>("CS Sector Chart");
export const useInvestmentChartsStore = getChartControlsStore("Sector Compass", "cumulative");

/**
 * Keys that must not be sent to BE
 */
const uiFieldsSet = new Set(["sectorIdsLocal"]);

export const filterForAPISelector = getAPIFiltersSelector(uiFieldsSet);
