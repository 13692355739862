import { FC } from "react";

import { type Marker } from "@googlemaps/markerclusterer";
import { Box } from "@mantine/core";
import { InfoWindow } from "@vis.gl/react-google-maps";

import Loader from "@Components/Loader/Loader";

type SingleProjectContentProps = {
  selectedMarker: Marker;
};

const LoadingContent: FC<SingleProjectContentProps> = ({ selectedMarker }) => {
  return (
    <InfoWindow anchor={selectedMarker} disableAutoPan>
      <Box w={35} h={35}>
        <Loader />
      </Box>
    </InfoWindow>
  );
};

export default LoadingContent;
