import { DEFAULT_PER_PAGE, DEFAULT_SORTING } from "@Lib/constants";
import { type TablesWithStore, type SortUI } from "@Lib/types/base";

import createStore from "./createStore";

const storeName = "Tables Store";
const INITAIL_PAGE = 1;

type InvestorsStoreState = {
  itemsPerPage: typeof DEFAULT_PER_PAGE;
  setItemsPerPage: (storeKey: TablesWithStore, perPage: string) => void;
  pages: Record<TablesWithStore, number>;
  setPage: (storeKey: TablesWithStore, page: number) => void;
  sorting: typeof DEFAULT_SORTING;
  setSorting: (storeKey: TablesWithStore, sort: SortUI) => void;
  clearData: (storeKey: TablesWithStore) => void;
};

export const useTablesStore = createStore<InvestorsStoreState>(
  set => ({
    /** Items per page data */
    itemsPerPage: DEFAULT_PER_PAGE,
    setItemsPerPage: (storeKey, value) =>
      set(
        state => ({
          itemsPerPage: {
            ...state.itemsPerPage,
            [storeKey]: parseInt(value),
          },
        }),
        false,
        {
          type: `${storeName}: setItemsPerPage`,
          payload: { storeKey, value },
        }
      ),
    /** Set current page data */
    pages: Object.keys(DEFAULT_PER_PAGE).reduce<Record<TablesWithStore, number>>(
      (accum, key) => ({
        ...accum,
        [key]: INITAIL_PAGE,
      }),
      {} as Record<TablesWithStore, number>
    ),
    setPage: (storeKey, page) =>
      set(
        state => ({
          pages: {
            ...state.pages,
            [storeKey]: page,
          },
        }),
        false,
        {
          type: `${storeName}: setPage`,
          payload: { storeKey, page },
        }
      ),
    /** Sorting state data */
    sorting: DEFAULT_SORTING,
    setSorting: (storeKey, sorting) =>
      set(
        state => ({
          sorting: {
            ...state.sorting,
            [storeKey]: sorting,
          },
        }),
        false,
        {
          type: `${storeName}: setSorting`,
          payload: { storeKey, sorting },
        }
      ),
    /** Clear data */
    clearData: storeKey =>
      set(
        state => ({
          pages: {
            ...state.pages,
            [storeKey]: INITAIL_PAGE,
          },
          sortings: {
            ...state.sorting,
            [storeKey]: DEFAULT_SORTING[storeKey],
          },
        }),
        false,
        {
          type: `${storeName}: clearData`,
          payload: storeKey,
        }
      ),
  }),
  storeName
);
