import { forwardRef } from "react";

import { Image, BoxProps } from "@mantine/core";
import { useTranslation } from "react-i18next";

import { useUserTrackingContext } from "@Context/UserTracking";
import { DownloadFileTypesEnum } from "@Lib/constants/api";
import { useBrowserZoom } from "@Lib/hooks/common";
import { SectorCompassUI } from "@Lib/types/sectorCompass";
import { downloadFile } from "@Lib/utils/download";

import SectionHolder from "../SectionHolder";

/**
 * Calculates image width based on the zoom level of the client's browser.
 * @param {number} zoomLevel - The zoom level of the client's browser.
 * @returns {string} - The width value of the image in percentage.
 */
const calculateImageWidth = (zoomLevel: number): string => {
  let width;
  switch (true) {
    case zoomLevel >= 1:
      width = "100%";
      break;
    case zoomLevel >= 0.9:
      width = "75%";
      break;
    case zoomLevel >= 0.8:
      width = "60%";
      break;
    case zoomLevel >= 0.7:
      width = "50%";
      break;
    default:
      width = "40%";
  }
  return width;
};

type MarketFrameworkSectionProps = BoxProps & {
  data: SectorCompassUI;
  marketName: string;
};

const MarketFrameworkSection = forwardRef<HTMLDivElement, MarketFrameworkSectionProps>(
  ({ data, marketName, ...boxProps }, ref) => {
    const { t } = useTranslation();
    const zoomLevel = useBrowserZoom();

    const imageWidth = calculateImageWidth(zoomLevel);
    const title = t("sectorCompassPage.sections.framework");

    const { trackDownload } = useUserTrackingContext();

    const getDownloadHandler = (tabTitle: string, imageUrl: string) => () => {
      const fileName = `${marketName} - ${tabTitle}`;
      downloadFile(imageUrl, fileName);

      trackDownload({
        fileName,
        fileType: DownloadFileTypesEnum.jpegImage,
      });
    };

    return (
      <SectionHolder
        ref={ref}
        title={title}
        onDownload={getDownloadHandler(title, data.frameworkSrc)}
        imgSrc={data.frameworkSrc}
        {...boxProps}
      >
        <Image src={data.frameworkSrc} alt={title} maw={imageWidth} style={{ alignSelf: "center" }} />
      </SectionHolder>
    );
  }
);

export default MarketFrameworkSection;
