import mixpanel from "mixpanel-browser";
import { type Params } from "react-router-dom";

import juneAnalytics from "@Lib/config/juneAnalytics";
import { ROUTE_TO_PAGE_NAME_MAP } from "@Lib/constants/routes";
import { USER_TRACKING_EVENTS } from "@Lib/constants/userTracking";
import { type FilterUI } from "@Lib/types/base";

export const clearMixpanelData = () => {
  mixpanel.reset();
  juneAnalytics.reset();
};

export const getTimeSpendOnPageTracker = (pageName: string) => ({
  start: () => {
    mixpanel.time_event(USER_TRACKING_EVENTS.timeSpendOnPage);
  },
  end: () => {
    mixpanel.track(USER_TRACKING_EVENTS.timeSpendOnPage, {
      page: pageName,
    });
  },
});

export const trackSightlineScroll = {
  start: () => {
    mixpanel.time_event(USER_TRACKING_EVENTS.scrollSightline);
  },
  end: (from: number, to: number) => {
    mixpanel.track(USER_TRACKING_EVENTS.scrollSightline, {
      fromPercentage: from,
      toPercentage: to,
    });
  },
};
/**
 * Handler that iteretaes over list of ids and returns labels for each id
 * @param ids Array of ids
 * @param objects Select options that match the ids
 * @returns Array of labels
 */
export const getLabelsByIds = (ids: string[], objects: FilterUI[]) =>
  ids.reduce<string[]>((labels, id) => {
    const obj = objects.find(o => o.value === id);
    if (obj && obj.label) {
      labels.push(obj.label);
    }
    return labels;
  }, []);

/**
 * Handler that produces a range string from two values
 * @param from start value
 * @param to end value
 * @returns a range string
 */
export const getFromToString = (from?: string | number, to?: string | number) => {
  if (from && to) {
    return `${from} - ${to}`;
  }
  if (from) {
    return `Above ${from}`;
  }
  if (to) {
    return `Below ${to}`;
  }
  return "";
};

/**
 * Handler that produces a label path, by appending to parent's label or label path the option's label
 * @param path path to the label
 * @param label option label
 * @returns a string of the path and label
 */
export const getLabelPath = (path: string, label: string) => {
  return `${path} -> ${label}`;
};

/**
 * Resolves the page name based on the provided pathname and route parameters.
 * @param {string} pathname - The current pathname to resolve the page name for.
 * @param {Params} params - Route parameters, typically extracted from the URL.
 * @returns {string | undefined} The resolved page name, or undefined if not found.
 */
export const resolvePageName = (pathname: string, params: Params): string | undefined => {
  const entriesArray = Object.entries(params);

  const mapKey =
    entriesArray.length > 0 ? pathname.replace(entriesArray[0][1] ?? "", `:${entriesArray[0][0]}`) : pathname;
  const pageName = ROUTE_TO_PAGE_NAME_MAP[mapKey];

  return pageName;
};
