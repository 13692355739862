/**
 * Returns an array of keys from the provided object, typed to ensure the keys correspond to the object.
 *
 * @template T - The type of the object.
 * @param {T} obj - The object from which to retrieve the keys.
 * @returns {(keyof T)[]} An array of the object's keys, typed as the keys of the object.
 */
export const getTypedKeys = <T extends object>(obj: T): (keyof T)[] => {
  return Object.keys(obj) as (keyof T)[];
};

/**
 * Returns an array of key-value pairs from the provided object, typed to ensure both keys and values correspond to the object.
 *
 * @template T - The type of the object.
 * @param {T} obj - The object from which to retrieve the key-value pairs.
 * @returns {[keyof T, T[keyof T]][]} An array of key-value pairs, where the keys are typed as the object's keys and values are typed as the corresponding values.
 */
export const getTypedEntries = <T extends object>(obj: T): [keyof T, T[keyof T]][] => {
  return Object.entries(obj) as [keyof T, T[keyof T]][];
};

/**
 * Returns an array of values from the provided object, typed to ensure the values correspond to the object.
 *
 * @template T - The type of the object.
 * @param {T} obj - The object from which to retrieve the values.
 * @returns {T[keyof T][]} An array of the object's values, typed as the values of the object.
 */
export const getTypedValues = <T extends object>(obj: T): T[keyof T][] => {
  return Object.values(obj) as T[keyof T][];
};
