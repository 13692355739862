import { FC } from "react";

import { Box, createStyles, BoxProps } from "@mantine/core";

const useStyles = createStyles(theme => ({
  root: {
    textAlign: "right",
    padding: theme.spacing.sm,
    backgroundColor: theme.white,
  },
}));

const LinkRow: FC<BoxProps> = ({ className, ...boxProps }) => {
  const { classes, cx } = useStyles();

  return <Box className={cx(classes.root, className)} {...boxProps} />;
};

export default LinkRow;
