import { FC } from "react";

import { Box, createStyles, MantineTheme, BoxProps } from "@mantine/core";

import { ReactComponent as ChevronIcon } from "@Assets/chevron.svg";

type ChevronExpandTypes = "light" | "default" | "indigo";

const getIconColor = (type: ChevronExpandTypes, expanded: boolean, theme: MantineTheme) => {
  switch (type) {
    case "light":
      return expanded ? theme.colors.grey[4] : theme.colors.grey[5];
    case "indigo":
      return expanded ? theme.colors.grey[3] : theme.colors.indigo[5];
    default:
      return expanded ? theme.colors.grey[3] : "inherit";
  }
};

const useStyles = createStyles(
  (theme, { expanded, type, disabled }: { expanded: boolean; type: ChevronExpandTypes; disabled: boolean }) => ({
    iconWrapper: {
      cursor: disabled ? "auto" : "pointer",
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
    },
    icon: {
      flex: "0 0 24px",
      color: getIconColor(type, expanded, theme),
      transform: expanded ? "rotateX(180deg)" : "rotateX(0deg)",
      transition: "transform .2s linear, color .2s linear",
    },
  })
);

type ChevronExpandProps = BoxProps & {
  type?: ChevronExpandTypes;
  expanded: boolean;
  handleExpand?: () => void;
  disabled?: boolean;
};

const ChevronExpand: FC<ChevronExpandProps> = ({
  type = "default",
  expanded,
  handleExpand,
  disabled = false,
  ...boxProps
}) => {
  const { classes } = useStyles({ expanded, type, disabled });

  return (
    <Box className={classes.iconWrapper} {...boxProps}>
      <ChevronIcon className={classes.icon} onClick={disabled ? undefined : handleExpand} />
    </Box>
  );
};

export default ChevronExpand;
