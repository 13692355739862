import { useCallback, useMemo, FC } from "react";

import { type FilterUI } from "@Lib/types/base";
import { type RaceToScaleCompanyUI } from "@Lib/types/sectorCompass";

import { useRaceToScaleContext } from "./context";
import RaceToScaleCell from "./RaceToScaleCell";
import RaceToScaleCompanyCell from "./RaceToScaleCompanyCell";

type RaceToScaleRowProps = {
  scales: FilterUI[];
  company: RaceToScaleCompanyUI;
};

const RaceToScaleRow: FC<RaceToScaleRowProps> = ({ scales, company: { projects, ...companyCellProps } }) => {
  const { id: ownerCompanyId } = companyCellProps;
  const { hoveredId, setHoveredId, unsetHoveredId } = useRaceToScaleContext();

  const projectsMap = useMemo(
    () =>
      scales.reduce<Record<string, RaceToScaleCompanyUI["projects"]>>((acc, scale) => {
        acc[scale.value] = projects.filter(({ scaleId }) => scaleId.toString() === scale.value);

        return acc;
      }, {}),
    [scales]
  );

  const handleMouseEnter = useCallback(() => setHoveredId(ownerCompanyId), [ownerCompanyId]);
  const handleMouseLeave = useCallback(() => unsetHoveredId(), []);

  const isHoverActive = ownerCompanyId === hoveredId;

  return (
    <>
      <RaceToScaleCompanyCell {...companyCellProps} />
      {scales.map(scale => {
        const projects = projectsMap[scale.value];

        return (
          <RaceToScaleCell
            key={scale.value}
            projects={projects}
            isHoverActive={isHoverActive}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
          />
        );
      })}
    </>
  );
};

export default RaceToScaleRow;
