import { type StorageKeys, type StorageDataUnion, type TablesTrackedInStorege } from "@Lib/types/base";

export const setItem = <T extends StorageKeys>(storageKey: T, data: Partial<StorageDataUnion<T>>) => {
  const prevData = getItem(storageKey) || {};

  localStorage.setItem(
    storageKey,
    JSON.stringify({
      ...prevData,
      ...data,
    })
  );
};

export const getItem = <T extends StorageKeys>(storageKey: T): StorageDataUnion<T> | undefined => {
  const result = localStorage.getItem(storageKey);

  if (result) {
    const parsed = JSON.parse(result);
    return parsed;
  }

  return;
};

export const resolveStoragePerPage = <T extends TablesTrackedInStorege>(
  storageKey: T,
  defaultPerPage: number
): number => {
  const storageDate = getItem(storageKey)?.perPage;

  if (!storageDate) {
    return defaultPerPage;
  }

  return parseInt(storageDate);
};
