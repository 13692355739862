import { FC } from "react";

import { type Marker } from "@googlemaps/markerclusterer";
import { InfoWindow } from "@vis.gl/react-google-maps";

import Divider from "@Components/Divider/Divider";
import { type ProjectUI } from "@Lib/types/projects";

import InfoContent from "./InfoContent";
import InfoContentHeader from "./InfoContentHeader";

type SingleProjectContentProps = {
  selectedMarker: Marker;
  project: ProjectUI;
};

const SingleProjectContent: FC<SingleProjectContentProps> = ({ selectedMarker, project }) => {
  return (
    <InfoWindow anchor={selectedMarker}>
      <InfoContentHeader
        logo={project.companies[0].logo}
        projectName={project.name}
        projectCountry={project.location}
      />
      <Divider m={0} />
      <InfoContent project={project} />
    </InfoWindow>
  );
};

export default SingleProjectContent;
