import { FC } from "react";

import DealsChartBase from "@Components/charts/DealsChartBase";
import { useInvestmentChartsStore, useStageChartStore } from "@Lib/store/sectorCompass/investment";
import { type ChartConfig } from "@Lib/types/base";
import { type ChartItemUI } from "@Lib/types/deals";

type DealsStageChartProps = Partial<{
  config: ChartConfig;
  fundingDataset: ChartItemUI[];
}>;

const DealsStageChart: FC<DealsStageChartProps> = ({ config = [], fundingDataset = [] }) => {
  const datasetType = useInvestmentChartsStore(state => state.datasetType);
  const chartDataType = useInvestmentChartsStore(state => state.chartDataType);
  const xRangeValue = useInvestmentChartsStore(state => state.xRangeValue);

  const hiddenDataKeys = useStageChartStore(state => state.hiddenDataKeys);
  const setMagnitudeInfo = useStageChartStore(state => state.setMagnitudeInfo);
  const magnitudeInfo = useStageChartStore(state => state.magnitudeInfo);

  return (
    <DealsChartBase
      config={config}
      fundingDataset={fundingDataset}
      hiddenDataKeys={hiddenDataKeys}
      datasetType={datasetType}
      chartDataType={chartDataType}
      xRangeValue={xRangeValue}
      setMagnitudeInfo={setMagnitudeInfo}
      magnitudeInfo={magnitudeInfo}
    />
  );
};

export default DealsStageChart;
