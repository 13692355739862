import { FC, useState } from "react";

import { Center } from "@mantine/core";
import { useTranslation } from "react-i18next";

import ErrorBox from "@Components/ErrorBox/ErrorBox";
import InfoButton from "@Components/InfoButton";
import NoDataMessage from "@Components/InvestmentDash/common/NoDataMessage";
import { DOWNLOAD_IDS, ProjectsGroupByEnum } from "@Lib/constants";
import { useGetOperationalChartData } from "@Lib/hooks/sectorCompass";

import { ProjectsChartContainer } from "../common";
import GroupByControl from "./GroupByControl";
import ProjectAreaChart from "./ProjectAreaChart";

type ProjectsOperationalDateChartProps = {
  sectorId: number;
  sectorPath: string[];
};

const ProjectsOperationalDateChart: FC<ProjectsOperationalDateChartProps> = ({ sectorId, sectorPath }) => {
  const { t } = useTranslation();
  const rootSectorId = parseInt(sectorPath[0]);

  const [value, setValue] = useState<ProjectsGroupByEnum>(ProjectsGroupByEnum.progress);
  const { isLoading, isError, data } = useGetOperationalChartData(sectorId, rootSectorId, value);

  return (
    <ProjectsChartContainer
      id={DOWNLOAD_IDS.projectsOperationalDateId}
      titleKey={"operational"}
      units={data?.units}
      buttons={<GroupByControl groupBy={value} handleOnChange={setValue} />}
      infoButton={
        <InfoButton
          contentTitle={t("sectorCompassPage.sections.deployment.operationalInfo.title")}
          content={t("sectorCompassPage.sections.deployment.operationalInfo.content")}
        />
      }
    >
      {isLoading ? (
        <Center h={"100%"}>{t("charts.loadingText")}</Center>
      ) : isError ? (
        <ErrorBox text={t("charts.errorText")} />
      ) : data.dataset.length === 0 ? (
        <NoDataMessage />
      ) : (
        <ProjectAreaChart units={data.units} dataset={data.dataset} config={data.config} />
      )}
    </ProjectsChartContainer>
  );
};

export default ProjectsOperationalDateChart;
