import { useCallback, FC } from "react";

import { useTranslation } from "react-i18next";

import CheckboxFilterBase from "@Components/charts/common/controls/CheckboxFilterBase";
import { useUserTrackingContext } from "@Context/UserTracking";
import { USER_TRACKING_PROPERTIES } from "@Lib/constants";
import { useGetProjectScaleFilterData } from "@Lib/hooks/filters";
import { useDeploymentFilterStore } from "@Lib/store/sectorCompass/deployment";

const ScaleFilter: FC = () => {
  const { t } = useTranslation();
  const { trackFilters } = useUserTrackingContext();

  const filterField = "project_scale_id";
  const { data = [] } = useGetProjectScaleFilterData();

  const filterValue = useDeploymentFilterStore(useCallback(state => state.filters[filterField] || [], [filterField]));
  const setFilter = useDeploymentFilterStore(state => state.setFilter);
  const resetFilters = useDeploymentFilterStore(state => state.resetFilters);

  const hendleSetFilterIDs = useCallback((value: typeof filterValue) => {
    if (!value || !value.length) {
      resetFilters([filterField]);
      return;
    }

    setFilter(filterField, value);

    trackFilters({
      filter: filterField,
      value: value,
      mechanism: USER_TRACKING_PROPERTIES.filtersMechanism.sectorCompassDeployment,
    });
  }, []);

  return (
    <CheckboxFilterBase
      config={data}
      label={t("sectorCompassPage.sections.deployment.filters.scale.placeholder")}
      filterIDs={filterValue}
      setFilterIDs={hendleSetFilterIDs}
      hightlight={Boolean(filterValue.length)}
    />
  );
};

export default ScaleFilter;
