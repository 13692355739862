import { cleanNotifications } from "@mantine/notifications";
import { useMutation } from "@tanstack/react-query";

import { firebaseLogout } from "@Lib/services/user";

export const useSignOut = () => {
  const { isLoading, mutate } = useMutation(firebaseLogout, {
    onMutate: () => {
      cleanNotifications();
    },
  });

  return { isLoading, mutate };
};
