import { FC, PropsWithChildren } from "react";

import { ModalsProvider as MantineModalsProvider } from "@mantine/modals";

import { LogoutModal, SubmitOrganisationSuccess } from "@Components/modals";

const ModalsProvider: FC<PropsWithChildren> = ({ children }) => (
  <MantineModalsProvider
    modals={{ logout: LogoutModal, submitOrganisation: SubmitOrganisationSuccess }}
    modalProps={{
      centered: true,
      padding: "sm",
    }}
  >
    {children}
  </MantineModalsProvider>
);

export default ModalsProvider;
