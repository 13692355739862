import { FC, PropsWithChildren } from "react";

import { Box, Center, Text, createStyles } from "@mantine/core";
import { useTranslation } from "react-i18next";

import ErrorBox from "@Components/ErrorBox/ErrorBox";

const useStyles = createStyles(theme => ({
  chart: {
    height: "40vh",
    width: "100%",
    fontSize: theme.fontSizes.sm,
    paddingTop: 10,
    overflow: "hidden",
  },
  chartLabel: {
    paddingLeft: theme.spacing.sm,
    fontSize: theme.fontSizes.sm,
    fontWeight: theme.other.fontWeight.semiBold,
    paddingTop: 10,
  },
}));

type ChartContainerProps = PropsWithChildren<{
  id?: string;
  label: string;
  labelAppendix?: string;
  isLoading: boolean;
  isError: boolean;
}>;

const ChartContainer: FC<ChartContainerProps> = ({ id, label, labelAppendix, isLoading, isError, children }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Box id={id}>
      <Text className={classes.chartLabel}>
        {label}
        {labelAppendix && (
          <>
            &nbsp;
            <Text span weight={400}>
              {labelAppendix}
            </Text>
          </>
        )}
      </Text>
      <Box className={classes.chart}>
        {isLoading ? (
          <Center h={"100%"}>{t("charts.loadingText")}</Center>
        ) : isError ? (
          <ErrorBox text={t("charts.errorText")} />
        ) : (
          children
        )}
      </Box>
    </Box>
  );
};

export default ChartContainer;
