import { FC } from "react";

import { Grid, Text, Flex, BoxProps } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import BasicChip, { type BasicChipVariants } from "@Components/BasicChip/BasicChip";
import { DATE_FORMAT_FULL_YEAR } from "@Lib/constants";
import { getFormattedDate } from "@Lib/utils/date";
import { formatMoney } from "@Lib/utils/formatters";
import { generateLinkUrl } from "@Lib/utils/routes";

// Define the discriminated types for rowData property
type Default = {
  dataType?: "default";
  data: Nullable<string>;
};

type LinkData = {
  dataType: "link";
  data: string;
};

type CompanyLinkData = {
  dataType: "companyLink";
  data: string;
  companyId: string;
};

type CompanyLinksData = {
  dataType: "companyLinks";
  data: { id: string; name: string }[];
};

type MoneyData = MakeNullable<
  {
    dataType: "money";
    data: number;
    maxValue?: number;
  },
  "dataType"
>;

type DateData = {
  dataType: "date";
  data: string;
};

type ChipsData = {
  dataType: "chips";
  data: Nullable<string[]>;
  chipVariant?: BasicChipVariants;
};

type MetricData = {
  dataType: "metric";
  data: number;
  metricUnit: string;
  maxValue?: Nullable<number>;
};

type EntityLinksData = {
  dataType: "entityLinks";
  data: Nullable<
    {
      id: string;
      name: string;
    }[]
  >;
};

// Main DetailsGridRowProps type
type DetailsGridRowProps = BoxProps & {
  rowData:
    | Default
    | LinkData
    | CompanyLinkData
    | CompanyLinksData
    | MoneyData
    | DateData
    | ChipsData
    | MetricData
    | EntityLinksData;
  title: string;
};

const DetailsGridRow: FC<DetailsGridRowProps> = ({ rowData, title, ...colProps }) => {
  const { t } = useTranslation();

  const { dataType, data } = rowData;

  if (data === null) {
    return null;
  }

  const getContent = () => {
    switch (dataType) {
      case "link":
        return (
          <Text component={Link} to={`${data}`} target="_blank" color="teal" style={{ wordBreak: "break-word" }}>
            {data}
          </Text>
        );

      case "companyLink":
        const { companyId } = rowData;
        return (
          <Text
            component={Link}
            to={generateLinkUrl("organizationById", companyId)}
            color="teal"
            style={{ wordBreak: "break-word" }}
          >
            {data}
          </Text>
        );

      case "companyLinks":
        return data.map(({ id, name }, idx) => (
          <Text
            key={id}
            component={Link}
            to={generateLinkUrl("organizationById", id)}
            color="teal"
            style={{ wordBreak: "break-word" }}
          >
            {name}
            {idx !== data.length - 1 && ", "}
          </Text>
        ));

      case "entityLinks":
        return data.map(({ id, name }, idx) => (
          <Text
            key={id}
            component={Link}
            to={generateLinkUrl("organizationById", id)}
            color="teal"
            style={{ wordBreak: "break-word" }}
          >
            {idx !== 0 && ", "}
            {name}
          </Text>
        ));

      case "money":
        if (data === 0) {
          return t("fundingUndisclosed");
        }

        return formatMoney(data);

      case "date":
        return getFormattedDate(data, DATE_FORMAT_FULL_YEAR) ?? t("noDataIndicator");

      case "chips":
        const { chipVariant } = rowData;
        return data.length ? (
          <Flex gap={4} wrap={"wrap"}>
            {data.map((datum, idx) => (
              <BasicChip key={idx} chipVariant={chipVariant}>
                {datum}
              </BasicChip>
            ))}
          </Flex>
        ) : (
          t("noDataIndicator")
        );

      case "metric":
        const { maxValue, metricUnit } = rowData;
        if (maxValue) {
          return `${data} - ${maxValue} ${metricUnit}`;
        }
        return `${data} ${metricUnit}`;

      default:
        return data || t("noDataIndicator");
    }
  };

  return (
    <>
      <Grid.Col span={8} mb={"xs"} {...colProps}>
        <Text color="grey.7" weight={600} transform="capitalize" component="span">
          {title}
        </Text>
      </Grid.Col>
      <Grid.Col span={16} mb={"xs"} pl={"sm"} {...colProps}>
        {getContent()}
      </Grid.Col>
    </>
  );
};

export default DetailsGridRow;
