import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  User,
  signInWithCustomToken,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";

import { firebaseAuth } from "@Lib/config/firebase";
import { type EntityBE } from "@Lib/types/base";
import {
  type UserBE,
  type UserBEMergedWithFBData,
  type SignInParams,
  type UserSignupInfoBE,
  type UserProfilePayload,
  type UserProfileBE,
} from "@Lib/types/user";

import { appApiPublic, goldApi, appApi } from "./api";

export const getServerTime = async () => {
  const { data } = await appApiPublic.get<{
    timestamp: number;
  }>("/time");

  return data;
};

//login user in firebase
export const firebaseSignIn = async ({ email, password }: SignInParams) => {
  await signInWithEmailAndPassword(firebaseAuth, email, password);
};

export const firebaseSignInCustomToken = (token: string) => signInWithCustomToken(firebaseAuth, token);

export const fetchMe = async (user: Nullable<User>): Promise<UserBEMergedWithFBData> => {
  if (!user) {
    return Promise.resolve(null);
  }

  const { email, metadata, uid } = user;
  const { data } = await appApi.post<UserBE>("/users/login", {
    email,
  });

  return Object.assign({ uid, metadata }, data);
};

export const firebaseLogout = async () => {
  await signOut(firebaseAuth);
};

export const firebaseResetPassword = async (email: string) => {
  await sendPasswordResetEmail(firebaseAuth, email);
};

export const firebaseReauthenticate = async (password: string) => {
  const currentUser = firebaseAuth.currentUser;
  if (currentUser && currentUser.email) {
    await reauthenticateWithCredential(currentUser, EmailAuthProvider.credential(currentUser.email, password));
  }
};

export const getSignupInfo = async (payload: { email: string; token: string }) => {
  const { data } = await appApiPublic.post<UserSignupInfoBE>("/users/signup", payload);

  return data;
};

export const getAccountInfo = async (localId: string) => {
  const { data } = await appApi.get<UserProfileBE>(`/users/${localId}`);

  return data;
};

export const getSearchedCity = async (search: string) => {
  const { data } = await goldApi.get<EntityBE[]>("/cities", {
    params: {
      page: 1,
      page_size: 0,
      search,
    },
  });

  return data;
};

export const updateUserProfile = async (id: string, payload: UserProfilePayload) => {
  if (payload.city_id === "other") {
    delete payload.city_id;
  }
  await appApi.patch<UserProfileBE>(`/users/${id}`, payload);
};

export const updatePassword = async (id: string, password: string) => {
  await appApi.patch<{
    password_updated_at: string;
  }>(`/users/${id}/set_password`, {
    password,
  });
};
