import { FC } from "react";

import { Box, Image, ImageProps } from "@mantine/core";

const ImagePlaceholder: FC<ImageProps> = ({ ...props }) => {
  return (
    <Box>
      <Image src={"/logo-placeholder.jpg"} {...props} />
    </Box>
  );
};

export default ImagePlaceholder;
