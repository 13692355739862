import { DATA_JOINER } from "@Lib/constants";
import { type NestedObject } from "@Lib/types/base";

export const buildCompositeIdsFromNestedObject = (data: NestedObject) => {
  const result: string[] = [];

  const traverse = (data: NestedObject, path = "") => {
    for (const key in data) {
      const newPath = path ? `${path}_${key}` : key;

      if (Object.keys(data[key]).length > 0) {
        traverse(data[key], newPath);
        continue;
      }
      result.push(newPath);
    }
  };

  traverse(data);
  return result;
};

export const buildNestedObject = (idsArr: string[], slice: NestedObject): NestedObject => {
  const result = Object.assign({}, slice);

  const currId = idsArr[0];
  if (idsArr.length === 1) {
    result[currId] = {};
  } else if (idsArr.length > 1) {
    result[currId] = {
      ...result[currId],
      ...buildNestedObject(idsArr.slice(1), result[currId]),
    };
  }

  return result;
};

export const deleteKeyFromNestedObject = (idsArr: string[], slice: NestedObject) => {
  const currentId = idsArr[0];
  if (idsArr.length === 1) {
    delete slice[currentId];
    return;
  }

  if (slice[currentId]) {
    deleteKeyFromNestedObject(idsArr.slice(1), slice[currentId]);
  }
};

export const getDeepestKeys = (obj: NestedObject, keysArray: string[] = []) => {
  for (const key in obj) {
    if (Object.keys(obj[key]).length > 0) {
      getDeepestKeys(obj[key], keysArray);
      continue;
    }
    keysArray.push(key);
  }
  return keysArray;
};

/**
 * A function that checks if a Taxonomy checkbox should be checked or not.
 *
 * @param value checkbox `value` of the format `parentId_parentId_childId`
 * @param checkedIds an object of checked ids in the format `{ parentId: { parentId: { childId: true } } }`
 * @returns checkbox checked state
 */
export const getChackboxState = (value: string, checkedIds: NestedObject = {}) => {
  const idsArr = value.split(DATA_JOINER);

  const checkIdsInNestedObject = (ids: string[], checkedIds: NestedObject): boolean => {
    if (ids.length === 0) {
      return true;
    }

    const currentId = ids[0];
    if (!checkedIds.hasOwnProperty(currentId)) {
      return false;
    }

    return checkIdsInNestedObject(ids.slice(1), checkedIds[currentId] as NestedObject);
  };

  return checkIdsInNestedObject(idsArr, checkedIds);
};
