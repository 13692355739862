import { useQuery } from "@tanstack/react-query";

import { getProjectsCountKey } from "@Components/SectorCompass/components/DeploymentSection/common/utils";
import { projectStatusById } from "@Context/Theme/chartsColors";
import { ProjectsGroupByEnum } from "@Lib/constants/api";
import { QUERIES } from "@Lib/constants/queries";
import { getProjectsChartData } from "@Lib/services/sectorCompass";
import { useDeploymentFilterStore } from "@Lib/store/sectorCompass/deployment";
import {
  type ProjectsChartRespponse,
  type ProjectsChartDatum,
  type ProjectsChartConfig,
} from "@Lib/types/sectorCompass";

type SelectorData = {
  units: string;
  dataset: ProjectsChartDatum[];
  config: ProjectsChartConfig[];
};

const dataSelector = ({ units, results }: ProjectsChartRespponse): SelectorData => {
  const fixDecimalSum = (sum: number) => {
    const roundingFactor = 100;

    return Math.round(sum * roundingFactor) / roundingFactor;
  };

  const baseItem = results.reduce(
    (accum, { breakdown: { name } }) => ({
      ...accum,
      [name]: 0,
      [getProjectsCountKey(name)]: 0,
    }),
    {}
  );

  const resultMap: Record<string, ProjectsChartDatum> = {};
  const config = new Map<string, ProjectsChartConfig>();

  for (const result of results) {
    const {
      group: { id: groupId, name: groupName },
      breakdown: { id, name },
      capacity_value,
      project_count,
    } = result;

    if (!resultMap[groupName]) {
      resultMap[groupName] = {
        group: groupName,
        total: 0,
        totalProjects: 0,
        keepLast: groupId === null,
        ...baseItem,
      } as ProjectsChartDatum;
    }

    resultMap[groupName][name] = fixDecimalSum(resultMap[groupName][name] + capacity_value);
    resultMap[groupName][getProjectsCountKey(name)] += project_count;
    resultMap[groupName].total = fixDecimalSum(resultMap[groupName].total + capacity_value);
    resultMap[groupName].totalProjects += project_count;

    if (!config.has(name) && id) {
      config.set(name, {
        dataKey: name,
        fill: projectStatusById[id],
      });
    }
  }

  return {
    units: units ?? "",
    dataset: Object.values(resultMap).sort((a, b) => {
      if (a.keepLast) return 1;
      if (b.keepLast) return -1;

      return b.total - a.total;
    }),
    config: Array.from(config.values()),
  };
};

export const useGetProjectsChartData = (sectorId: number, groupBy: ProjectsGroupByEnum, groupLimit?: number) => {
  const filters = useDeploymentFilterStore(state => state.filters);

  return useQuery({
    queryKey: QUERIES.getCompassProjectsChart(sectorId, groupBy, filters),
    queryFn: () => getProjectsChartData(sectorId, groupBy, filters, groupLimit),
    select: dataSelector,
  });
};
