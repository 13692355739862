import { FC } from "react";

import { Map as GoogleMap, ControlPosition } from "@vis.gl/react-google-maps";

import { useGetLegendColorMap } from "@Components/SectorCompass/hooks/useGetLegendColorMap";
import { useScrollwheelState } from "@Lib/hooks/common";
import { type DeploymentMapCoordinatesUI } from "@Lib/types/sectorCompass";

import CustomMarker from "./CustomMarker";
import Legend from "./Legend";

const mapId = import.meta.env.VITE_GOOGLE_MAPS_MAPID;
const style = `
.gm-style-iw {
  max-width: 350px !important;
}
.gm-style-iw-chr { 
  display: none; 
}`;

type DeploymentMapProps = {
  data: DeploymentMapCoordinatesUI;
  rootSectorId: number;
};

const Map: FC<DeploymentMapProps> = ({ data, rootSectorId }) => {
  const { legendColorMap } = useGetLegendColorMap(rootSectorId, data.legend);
  const { scrollwheelEnabled } = useScrollwheelState();

  return (
    <GoogleMap
      mapId={mapId}
      reuseMaps
      defaultCenter={{ lat: 22.54992, lng: 0 }}
      defaultZoom={3}
      minZoom={2}
      gestureHandling={"greedy"}
      disableDefaultUI
      scrollwheel={scrollwheelEnabled}
      zoomControl
      zoomControlOptions={{ position: ControlPosition.TOP_RIGHT }}
      clickableIcons={false}
    >
      <style>{style}</style>
      <CustomMarker points={data.markers} legendColorMap={legendColorMap} rootSectorId={rootSectorId} />
      <Legend legend={data.legend} legendColorMap={legendColorMap} />
    </GoogleMap>
  );
};

export default Map;
