import { FC } from "react";

import { Select, Text, createStyles } from "@mantine/core";
import { useTranslation } from "react-i18next";

import { ProjectsGroupByEnum } from "@Lib/constants";
import { useGetFilterUI } from "@Lib/hooks/common";
import { type TranslationKeys } from "@Lib/i18n/config";

const selectConfigData: Array<{
  value: ProjectsGroupByEnum;
  labelKey: TranslationKeys;
}> = [
  {
    value: ProjectsGroupByEnum.progress,
    labelKey: "sectorCompassPage.sections.deployment.operationalDateChart.selectOptions.status",
  },
  {
    value: ProjectsGroupByEnum.scale,
    labelKey: "sectorCompassPage.sections.deployment.operationalDateChart.selectOptions.scale",
  },
  {
    value: ProjectsGroupByEnum.technology,
    labelKey: "sectorCompassPage.sections.deployment.operationalDateChart.selectOptions.technology",
  },
  {
    value: ProjectsGroupByEnum.country,
    labelKey: "sectorCompassPage.sections.deployment.operationalDateChart.selectOptions.location",
  },
];

const useStyles = createStyles(theme => ({
  datasetControl: {
    width: 156,
    fontWeight: theme.other.fontWeight.regular,
    ...theme.other.getForceInputHeight(30),
  },
  prefix: {
    color: theme.colors.grey[9],
    paddingLeft: theme.spacing.xs,
    flexGrow: 0,
  },
}));

type GroupByControlProps = {
  groupBy: ProjectsGroupByEnum;
  handleOnChange: (value: ProjectsGroupByEnum) => void;
};

const GroupByControl: FC<GroupByControlProps> = ({ groupBy, handleOnChange }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const translatedGroupByData = useGetFilterUI(selectConfigData);

  return (
    <Select
      data={translatedGroupByData}
      value={groupBy}
      onChange={handleOnChange}
      className={classes.datasetControl}
      icon={
        <Text className={classes.prefix}>
          {t("sectorCompassPage.sections.deployment.operationalDateChart.valuePrefix")}
        </Text>
      }
      iconWidth={60}
    />
  );
};

export default GroupByControl;
