import { Text, type LabelProps } from "recharts";

import { type ProjectsChartDatum } from "@Lib/types/sectorCompass";

type BarLabelProps = LabelProps & {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  dataset: ProjectsChartDatum[];
};

const BarLabel = (props: BarLabelProps) => {
  const {
    value = "",
    formatter = (value: number) => value,
    x = 0,
    y = 0,
    width = 0,
    offset = 0,
    height = 0,
    fill,
    fontSize,
    fontWeight,
    dataset,
  } = props;

  const datum = dataset.find(d => d.group === value);

  if (!datum) {
    return null;
  }

  const adjustedX = x + width + offset;
  const adjustedY = y + height / 2;

  const labelText = `${formatter(datum.total)} (${datum.totalProjects})`;

  return (
    <Text
      x={adjustedX}
      y={adjustedY}
      fill={fill}
      fontSize={fontSize}
      fontWeight={fontWeight}
      textAnchor="start"
      verticalAnchor="middle"
    >
      {labelText}
    </Text>
  );
};

export default BarLabel;
