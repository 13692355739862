import { FC } from "react";

import { Box, Text, createStyles } from "@mantine/core";
import { useTranslation } from "react-i18next";

import NoDataMessageBase from "@Components/charts/common/NoDataMessageBase";
import ErrorBox from "@Components/ErrorBox/ErrorBox";
import InfoButton from "@Components/InfoButton";
import Loader from "@Components/Loader/Loader";
import { DOWNLOAD_IDS } from "@Lib/constants/ui";
import { useGetProjectScaleFilterData } from "@Lib/hooks/filters";
import { useGetRaceToScaleCompanies } from "@Lib/hooks/sectorCompass";

import { RtSInfoContent } from "../common";
import RaceToScaleGrid from "./RaceToScaleGrid";

const useStyles = createStyles(theme => {
  return {
    container: {
      fontSize: theme.fontSizes.sm,
      flexShrink: 0,
      flexBasis: "100%",
      width: "100%",
    },
    titleRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "nowrap",
      padding: theme.spacing.sm,
    },
    title: {
      display: "flex",
      alignItems: "center",
      fontWeight: theme.other.fontWeight.bold,
    },
    content: {
      paddingInline: theme.spacing.sm,
      paddingBottom: theme.spacing.sm,
    },
  };
});

type RaceToScaleCompaniesProps = {
  sectorId: number;
};

const RaceToScaleCompanies: FC<RaceToScaleCompaniesProps> = ({ sectorId }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const scaleQuery = useGetProjectScaleFilterData();
  const dataQuery = useGetRaceToScaleCompanies(sectorId, "0");

  return (
    <Box id={DOWNLOAD_IDS.companiesRaceToScaleId} className={classes.container}>
      <Box className={classes.titleRow}>
        <Box className={classes.title}>
          <Text>{t("sectorCompassPage.sections.deployment.raceToScaleCompany")}</Text>
          <InfoButton
            autoWidth
            withinPortal
            position="bottom-start"
            contentTitle={t("sectorCompassPage.sections.deployment.operationalInfo.title")}
            content={
              <RtSInfoContent subtitle={t("sectorCompassPage.sections.deployment.raceToScaleInfo.subtitleCompanies")} />
            }
          />
        </Box>
      </Box>
      <Box className={classes.content}>
        {scaleQuery.isLoading || dataQuery.isLoading ? (
          <Loader />
        ) : scaleQuery.isError || dataQuery.isError ? (
          <ErrorBox text={t("errors.loadingData")} />
        ) : dataQuery.data.dataset.length === 0 ? (
          <NoDataMessageBase />
        ) : (
          <RaceToScaleGrid dataScales={scaleQuery.data} data={dataQuery.data.dataset} />
        )}
      </Box>
    </Box>
  );
};

export default RaceToScaleCompanies;
