import {
  stageBarsById,
  sectorBarsById,
  sectorDarkerBars,
  sectorLighterBars,
  dealsCountLine as dealsCountColor,
} from "@Context/Theme/chartsColors";
import { SECTOR_GROUP_OTHER_ID, DATA_JOINER } from "@Lib/constants";
import { type ChartDatum } from "@Lib/types/base";
import {
  type DatasetTypes,
  type DealsChartItemBE,
  type BaseChartItemUI,
  type ChartItemUI,
  type DealsChartResponse,
} from "@Lib/types/deals";
import { type MakeKeysOptional } from "@Lib/types/utils";
import { generateDarkToLightColorShades } from "@Lib/utils/colors";

export const getSliderStep = (dataLength: number) => 100 / (dataLength - 1);

export const getCorrectionValue = (prevDatasetType: DatasetTypes, currtDatasetType: DatasetTypes) => {
  const checkSet = new Set([prevDatasetType, currtDatasetType]);
  if ((checkSet.has("quarterYear") && checkSet.has("halfYear")) || (checkSet.has("halfYear") && checkSet.has("year"))) {
    return 2;
  }
  if (checkSet.has("quarterYear") && checkSet.has("year")) {
    return 4;
  }

  return null;
};

export const getComulativeData = <T extends Record<string, string | number>>(data: T[]) => {
  const accumulate = (accum: T[], item: T) => {
    const prev = accum[accum.length - 1];
    if (!prev) {
      return [item];
    }

    const cumulative = { ...item } as T; // Type assertion

    Object.keys(item).forEach(key => {
      const fKey = key as keyof T; // Type assertion
      const value = item[fKey];
      const prevValue = prev[fKey];
      if (fKey !== "dateRange" && typeof value === "number" && typeof prevValue === "number") {
        cumulative[fKey] = (value + prevValue) as T[keyof T]; // Type assertion
      }
    });

    return [...accum, cumulative];
  };

  return data.reduce(accumulate, []);
};

export const getPercentageData = <T extends Record<string, string | number>>(data: T[]) => {
  const mapper = (item: T) => {
    const result = { ...item } as T; // Type assertion;
    const total = result.total;

    Object.keys(item).forEach(key => {
      const fKey = key as keyof T; // Type assertion
      if (fKey === "dateRange" || fKey === "dealsCount") {
        return;
      }

      if (fKey === "total") {
        result[fKey] = 100 as T[keyof T]; // Type assertion;
        return;
      }

      const value = result[fKey];
      if (typeof total === "number" && typeof value === "number") {
        if (total === 0) {
          result[fKey] = 0 as T[keyof T]; // Type assertion;
          return;
        }
        result[fKey] = ((value / total) * 100) as T[keyof T]; // Type assertion;
      }
    });

    return result;
  };

  return data.map(mapper);
};

export const getFilterIDsUpdate = (
  checked: boolean,
  groupId: string,
  currFilterList: string[],
  allFilterIDs: string[]
) => {
  let result: string[] = [];
  if (checked) {
    // We have active filtration add id to filter list
    result = [...currFilterList, groupId];
  } else {
    // Option unchecked
    if (currFilterList.length) {
      // We have active filtration, therefore some options are unchecked,
      // remove id from the filtration list
      result = currFilterList.filter(id => id !== groupId);
    } else {
      // All options are checked, we need to add their ids for filtration except the current id
      result = allFilterIDs.filter(id => id !== groupId);
    }
  }

  return result.sort();
};

export const isMaxFiltrationReached = (numHiddenBars: number, numPossibleOptions: number) => {
  // We don't allow unchecking the LAST option, update in case more than one option should stay active
  const MIN_ACTIVE_OPTIONS = 1;
  return numHiddenBars === numPossibleOptions - MIN_ACTIVE_OPTIONS;
};

export const getChartDatumBase = (data: DealsChartItemBE[]) =>
  data.reduce((accum, item) => {
    return {
      ...accum,
      [item.group.name]: 0,
    };
  }, {});

export const getDealsStageChartConfig = (data: MakeKeysOptional<DealsChartItemBE, "period">[]) => {
  const configSet = data.reduce((acc, item) => {
    const {
      group: { path, name, id },
    } = item;

    const groupId = id ?? SECTOR_GROUP_OTHER_ID;
    acc.set(name, {
      color: stageBarsById[groupId],
      dataKey: name,
      groupId: groupId.toString(),
      display: "bar",
      sectorTaxonomyIds: (path ?? []).map(path => path.join(DATA_JOINER)),
    });

    return acc;
  }, new Map<string, ChartDatum>());

  configSet.set("dealsCount", {
    color: dealsCountColor,
    dataKey: "dealsCount",
    display: "line",
  });

  return Array.from(configSet.values());
};

export const getDealsSectorChartConfig = (data: DealsChartItemBE[]) => {
  let shadeBaseColor = false;
  let baseColor = sectorBarsById[999];

  const configSet = data.reduce((acc, item) => {
    const {
      group: { path, name, id },
    } = item;
    const verticalId = path ? path[0][0] : undefined;
    const color = verticalId ? sectorBarsById[verticalId] : sectorBarsById[999];
    const prevItem = Array.from(acc.values())[acc.size - 1];

    if (prevItem && color === prevItem.color) {
      shadeBaseColor = true;
      baseColor = color;
    }

    acc.set(name, {
      color,
      dataKey: name,
      groupId: (id ?? SECTOR_GROUP_OTHER_ID).toString(),
      display: "bar",
      sectorTaxonomyIds: (path ?? []).map(path => path.join(DATA_JOINER)),
    });

    return acc;
  }, new Map<string, ChartDatum>());

  if (shadeBaseColor) {
    const shades = generateDarkToLightColorShades({
      baseColor,
      endDarkColor: sectorDarkerBars[baseColor],
      endLightColor: sectorLighterBars[baseColor],
      count: configSet.size,
    });

    let idx = 0;
    configSet.forEach(configItem => {
      configItem.color = shades[idx];
      idx++;
    });
  }

  configSet.set("dealsCount", {
    color: dealsCountColor,
    dataKey: "dealsCount",
    display: "line",
  });

  return Array.from(configSet.values());
};

export const chartDataSelector = (response: DealsChartResponse): ChartItemUI[] => {
  const objFunding: Record<string, ChartItemUI> = {},
    chartDatumBase = getChartDatumBase(response.results);

  for (const item of response.results) {
    const dealsCount = item.deal_count;
    const funding = item.funding_usd;
    const { label: periodLabel, start_date, end_date } = item.period;

    // Count funding
    if (!objFunding[periodLabel]) {
      const base: BaseChartItemUI = {
        dateRange: periodLabel,
        dateRangeStart: start_date,
        dateRangeEnd: end_date,
        dealsCount,
        total: funding,
      };

      objFunding[periodLabel] = { ...base, ...chartDatumBase, [item.group.name]: funding } as ChartItemUI;
      continue;
    }

    const accumDealsCount = objFunding[periodLabel].dealsCount + dealsCount;
    const accumTotal = objFunding[periodLabel].total + funding;

    objFunding[periodLabel] = {
      ...objFunding[periodLabel],
      dealsCount: accumDealsCount,
      total: accumTotal,
      [item.group.name]: funding,
    } as ChartItemUI;
  }

  return Object.values(objFunding);
};
