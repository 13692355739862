import { FC } from "react";

import { Center } from "@mantine/core";
import { useTranslation } from "react-i18next";

import NoDataMessageBase from "@Components/charts/common/NoDataMessageBase";
import ErrorBox from "@Components/ErrorBox/ErrorBox";
import { ProjectsGroupByEnum } from "@Lib/constants/api";
import { DOWNLOAD_IDS } from "@Lib/constants/ui";
import { useGetProjectsChartData } from "@Lib/hooks/sectorCompass";

import { ProjectsChartContainer, ProjectsChart } from "./common";

type ProjectsCountryChartProps = {
  sectorId: number;
};

const ProjectsCountryChart: FC<ProjectsCountryChartProps> = ({ sectorId }) => {
  const { t } = useTranslation();

  const { isLoading, isError, data } = useGetProjectsChartData(sectorId, ProjectsGroupByEnum.country);

  return (
    <ProjectsChartContainer
      id={DOWNLOAD_IDS.projectsCountryId}
      titleKey={ProjectsGroupByEnum.country}
      units={data?.units}
    >
      {isLoading ? (
        <Center h={"100%"}>{t("charts.loadingText")}</Center>
      ) : isError ? (
        <ErrorBox text={t("charts.errorText")} />
      ) : data.dataset.length === 0 ? (
        <NoDataMessageBase />
      ) : (
        <ProjectsChart units={data.units} dataset={data.dataset} config={data.config} />
      )}
    </ProjectsChartContainer>
  );
};

export default ProjectsCountryChart;
