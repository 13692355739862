import { useCallback, FC } from "react";

import { type Marker } from "@googlemaps/markerclusterer";
import { Box, Tooltip, createStyles } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { AdvancedMarker } from "@vis.gl/react-google-maps";

import { type Point } from "@Lib/types/sectorCompass";
import { getContrastColor } from "@Lib/utils/colors";

const useStyles = createStyles((theme, { color, numProjects }: { color: string; numProjects: number }) => {
  const getMarkerDiameter = (numProjects: number) => (numProjects > 1 ? 18 : 14);

  return {
    marker: {
      width: getMarkerDiameter(numProjects),
      height: getMarkerDiameter(numProjects),
      backgroundColor: color,
      borderRadius: "50%",
      border: "1px solid white",
      ...(numProjects <= 1
        ? {}
        : {
            "&:after": {
              content: `"${numProjects}"`,
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              top: "50%",
              left: "50%",
              width: "100%",
              height: "100%",
              transform: "translate(-50%, -50%)",
              color: getContrastColor(color),
              fontSize: theme.fontSizes.xs,
            },
          }),
    },
  };
});

type TreeMarkerProps = {
  point: Point;
  onClick: (tree: Point) => void;
  setMarkerRef: (marker: Marker | null, key: string) => void;
  color: string;
};

const PointMarker: FC<TreeMarkerProps> = ({ point, onClick, color, setMarkerRef }) => {
  const [tooltipOpened, handlers] = useDisclosure(false);

  const numProjects = point.projects.length;
  const { classes } = useStyles({ color, numProjects });
  const handleClick = useCallback(() => {
    handlers.close();
    onClick(point);
  }, [onClick, point]);

  const ref = useCallback(
    (marker: google.maps.marker.AdvancedMarkerElement) => setMarkerRef(marker, point.id),
    [setMarkerRef, point.id]
  );

  if (numProjects === 1) {
    return (
      <AdvancedMarker ref={ref} position={point} onClick={handleClick}>
        <Tooltip
          opened={tooltipOpened}
          label={point.projects[0].name}
          radius="sm"
          withArrow
          arrowSize={8}
          withinPortal
          style={{
            backgroundColor: "white",
            borderRadius: "4px",
            boxShadow: "0px 1px 12px 0px #0000001F",
            color: "black",
            fontSize: 10,
            padding: "6px 8px",
            fontWeight: 600,
          }}
        >
          <Box className={classes.marker} onMouseEnter={handlers.open} onMouseLeave={handlers.close} />
        </Tooltip>
      </AdvancedMarker>
    );
  }

  return (
    <AdvancedMarker ref={ref} position={point} onClick={handleClick}>
      <Box className={classes.marker} />
    </AdvancedMarker>
  );
};

export default PointMarker;
