import { FC } from "react";

import { useTranslation } from "react-i18next";

import BarsFilterControlBase from "@Components/charts/common/controls/BarsFilterControlBase";
import { useChartsFilterStore } from "@Lib/store/sectorCompass/investment";
import { type ChartConfig } from "@Lib/types/base";

type StageControlProps = {
  config: ChartConfig;
};

const StageControl: FC<StageControlProps> = ({ config }) => {
  const { t } = useTranslation();

  // Filters data is shared for both Home Charts
  const filterIDs = useChartsFilterStore(state => state.filters.deal_type_id ?? []);
  const setFilterIDs = useChartsFilterStore(state => state.setFilter);

  const hendleSetFilterIDs = (filterIds: string[]) => {
    setFilterIDs("deal_type_id", filterIds);
  };

  return (
    <BarsFilterControlBase
      config={config}
      label={t("charts.dealsCount.dealsByStage.label")}
      filterIDs={filterIDs}
      setFilterIDs={hendleSetFilterIDs}
    />
  );
};

export default StageControl;
