import { FC, ReactNode } from "react";

import { Flex, Text, createStyles } from "@mantine/core";
import { useTranslation } from "react-i18next";

const useStyles = createStyles(theme => ({
  root: {
    height: "100%",
    minHeight: "inherit",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing.xl,
    fontSize: theme.fontSizes.sm,
  },
}));

type NoDataMessageBaseProps = {
  noDataDescription?: ReactNode;
};

const NoDataMessageBase: FC<NoDataMessageBaseProps> = ({ noDataDescription }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Flex className={classes.root}>
      {t("charts.noData")}
      {noDataDescription && (
        <Text mt={"sm"} align="center">
          {noDataDescription}
        </Text>
      )}
    </Flex>
  );
};

export default NoDataMessageBase;
