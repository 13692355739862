import { FC } from "react";

import { Box, Text, createStyles } from "@mantine/core";
import { Surface, Rectangle } from "recharts";

import { CHARTS_CONFIG } from "@Components/charts/config";

const useStyles = createStyles(theme => ({
  root: {
    marginRight: -theme.spacing.xs,
    textAlign: "right",
    lineHeight: 1,
  },
  item: {
    display: "inline-flex",
    alignItems: "center",
    gap: theme.other.extraSpacing.xxs,
    marginInline: theme.spacing.xs,
  },
}));

type LegendContentProps = {
  payload?: {
    value: string;
    color: string;
  }[];
  noteText?: string;
  legendValueFixer?: (value: string) => string;
};

const LegendContent: FC<LegendContentProps> = ({ payload, noteText, legendValueFixer }) => {
  const { classes } = useStyles();

  if (!payload) {
    return null;
  }

  if (noteText) {
    return (
      <Box className={classes.root}>
        <Text span size={CHARTS_CONFIG.mainFontSize}>
          {noteText}
        </Text>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      {payload.map(({ color, value }) => {
        const legendLabel = legendValueFixer ? legendValueFixer(value) : value;

        return (
          <Box key={value} className={classes.item}>
            <Surface width={CHARTS_CONFIG.legend.iconConfig.rect.w} height={CHARTS_CONFIG.legend.iconConfig.rect.h}>
              <Rectangle
                width={CHARTS_CONFIG.legend.iconConfig.rect.w}
                height={CHARTS_CONFIG.legend.iconConfig.rect.h}
                fill={color}
              />
            </Surface>
            <Text span size={CHARTS_CONFIG.mainFontSize}>
              {legendLabel}
            </Text>
          </Box>
        );
      })}
    </Box>
  );
};

export default LegendContent;
