import { FC, PropsWithChildren } from "react";

import { QueryClient, QueryClientProvider, QueryObserverOptions } from "@tanstack/react-query";

import { CustomError } from "@Lib/types/error";
import { formatError } from "@Lib/utils/errorHandlers";
import { showErrorNotification } from "@Lib/utils/notifications";

const refetchOnWindowFocus = process.env.NODE_ENV === "production";

const onError = (e: unknown) => {
  const translatedError = formatError(e);

  return showErrorNotification({ id: "query-error", message: translatedError });
};

const retryHandler: QueryObserverOptions["retry"] = (failureCount, err) => {
  if (err instanceof CustomError && err.status === "timestamp_out_of_range" && failureCount === 1) {
    return false;
  }

  // First call has failureCount = 0
  if (failureCount >= 2) {
    return false;
  }

  return true;
};

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError,
      retry: 1,
    },
    queries: {
      onError,
      refetchOnWindowFocus,
      retry: retryHandler,
      retryDelay: 1000,
    },
  },
});

const QueryProvider: FC<PropsWithChildren> = ({ children }) => {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

export default QueryProvider;
