import { createContext, useContext } from "react";

export type WebWorkerContextValues = {
  worker: Worker | null;
};

export const WebWorkerContext = createContext<WebWorkerContextValues>(null!);

WebWorkerContext.displayName = "WebWorkerContext";

export const useWebWorkerContext = () => useContext(WebWorkerContext);
