import { FC } from "react";

import { Box, Image, Text, createStyles, BoxProps } from "@mantine/core";

import ImagePlaceholder from "@Components/ImagePlaceholder/ImagePlaceholder";

const useStyles = createStyles(theme => ({
  root: {
    display: "flex",
    gap: theme.spacing.sm,
    flexWrap: "nowrap",
    paddingBlock: theme.spacing.sm,
    width: "100%",
    maxWidth: "250px",
  },
  projectName: {
    fontWeight: theme.other.fontWeight.bold,
  },
}));

type InfoContentHeaderProps = BoxProps & {
  logo: string;
  projectName: string;
  projectCountry: string;
};

const InfoContentHeader: FC<InfoContentHeaderProps> = ({ logo, projectName, projectCountry, ...boxProps }) => {
  const { classes } = useStyles();
  return (
    <Box className={classes.root} {...boxProps}>
      <Image src={logo} width={40} height={40} fit="contain" withPlaceholder placeholder={<ImagePlaceholder />} />
      <Box>
        <Text className={classes.projectName}>{projectName}</Text>
        <Text>{projectCountry}</Text>
      </Box>
    </Box>
  );
};

export default InfoContentHeader;
