import axios from "axios";

import { BEDataFormatEnum } from "@Lib/constants/api";

import {
  handleOnPublicRequestFulfilled,
  handleOnRequestFulfilled,
  getResponseOnRejected,
  throwOnRejectedError,
} from "./interceptors";

const getOptions = (urlAppendix: string) => ({
  baseURL: import.meta.env.VITE_API_URL + urlAppendix,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
  paramsSerializer: {
    indexes: null,
  },
  params: {
    format: BEDataFormatEnum.json,
  },
});

const goldApiPublic = axios.create(getOptions("/v1/gold/public"));
goldApiPublic.interceptors.request.use(handleOnPublicRequestFulfilled);
goldApiPublic.interceptors.response.use(null, throwOnRejectedError);

const appApiPublic = axios.create(getOptions("/v1/app"));
appApiPublic.interceptors.request.use(handleOnPublicRequestFulfilled);
appApiPublic.interceptors.response.use(null, throwOnRejectedError);

const goldApi = axios.create(getOptions("/v1/gold"));
goldApi.interceptors.request.use(handleOnRequestFulfilled);
goldApi.interceptors.response.use(null, getResponseOnRejected(goldApi));

const appApi = axios.create(getOptions("/v1/app"));
appApi.interceptors.request.use(handleOnRequestFulfilled);
appApi.interceptors.response.use(null, getResponseOnRejected(appApi));

export { goldApiPublic, appApiPublic, goldApi, appApi };
