import { SectorCompassesSortingEnum } from "@Lib/config/sectorCompass";
import { ProjectsGroupByEnum, SECTOR_COMPASS_PROJECT_OBSERVED_STATUSES } from "@Lib/constants/api";
import { type SectorFilterBE } from "@Lib/types/filters";
import {
  type SectorCompassListResponse,
  type SectorCompassBE,
  type MarketMapBE,
  type SectorCompassParams,
  type DeploymentMapResponse,
  type ProjectsChartRespponse,
  type RaceToScaleByProjectsResponse,
  type RaceToScaleByCompaniesResponse,
} from "@Lib/types/sectorCompass";

import { goldApi } from "./api";

export const getAllCompasses = async ({ search, ordering }: SectorCompassParams = {}) => {
  const sortingMapper = {
    [SectorCompassesSortingEnum.newest]: "-published_at",
    [SectorCompassesSortingEnum.vertical]: "-root_sector",
  };

  const { data } = await goldApi.get<SectorCompassListResponse>("/sector-compass", {
    params: { search, ordering: ordering ? sortingMapper[ordering] : null },
  });

  return data;
};

export const getCompassById = async (compassId: string) => {
  const { data } = await goldApi.get<SectorCompassBE>(`/sector-compass/${compassId}`);

  return data;
};

export const getCompassInnovationMap = async (compassId: string) => {
  const { data } = await goldApi.get<MarketMapBE>(`/sector-compass/${compassId}/market-map`);

  return data;
};

export const getCompassTaxonomyFilterData = async (sectorId: number) => {
  const { data } = await goldApi.get<SectorFilterBE>(`/taxonomy/sectors/${sectorId}`);

  return data;
};

export const requestPDFDownload = async (compassId: string, marketMap: string) =>
  goldApi.post<{ id: number }>(`/sector-compass/${compassId}/download`, {
    market_map: marketMap,
  });

// Deployment section
const getBaseFilterParams = (sectorId: number) => ({
  project_sector_id: sectorId,
  project_progress_id: SECTOR_COMPASS_PROJECT_OBSERVED_STATUSES,
});

export const getDeploymentMapCoordinates = async (sectorId: number, filters = {}) => {
  const { data } = await goldApi.get<DeploymentMapResponse>("/projects/map", {
    params: {
      ...getBaseFilterParams(sectorId),
      ...filters,
    },
  });

  return data;
};

const getChartBaseParams = (sectorId: number, groupBy: ProjectsGroupByEnum) => ({
  ...getBaseFilterParams(sectorId),
  group_by: groupBy,
});

export const getProjectsChartData = async (
  sectorId: number,
  groupBy: ProjectsGroupByEnum,
  filters = {},
  groupLimit?: number
) => {
  const { data } = await goldApi.get<ProjectsChartRespponse>("/projects/aggregate/bar", {
    params: {
      ...getChartBaseParams(sectorId, groupBy),
      ...filters,
      max_groups: groupLimit,
    },
  });

  return data;
};

export const getOperationalChartData = async (sectorId: number, groupBy: ProjectsGroupByEnum, filters = {}) => {
  const { data } = await goldApi.get<ProjectsChartRespponse>("/projects/aggregate/bar", {
    params: {
      ...getChartBaseParams(sectorId, groupBy),
      ...filters,
      period: "year",
    },
  });

  return data;
};

export const getRaceToScaleProjects = async (
  sectorId: number,
  page: number,
  perPage: number,
  filters = {}
): Promise<RaceToScaleByProjectsResponse> => {
  const { data } = await goldApi.get<RaceToScaleByProjectsResponse>("/projects/race-to-scale", {
    params: {
      ...getBaseFilterParams(sectorId),
      ...filters,
      page,
      page_size: perPage,
    },
  });

  return data;
};

export const getRaceToScaleCompanies = async (
  sectorId: number,
  page: number,
  perPage: number,
  filters = {}
): Promise<RaceToScaleByCompaniesResponse> => {
  const { data } = await goldApi.get<RaceToScaleByCompaniesResponse>("/companies/race-to-scale", {
    params: {
      ...getBaseFilterParams(sectorId),
      ...filters,
      page,
      page_size: perPage,
    },
  });

  return data;
};
