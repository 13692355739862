import DOMPurify from "dompurify";

DOMPurify.addHook("afterSanitizeAttributes", node => {
  if (node.nodeName === "IMG" && node.hasAttribute("style")) {
    (node as HTMLImageElement).style.width = "100%";
    (node as HTMLImageElement).style.height = "auto";
  }

  if (node.nodeName === "A") {
    // set all elements owning target to target=_blank
    if ("target" in node) {
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener noreferrer");
    }
    // set non-HTML/MathML links to xlink:show=new
    if (!node.hasAttribute("target") && (node.hasAttribute("xlink:href") || node.hasAttribute("href"))) {
      node.setAttribute("xlink:show", "new");
    }
  }
});
