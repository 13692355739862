import { FC } from "react";

import { Box, createStyles, Text } from "@mantine/core";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";

import { SectorCompassScaleUI } from "@Lib/types/sectorCompass";

const useStyles = createStyles((theme, isRoot: boolean) => ({
  root: {
    display: "grid",
    justifyContent: "space-between",
    gridTemplateColumns: "repeat(2, auto)",
    gridColumnGap: theme.spacing.sm,
    borderBottom: theme.other.getDefaultBorder(theme),
    marginBottom: theme.spacing.xs,
    "&: last-child": {
      borderBottom: "none",
      marginBottom: 0,
    },
  },
  header: {
    fontWeight: theme.other.fontWeight.semiBold,
  },
  description: {
    color: theme.colors.grey[7],
    "& > ul": {
      listStyle: "none",
      padding: 0,
      marginBlock: theme.spacing.xs,
      "& > li > p": {
        marginBlock: "2px",
      },
    },
  },
  capacityContainer: {
    marginBlock: theme.spacing.xs,
    minWidth: "50px",
    textAlign: "right",
  },
  capacityLabel: {
    fontWeight: theme.other.fontWeight.semiBold,
    opacity: isRoot ? 1 : 0,
  },
}));

type RtSInfoContentRowProps = {
  scale: SectorCompassScaleUI;
  isRoot: boolean;
  capacityUnits?: string;
};

const RtSInfoContentRow: FC<RtSInfoContentRowProps> = ({ scale, isRoot, capacityUnits }) => {
  const { classes } = useStyles(isRoot);
  const { t } = useTranslation();

  const { name, description, capacityRange } = scale;
  const chunk = DOMPurify.sanitize(description);

  return (
    <Box className={classes.root}>
      <Box>
        <Text className={classes.header}>{name}</Text>
        <Text className={classes.description} dangerouslySetInnerHTML={{ __html: chunk }} />
      </Box>
      <Box>
        <Text className={classes.capacityLabel}>
          {t("sectorCompassPage.sections.deployment.raceToScaleInfo.capacity", {
            capacityUnits,
            interpolation: { escapeValue: false },
          })}
        </Text>
        <Text className={classes.capacityContainer}>{capacityRange}</Text>
      </Box>
    </Box>
  );
};

export default RtSInfoContentRow;
