import { FC } from "react";

import { Box, createStyles } from "@mantine/core";

import { HTMLParagraphContent, HTMLParagraphSubTitle, HTMLParagraphTitle } from "./common";

const useStyles = createStyles(theme => ({
  root: {
    color: theme.colors.grey[8],
    display: "flex",
    flexDirection: "column",
  },
}));

type HTMLParagraphProps = {
  title?: string;
  subTitle?: string;
  bordered?: boolean;
  content: string;
};

const HTMLParagraph: FC<HTMLParagraphProps> = ({ title, subTitle, bordered = true, content }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      {title && <HTMLParagraphTitle title={title} mb={4} />}
      {subTitle && <HTMLParagraphSubTitle subTitle={subTitle} mb={"xs"} />}
      <HTMLParagraphContent content={content} bordered={bordered} p={"0.7em"} bg="grey.1" />
    </Box>
  );
};

export default HTMLParagraph;
