import { FC } from "react";

import { Box, Text, createStyles, BoxProps } from "@mantine/core";

import { HEADER_ROW_HEIGHT } from "./utils";

const useStyles = createStyles(theme => {
  const BORDER = 3;
  const H = (HEADER_ROW_HEIGHT * Math.sqrt(2)) / 2;

  return {
    headerCell: {
      height: HEADER_ROW_HEIGHT,
      position: "relative",
      color: theme.white,
      backgroundColor: theme.colors.indigo[5],
      fontSize: theme.fontSizes.sm,
      fontWeight: theme.other.fontWeight.semiBold,
      textAlign: "center",
      textTransform: "uppercase",
      padding: "0.5rem 0.75rem",

      "& > .arrow": {
        display: "inline-block",
        width: HEADER_ROW_HEIGHT * 2,
        height: "100%",
        position: "absolute",
        top: 0,
        left: -(H + BORDER),
        overflow: "hidden",
        "&:before": {
          content: "''",
          borderTop: `${BORDER}px solid ${theme.white}`,
          borderRight: `${BORDER}px solid ${theme.white}`,
          display: "inline-block",
          width: HEADER_ROW_HEIGHT,
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          transform: "rotate(45deg)",
        },
      },

      "&:nth-of-type(-n+2) > .arrow": {
        "&:before": {
          display: "none",
        },
      },
    },
  };
});

type RtSHeaderCellProps = BoxProps & {
  label: string;
};

const RtSHeaderCell: FC<RtSHeaderCellProps> = ({ label, className }) => {
  const { classes, cx } = useStyles();

  return (
    <Box className={cx(classes.headerCell, className)}>
      <Text>{label}</Text>
      <Box className="arrow" />
    </Box>
  );
};

export default RtSHeaderCell;
