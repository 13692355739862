import { FC } from "react";

import { createStyles } from "@mantine/core";

const key = "0%, 42%, 58%, 100%";

const useStyles = createStyles({
  rotatingGroup: {
    animation: "rotateAnimation 2.5s infinite",
    transformOrigin: "center",
    animationTimingFunction: "cubic-bezier(0.42, 0, 0.58, 1)",

    "@keyframes rotateAnimation": {
      "0%": {
        transform: "rotate(0deg)",
      },
      "22%, 50%": {
        transform: "rotate(180deg)",
      },
      "68%, 100%": {
        transform: "rotate(360deg)",
      },
    },
  },
  pathDot: {
    animation: "moveDotAnimation 1.25s infinite",
    transformOrigin: "center",
    animationTimingFunction: "cubic-bezier(0.42, 0, 0.58, 1)",

    "@keyframes moveDotAnimation": {
      [key]: {
        transform: "translate(4px, 11px)",
      },
      "50%": {
        transform: "translate(-8px, 11px)",
      },
    },
  },
  pathCircle: {
    animation: "moveCircleAnimation 1.25s infinite",
    transformOrigin: "center",
    animationTimingFunction: "cubic-bezier(0.42, 0, 0.58, 1)",

    "@keyframes moveCircleAnimation": {
      [key]: {
        transform: "translate(1px, 11px)",
      },
      "50%": {
        transform: "translate(12px, 11px)",
      },
    },
  },
});

const LoadingLogo: FC = () => {
  const { classes } = useStyles();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 38 38"
      width="28"
      height="28"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
    >
      <g className={classes.rotatingGroup}>
        <g className={classes.pathDot} transform="translate(4 11)">
          <path d="M32,8.155705c0,4.416678-3.583262,8.000026-7.999949,8.000026s-8.000051-3.583348-8.000051-8.000026s3.583364-7.999974,8.000051-7.999974s7.999949,3.624977,7.999949,7.999974Z" />
        </g>
        <g className={classes.pathCircle} transform="translate(1 11)">
          <path d="M8.000021,4.322392c-2.125003,0-3.833345,1.708322-3.833345,3.833313s1.708343,3.833323,3.833345,3.833323s3.833377-1.708352,3.833377-3.833323-1.708364-3.833313-3.833377-3.833313Zm0,11.833339C3.583345,16.155731,0,12.572383,0,8.155705s3.583345-7.999974,8.000021-7.999974s7.999979,3.583316,7.999979,7.999974-3.583324,8.000026-7.999979,8.000026Z" />
        </g>
      </g>
    </svg>
  );
};

export default LoadingLogo;
