import { FC } from "react";

import { useTranslation } from "react-i18next";

import HTMLParagraph from "@Components/HTMLParagraph";
import { type SCContextKeys } from "@Lib/types/sectorCompass";

type ContextParagraphProps = {
  titleKey: SCContextKeys[number];
  content: string;
};

const ContextParagraph: FC<ContextParagraphProps> = ({ titleKey, content }) => {
  const { t } = useTranslation();

  return (
    <HTMLParagraph
      title={t(`sectorCompassPage.sections.context.paragraph.${titleKey}.title`)}
      subTitle={t(`sectorCompassPage.sections.context.paragraph.${titleKey}.subTitle`)}
      content={content}
    />
  );
};

export default ContextParagraph;
