import { FC } from "react";

import { Group, Text, Select, createStyles, SelectProps } from "@mantine/core";
import { useTranslation } from "react-i18next";

import { ITEMS_PER_PAGE_OPTIONS } from "@Lib/constants";

const useStyles = createStyles(theme => ({
  root: {
    gap: theme.spacing.xs,
    alignItems: "center",
    justifyContent: "flex-end",
  },
  label: {
    fontSize: theme.fontSizes.sm,
  },
  select: {
    width: 70,
    ...theme.other.getForceInputHeight(30),
  },
}));

type ItemsPerPageSelectProps = Omit<SelectProps, "data"> & {
  data?: SelectProps["data"];
};

const ItemsPerPageSelect: FC<ItemsPerPageSelectProps> = ({ data = ITEMS_PER_PAGE_OPTIONS, ...props }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Group className={classes.root}>
      <Text className={classes.label}>{t("table.itemsPerPage")}</Text>
      <Select className={classes.select} data={data} {...props} />
    </Group>
  );
};

export default ItemsPerPageSelect;
