import { FC } from "react";

import { Divider as MantineDivider, DividerProps, createStyles } from "@mantine/core";

const useStyles = createStyles(theme => ({
  root: {
    borderColor: theme.other.getDefaultBorderColor(theme),
    marginBlock: theme.spacing.sm,
  },
}));

const Divider: FC<DividerProps> = props => {
  const { classes } = useStyles();
  return <MantineDivider className={classes.root} {...props} />;
};

export default Divider;
