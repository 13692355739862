import { useQuery } from "@tanstack/react-query";

import { QUERIES } from "@Lib/constants";
import { getNotableProjects } from "@Lib/services/projects";
import { notableProjectsDataSelector } from "@Lib/utils/queries";

export const useGetNotableProjectsBySector = (compassId: number) =>
  useQuery({
    queryKey: QUERIES.getNotableProjects(compassId),
    queryFn: () => getNotableProjects(compassId),
    select: notableProjectsDataSelector,
    enabled: !!compassId,
  });
