import { useQuery } from "@tanstack/react-query";

import { QUERIES } from "@Lib/constants";
import { getCompassInnovationMap } from "@Lib/services/sectorCompass";

export const useGetCompassMap = (compassId: string) =>
  useQuery({
    queryKey: QUERIES.getCompassMap(compassId),
    queryFn: () => getCompassInnovationMap(compassId),
    enabled: !!compassId,
    staleTime: Infinity,
  });
