export const NOTIFICATIONS_ITEMS_PER_PAGE = 10;
export const DOWNLOAD_SWITCH_LIMIT = 100; // If data is more than the LIMIT we use download notifiction approach

export const SOURCE_KEY = "sightline-climate";
export enum MainMsgTypeEnum {
  START = "START",
  POLLING_START = "POLLING_START",
}

export enum WorkerMsgTypeEnum {
  STARTED = "STARTED",
  POLLING_END = "POLLING_END",
  POLLING_ERROR = "POLLING_ERROR",
}
