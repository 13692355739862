import { useEffect, useState } from "react";

export const useBrowserZoom = () => {
  const [zoomLevel, setZoomLevel] = useState(1);

  useEffect(() => {
    const updateZoom = () => {
      const zoom = window.outerWidth / document.documentElement.clientWidth;
      setZoomLevel(zoom);
    };

    window.addEventListener("resize", updateZoom);
    updateZoom();

    return () => {
      window.removeEventListener("resize", updateZoom);
    };
  }, []);

  return zoomLevel;
};
