import { FC } from "react";

import { Text, createStyles, TextProps, Tooltip } from "@mantine/core";
import { Trans } from "react-i18next";

import { useAuthContext } from "@Context/Auth";

const useStyles = createStyles(theme => ({
  tooltipContent: {
    textTransform: "initial",
    fontWeight: theme.other.fontWeight.regular,
  },
  badge: {
    paddingInline: theme.other.extraSpacing.xxs,
    paddingBlock: 2,
    backgroundColor: theme.colors.indigo[5],
    color: theme.white,
    textTransform: "uppercase",
    borderRadius: 4,
    fontWeight: theme.other.fontWeight.semiBold,
  },
}));

const BadgePreview: FC<TextProps> = textProps => {
  const { classes } = useStyles();
  const { user } = useAuthContext();

  if (user?.accessLevel === "PREMIUM") {
    return null;
  }

  return (
    <Tooltip
      label={
        <Text className={classes.tooltipContent}>
          <Trans i18nKey="sectorCompassPage.badgePreviewTooltip" components={{ br: <br /> }} />
        </Text>
      }
      withinPortal
    >
      <Text span className={classes.badge} size="xs" {...textProps}>
        <Trans i18nKey="sectorCompassPage.badgePreview" />
      </Text>
    </Tooltip>
  );
};

export default BadgePreview;
