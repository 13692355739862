import { FC } from "react";

import { Box, createStyles } from "@mantine/core";

import { Z_INDEX_MAP, DATA_JOINER, DOWNLOAD_REMOVE_SCROLL_PROP } from "@Lib/constants/ui";
import { type FilterUI } from "@Lib/types/base";
import { type RaceToScaleCompanyUI } from "@Lib/types/sectorCompass";

import { RtSHeaderCell } from "../common";
import { HEADER_ROW_HEIGHT } from "../common/utils";
import RaceToScaleRow from "./RaceToScaleRow";

const useStyles = createStyles((theme, { rowHeight, maxRows }: { rowHeight: number; maxRows: number }) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "36ch repeat(4, 1fr)",
    gridTemplateRows: `${HEADER_ROW_HEIGHT}px auto`,
    gridAutoRows: rowHeight,
    overflowY: "auto",
    height: rowHeight * maxRows + HEADER_ROW_HEIGHT,
    position: "relative",
    paddingRight: theme.spacing.sm,
  },
  headerCell: {
    position: "sticky",
    top: 0,
    zIndex: Z_INDEX_MAP.raceToScale.header,

    "&:nth-of-type(1)": {
      backgroundColor: theme.white,
    },

    "&:nth-of-type(5)": {
      marginRight: -theme.spacing.sm,
    },
  },
}));

type RaceToScaleGridProps = {
  dataScales: FilterUI[];
  data: RaceToScaleCompanyUI[];
  rowHeight: number;
  maxRows: number;
};

const RaceToScaleGrid: FC<RaceToScaleGridProps> = ({ dataScales, data, rowHeight, maxRows }) => {
  const { classes } = useStyles({ rowHeight, maxRows });

  return (
    <Box className={classes.root} {...DOWNLOAD_REMOVE_SCROLL_PROP}>
      <Box className={classes.headerCell} />
      {dataScales.map(scale => (
        <RtSHeaderCell key={scale.value} label={scale.label} className={classes.headerCell} />
      ))}
      {data.map(company => {
        const rowKey = `${company.id}${DATA_JOINER}${company.projects.map(project => project.id).join(DATA_JOINER)}`;
        return <RaceToScaleRow key={rowKey} scales={dataScales} company={company} />;
      })}
    </Box>
  );
};

export default RaceToScaleGrid;
