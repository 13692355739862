import { FC } from "react";

import { Switch, createStyles } from "@mantine/core";
import { useTranslation } from "react-i18next";

const useStyles = createStyles(theme => ({
  track: {
    cursor: "pointer",
  },
  label: {
    cursor: "pointer",
    paddingRight: theme.spacing.xs,
    fontSize: theme.fontSizes.sm,
    fontWeight: theme.other.fontWeight.semiBold,
    userSelect: "none",
  },
}));

type DealsToggleBaseProps = {
  isCheckd: boolean;
  handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const DealsToggleBase: FC<DealsToggleBaseProps> = ({ isCheckd, handleOnChange }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  return (
    <Switch
      label={t("charts.dealsCount.dealsByStage.legend.dealsCount")}
      checked={isCheckd}
      onChange={handleOnChange}
      size="xs"
      labelPosition="left"
      classNames={classes}
      defaultChecked
    />
  );
};

export default DealsToggleBase;
