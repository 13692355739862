import { FC } from "react";

import { Box, Text, createStyles } from "@mantine/core";

const useStyles = createStyles((theme, borderColor: string) => ({
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing.xs,
    paddingLeft: 4,
    paddingRight: 2,
    borderLeftWidth: 2,
    borderLeftStyle: "solid",
    borderLeftColor: borderColor,
  },
  title: {
    lineHeight: 1.2,
    fontWeight: theme.other.fontWeight.semiBold,
  },
}));

type TooltipRowProps = {
  name: string;
  value: string | number;
  borderColor: string;
};

const TooltipRow: FC<TooltipRowProps> = ({ name, value, borderColor }) => {
  const { classes } = useStyles(borderColor);

  return (
    <Box className={classes.row}>
      <Text className={classes.title}>{name}</Text>
      <Text>{value}</Text>
    </Box>
  );
};

export default TooltipRow;
