import { AxiosHeaders, InternalAxiosRequestConfig, AxiosRequestConfig, AxiosResponse } from "axios";

import { BEDataFormatEnum } from "@Lib/constants/api";
import { SERVER_TIMESTAMP_DELTA_STORAGE_KEY } from "@Lib/constants/queries";
import { goldApi, goldApiPublic } from "@Lib/services/api";
import { getSignatureHeaders } from "@Lib/utils/signature";

const VITE_API_URL = import.meta.env.VITE_API_URL;

export const getServerTimeDelta = (): number => {
  const delta = sessionStorage.getItem(SERVER_TIMESTAMP_DELTA_STORAGE_KEY);

  if (!delta) return 0;

  const deltaNum = parseInt(delta);

  if (isNaN(deltaNum)) return 0;

  return deltaNum;
};

export const getAxiosHeaders = (req: InternalAxiosRequestConfig, token?: string): AxiosHeaders => {
  const requestedPath = `${req.baseURL?.replace(VITE_API_URL, "") ?? ""}${req.url ?? ""}`;

  const headers = {
    ...req.headers,
    ...getSignatureHeaders(requestedPath, getServerTimeDelta(), req.params),
  };

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  return new AxiosHeaders(headers);
};

export const getAPIHandler =
  <T>(url: string) =>
  async () => {
    const { data } = await goldApi.get<T>(url);

    return data;
  };

export const getAPIPaginatedHandler =
  <T>(url: string) =>
  async () => {
    const { data } = await goldApi.get<T>(url, {
      params: {
        page: 1,
        page_size: 0,
      },
    });

    return data;
  };

export const getPublicAPIPaginatedHandler =
  <T>(url: string) =>
  async () => {
    const { data } = await goldApiPublic.get<T>(url, {
      params: {
        page: 1,
        page_size: 0,
      },
    });

    return data;
  };

/**
 * Fetches a downloadable resource using a GET request.
 * Currently set to download `csv`.
 * Extend with other file formats in case needed.
 *
 * @param {string} url - The URL of the resource to download.
 * @param {AxiosRequestConfig["params"]} [params={}] - Additional query parameters for the request.
 * @returns {Promise<AxiosResponse<Blob>} A Promise that resolves to a Blob representing the downloaded content.
 */
export const getXLSXDownload = (url: string, params: AxiosRequestConfig["params"] = {}): Promise<AxiosResponse<Blob>> =>
  goldApi.get<Blob>(url, {
    params: {
      format: BEDataFormatEnum.excel,
      ...params,
    },
    headers: {
      Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    responseType: "blob",
  });

/**
 * Constructs a header object containing the user's time zone.
 * This header is intended to be used in API requests to provide the server with the user's time zone information.
 *
 * @returns {object} An object containing the "Time-Zone" header with the user's time zone as its value.
 *
 * @example
 * ```typescript
 * const headers = getTimeZoneHeader();
 * console.log(headers); // Output: { "Time-Zone": "America/New_York" }
 * ```
 */
export const getTimeZoneHeader = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return {
    "Time-Zone": timeZone,
  };
};
