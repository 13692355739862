import { useState, useMemo, FC, PropsWithChildren } from "react";

import { RaceToScaleContext } from "./RaceToScaleContext";

// Provider component
const RaceToScaleProvider: FC<PropsWithChildren> = ({ children }) => {
  const [entityId, setEntityId] = useState<string | null>(null);

  const handleSetEntityId = (id: string) => setEntityId(id);
  const handleUnsetEntityId = () => setEntityId(null);

  const value = useMemo(
    () => ({
      hoveredId: entityId,
      setHoveredId: handleSetEntityId,
      unsetHoveredId: handleUnsetEntityId,
    }),
    [entityId]
  );

  return <RaceToScaleContext.Provider value={value}>{children}</RaceToScaleContext.Provider>;
};

export default RaceToScaleProvider;
