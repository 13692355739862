import { type QueryKey } from "@tanstack/react-query";

import { type TranslationKeys } from "@Lib/i18n/config";

import { type EntityBE, type EntityBaseBE, type EntityEmojiBE, type FilterUI, type NestedObject } from "./base";
import { type KeyMatchingPartial, type RecordValuesUnion, type UnionToIntersection } from "./utils";

type FilterResponse<T> = T[];

export type EntityFilterResponse = FilterResponse<EntityBE>;

export type LocationBE = EntityBaseBE<{
  countries: Nullable<string[]>;
}>;

export type SectorFilterBE = EntityBaseBE<
  EntityEmojiBE & {
    company_count: number;
    insight_count: number;
    is_primary?: boolean;
    children: SectorFilterBE[];
  }
>;
export type SectorFilterResponse = FilterResponse<SectorFilterBE>;

type CheckboxFilterBE = EntityBaseBE<{
  description: Nullable<string>;
  children: CheckboxFilterBE[];
}>;

export type CheckboxFilterResponse = FilterResponse<CheckboxFilterBE>;

export type CheckboxFilterUI = FilterUI & {
  isPrimary?: boolean;
  children?: CheckboxFilterUI[];
};

/** Select Filds ENUMS */
export enum LessThan5Plus500mENUM {
  lessThan5M = "0_4999999",
  _5_19M = "5000000_19999999",
  _20_49M = "20000000_49999999",
  _50_99M = "50000000_99999999",
  _100_499M = "100000000_499999999",
  _500Mplus = "500000000_infinity",
}

export enum OverYearsENUM {
  year = "1_year_over",
  years2 = "2_year_over",
  years5 = "5_year_over",
  years10 = "10_year_over",
}

export enum Past10YearsPlusENUM {
  year = "1_year",
  years2 = "2_year",
  years5 = "5_year",
  years10 = "10_year",
  over10 = "10_year_over",
}

export enum PastYearENUM {
  month = "1",
  months3 = "3",
  months6 = "6",
  months12 = "12",
}

export enum Past3YearsENUM {
  week = "7_day",
  month = "1_month",
  months3 = "3_month",
  months6 = "6_month",
  year = "1_year",
  years3 = "3_year",
  over3 = "3_year_over",
}

export enum InvestmentsRangesENUM {
  _1_10 = "1_10",
  _11_50 = "11_50",
  _51_100 = "51_100",
  _100plus = "101_infinity",
}

/** Bottom options (other, dk, na ...) */
export enum CustomOptionsENUM {
  custom = "custom",
  empty = "",
  all = "All",
}

export enum YesNoENUM {
  yes = "yes",
  no = "no",
}

// In case you add any keys here, also update the `Set`s in `src/lib/store/filters/data.ts`
// Currently these are not updated automatically since the initail store state is an empty object
export type UniversalGroupFilters = {
  companySectorIdsLocal: NestedObject;
  sector_id: string[];
};

export type DealsGroupFilters = {
  dealTypesIdsLocal: NestedObject;
  deal_type_id: string[];
  dealSizeRangeLocal: LessThan5Plus500mENUM | CustomOptionsENUM;
  deal_funding_range_min: Nullable<number>;
  deal_funding_range_max: Nullable<number>;
  dealDateRangeLocal: Past3YearsENUM | CustomOptionsENUM;
  deal_date_range_after: Nullable<string>;
  deal_date_range_before: Nullable<string>;
};

export type CompanyGroupFilters = {
  company_location_id: string[];
  companyIdLocal: FilterUI[];
  company_id: string[];
  company_status_id: string[];
  company_stage_id: string[];
  companyFundingRangeLocal: LessThan5Plus500mENUM | CustomOptionsENUM;
  company_total_funding_range_min: Nullable<number>;
  company_total_funding_range_max: Nullable<number>;
  company_tech_type_id: string[];
  companyFoundedDateRangeLocal: Past10YearsPlusENUM | CustomOptionsENUM;
  company_founded_date_range_after: Nullable<string>;
  company_founded_date_range_before: Nullable<string>;
  companyLatestDealDateRangeLocal: Past10YearsPlusENUM | CustomOptionsENUM;
  company_last_deal_date_range_after: Nullable<string>;
  company_last_deal_date_range_before: Nullable<string>;
  company_size_id: string[];
};

export type InvestorGroupFilters = {
  investor_location_id: string[];
  investorIdLocal: FilterUI[];
  investor_id: string[];
  investorTypesIdsLocal: NestedObject;
  investor_type_id: string[];
  investor_fund_size_id: string;
  investor_is_lead: Nullable<boolean>;
  investmentsRangeLocal: InvestmentsRangesENUM | CustomOptionsENUM;
  investor_num_investments_range_min: Nullable<number>;
  investor_num_investments_range_max: Nullable<number>;
  leadInvestmentsRangeLocal: InvestmentsRangesENUM | CustomOptionsENUM;
  investor_num_lead_investments_range_min: Nullable<number>;
  investor_num_lead_investments_range_max: Nullable<number>;
  lastInvestmentDateRangeLocal: PastYearENUM | CustomOptionsENUM;
  investor_last_investment_date_range_after: Nullable<string>;
  investor_last_investment_date_range_before: Nullable<string>;
};

export type ProjectGroupFilters = {
  project_location_id: string[];
  project_progress_id: string[];
  project_scale_id: string[];
  projectOperationDateRangeLocal: PastYearENUM | CustomOptionsENUM;
  project_operation_date_range_after: Nullable<string>;
  project_operation_date_range_before: Nullable<string>;
};

export type FiltersStoreData = Partial<
  UnionToIntersection<
    UniversalGroupFilters | DealsGroupFilters | CompanyGroupFilters | InvestorGroupFilters | ProjectGroupFilters
  >
>;

type AssertUnion<K extends keyof FiltersStoreData> = keyof Pick<FiltersStoreData, K>;

export type SelectFilters = AssertUnion<"investor_fund_size_id">;
export type MultiSelectFilters = AssertUnion<
  | "company_status_id"
  | "company_stage_id"
  | "company_tech_type_id"
  | "company_size_id"
  | "project_progress_id"
  | "project_scale_id"
>;
export type LoactionFilters = KeyMatchingPartial<FiltersStoreData, "_location_id" | "_location_id">;
export type LocalAutocompleteFilters = AssertUnion<"companyIdLocal" | "investorIdLocal">;
export type AutocompleteFilters = AssertUnion<"company_id" | "investor_id">;

export type LocalCheckboxFilterKeys = AssertUnion<
  "companySectorIdsLocal" | "dealTypesIdsLocal" | "investorTypesIdsLocal"
>;
export type CheckboxFilterKeys = AssertUnion<"sector_id" | "deal_type_id" | "investor_type_id">;

export type RangeFiltersAPITuple = [
  KeyMatchingPartial<FiltersStoreData, "_range_min">,
  KeyMatchingPartial<FiltersStoreData, "_range_max">
];
export type RangeLocalFilterKeys = Exclude<
  KeyMatchingPartial<FiltersStoreData, "RangeLocal">,
  DateRangeLocalFilterKeys
>;

export type DateRangeFiltersAPIKeys = KeyMatchingPartial<FiltersStoreData, "_range_after" | "_range_before">;
export type DateRangeFiltersAPITuple = [
  KeyMatchingPartial<FiltersStoreData, "_range_after">,
  KeyMatchingPartial<FiltersStoreData, "_range_before">
];
export type DateRangeLocalFilterKeys = KeyMatchingPartial<FiltersStoreData, "DateRangeLocal">;

export type LocationFilterKeys = KeyMatchingPartial<FiltersStoreData, "_location_">;

export type AcceptedValues = RecordValuesUnion<FiltersStoreData>;

/**
 * Filter chips configuratuion
 */
type ChipVariant = "checkbox" | "multi" | "single" | "range" | "date-range" | "location" | "autocomplete";

type ConfigBase<T extends ChipVariant, K extends keyof FiltersStoreData, U extends object = object> = {
  type: T;
  labelKey: TranslationKeys;
  fieldKey: K;
} & U;

type CheckboxConfig = ConfigBase<
  "checkbox",
  LocalCheckboxFilterKeys,
  {
    queryKey: QueryKey;
    linkedField: CheckboxFilterKeys;
  }
>;

type MultiConfig = ConfigBase<
  "multi",
  MultiSelectFilters,
  {
    queryKey: QueryKey;
  }
>;

type SingleConfig = ConfigBase<
  "single",
  SelectFilters | "investor_is_lead",
  {
    queryKey: QueryKey;
  }
>;

type RangeConfig = ConfigBase<
  "range",
  RangeLocalFilterKeys,
  {
    dataFields: RangeFiltersAPITuple;
    isCurrency?: boolean;
  }
>;

type DateRangeConfig = ConfigBase<
  "date-range",
  DateRangeLocalFilterKeys,
  {
    dateDataFields: DateRangeFiltersAPITuple;
  }
>;

type LocationConfig = ConfigBase<"location", LocationFilterKeys>;

type AutocompleteConfig = ConfigBase<
  "autocomplete",
  LocalAutocompleteFilters,
  {
    linkedField: AutocompleteFilters;
  }
>;

export type FilterConfig = UnionToIntersection<
  | Record<LocalCheckboxFilterKeys, CheckboxConfig>
  | Record<MultiSelectFilters, MultiConfig>
  | Record<SelectFilters | "investor_is_lead", SingleConfig>
  | Record<RangeLocalFilterKeys, RangeConfig>
  | Record<DateRangeLocalFilterKeys, DateRangeConfig>
  | Record<LocationFilterKeys, LocationConfig>
  | Record<LocalAutocompleteFilters, AutocompleteConfig>
>;
