import { FC, ChangeEvent } from "react";

import { Box, Checkbox, Text, Tooltip, createStyles } from "@mantine/core";

const useStyles = createStyles(theme => ({
  root: {
    minWidth: 100,
    display: "flex",
    alignItems: "center",
    gap: 6,
    paddingBlock: 4,
    paddingInline: 6,
    marginBlock: 1,
    borderRadius: theme.radius.xs,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.colors.indigo[1],
    },
  },
}));

type CheckboxMenuItemProps = {
  label: string;
  isChecked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isPartiallyChecked?: boolean;
  partiallyCheckedTooltip?: string;
};

const CheckboxMenuItem: FC<CheckboxMenuItemProps> = ({
  label,
  isChecked,
  onChange,
  isPartiallyChecked = false,
  partiallyCheckedTooltip,
}) => {
  const { classes } = useStyles();

  const color = isPartiallyChecked ? "grey" : "indigo";

  if (isPartiallyChecked && partiallyCheckedTooltip) {
    return (
      <Tooltip label={partiallyCheckedTooltip} openDelay={500} position="left" withinPortal offset={12}>
        <Box component="label" htmlFor={label} className={classes.root}>
          <Checkbox
            id={label}
            checked={isChecked}
            onChange={onChange}
            size={"xs"}
            transitionDuration={0}
            color={color}
          />
          <Text span size={"sm"}>
            {label}
          </Text>
        </Box>
      </Tooltip>
    );
  }

  return (
    <Box component="label" htmlFor={label} className={classes.root}>
      <Checkbox id={label} checked={isChecked} onChange={onChange} size={"xs"} transitionDuration={0} color={color} />
      <Text span size={"sm"}>
        {label}
      </Text>
    </Box>
  );
};

export default CheckboxMenuItem;
