import mixpanel from "mixpanel-browser";

const token = import.meta.env.VITE_MIXPANEL_TOKEN;
const apiHost = import.meta.env.VITE_MIXPANEL_API_HOST;
const isDev = process.env.NODE_ENV === "development";

mixpanel.init(token, {
  api_host: apiHost,
  ignore_dnt: true,
  debug: isDev,
});
