import { type ComponentType } from "react";

import { ResponsiveContainer } from "recharts";

export type WithResponsiveContainerProps = {
  width?: number;
  height?: number;
};

function withResponsiveContainer<P extends WithResponsiveContainerProps>(Component: ComponentType<P>) {
  return function WithResponsiveContainer(props: P) {
    return (
      <ResponsiveContainer width="100%" height="100%" debounce={350}>
        <Component {...props} />
      </ResponsiveContainer>
    );
  };
}

export default withResponsiveContainer;
