import { forwardRef } from "react";

import { Center, ScrollArea, createStyles, BoxProps, Box } from "@mantine/core";
import { useTranslation } from "react-i18next";

import Card from "@Components/Card";
import SightlineChipRow from "@Components/Card/SightlineChipRow";
import ErrorBox from "@Components/ErrorBox/ErrorBox";
import { GoToLink, LinkRow } from "@Components/GoToLink";
import { DATE_FORMAT } from "@Lib/constants";
import { useGetSightlinesBySectorId } from "@Lib/hooks/sightlines";
import { getFormattedDate } from "@Lib/utils/date";
import { generateLinkUrl } from "@Lib/utils/routes";

import SectionHolder from "../SectionHolder";

const useStyles = createStyles(theme => ({
  content: {
    height: "100%",
    paddingTop: theme.spacing.sm,
    marginInline: theme.spacing.sm,
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: theme.spacing.sm,
    [theme.fn.smallerThan("lg")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.fn.smallerThan("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
}));

type SightlineListSection = BoxProps & {
  sectorId: number;
  sectorPath: string[];
};

const SightlineListSection = forwardRef<HTMLDivElement, SightlineListSection>(
  ({ sectorId, sectorPath, ...boxProps }, ref) => {
    const { t } = useTranslation();
    const { classes } = useStyles();

    const query = useGetSightlinesBySectorId(sectorId);
    const gotoParams = new URLSearchParams({ sectorPath: sectorPath.join() });
    const goto = `${generateLinkUrl("sightlines")}?${gotoParams.toString()}`;

    return (
      <SectionHolder ref={ref} {...boxProps} title={t("sectorCompassPage.sections.sightlines.title")}>
        {query.isLoading ? (
          <Center className={classes.content}>{t("sectorCompassPage.sections.sightlines.loading")}</Center>
        ) : query.isError ? (
          <ErrorBox text={t("sectorCompassPage.sections.sightlines.errorText")} />
        ) : (
          <ScrollArea className={classes.content}>
            <Box className={classes.grid}>
              {query.data.map(({ id, title, rootSector, isPaid, coverImage, publishedAt, overview }) => (
                <Card
                  key={id}
                  title={title}
                  subtitle={overview}
                  imgSrc={coverImage}
                  date={getFormattedDate(publishedAt, DATE_FORMAT)}
                  linkText={t("sightlinesPage.cardLink")}
                  goto={generateLinkUrl("sightlineById", id)}
                  chips={<SightlineChipRow rootSector={rootSector} isPaid={isPaid} />}
                  isSightline
                />
              ))}
            </Box>
          </ScrollArea>
        )}
        <LinkRow>
          <GoToLink to={goto} text={t("sectorCompassPage.sections.sightlines.link")} />
        </LinkRow>
      </SectionHolder>
    );
  }
);

export default SightlineListSection;
