import { FC } from "react";

import DatasetControlBase from "@Components/charts/common/controls/DatasetControlBase";
import { useInvestmentChartsStore } from "@Lib/store/sectorCompass/investment";

const DatasetControls: FC = () => {
  const datasetType = useInvestmentChartsStore(state => state.datasetType);
  const setDatasetType = useInvestmentChartsStore(state => state.setDatasetType);

  return <DatasetControlBase datasetType={datasetType} setDatasetType={setDatasetType} />;
};

export default DatasetControls;
