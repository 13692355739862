import { FC } from "react";

import { Box, createStyles } from "@mantine/core";

import { type RaceToScaleCompanyUI } from "@Lib/types/sectorCompass";

import MultipleProjectsDot from "./MultipleProjectsDot";
import ProjectDot from "./ProjectDot";

const DOT_DIAMETER = 14;
const DOT_MULTIPLE_DIAMETER = 16;
const TOOLTIP_STYLE = {
  backgroundColor: "white",
  borderRadius: "4px",
  boxShadow: "0px 1px 12px 0px #0000001F",
  color: "black",
  fontSize: 10,
  padding: "6px 8px",
};

const useStyles = createStyles((theme, hovered: boolean) => ({
  cell: {
    position: "relative",
    borderRight: theme.other.getDefaultBorder(theme),
    "&:nth-of-type(5n)": {
      borderRight: "none",
    },
  },
  dotPlane: {
    position: "relative",
    height: "100%",
    // This makes sure the dot is placed within the containing cell
    width: `calc(100% - ${DOT_DIAMETER}px)`,
  },
  lineHoverSpace: {
    height: 7,
    width: "100%",
    position: "absolute",
    top: "50%",
    left: 0,
    right: 0,
    transform: "translateY(-50%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "stretch",
  },
  line: {
    height: 0,
    flexGrow: 1,
    borderBottomColor: hovered ? theme.colors.grey[5] : theme.other.getDefaultBorderColor(theme),
    borderBottomStyle: hovered ? "solid" : "dashed",
    borderBottomWidth: 1,
  },
}));

type RaceToScaleCellProps = {
  projects: RaceToScaleCompanyUI["projects"];
  isHoverActive: boolean;
  handleMouseEnter: () => void;
  handleMouseLeave: () => void;
};

const RaceToScaleCell: FC<RaceToScaleCellProps> = ({ projects, isHoverActive, handleMouseEnter, handleMouseLeave }) => {
  const { classes } = useStyles(isHoverActive);

  const projectGroups = Object.values(
    projects.reduce<Record<string, RaceToScaleCompanyUI["projects"]>>((acc, project) => {
      const capacityKey = `${project.capacityOffset}`;
      return {
        ...acc,
        [capacityKey]: [...(acc[capacityKey] || []), project],
      };
    }, {})
  );

  return (
    <Box className={classes.cell}>
      <Box className={classes.dotPlane}>
        {projectGroups.map((projectGroup, index) => {
          if (projectGroup.length === 0) {
            return null;
          }

          if (projectGroup.length === 1) {
            return (
              <ProjectDot
                key={index}
                project={projectGroup[0]}
                dotDiameter={DOT_DIAMETER}
                tooltipStyle={TOOLTIP_STYLE}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
              />
            );
          }

          return (
            <MultipleProjectsDot
              key={index}
              projects={projectGroup}
              dotDiameter={DOT_MULTIPLE_DIAMETER}
              tooltipStyle={TOOLTIP_STYLE}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
            />
          );
        })}
      </Box>
      <Box className={classes.lineHoverSpace} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Box className={classes.line} />
      </Box>
    </Box>
  );
};

export default RaceToScaleCell;
