import { useCallback } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { SERVER_TIMESTAMP_DELTA_STORAGE_KEY } from "@Lib/constants";
import { clearMixpanelData } from "@Lib/utils/userTracking";

export const useClearOnLogout = () => {
  const queryClient = useQueryClient();

  const clearOnLogout = useCallback(() => {
    sessionStorage.removeItem(SERVER_TIMESTAMP_DELTA_STORAGE_KEY);
    clearMixpanelData();
    queryClient.clear();
  }, []);

  return clearOnLogout;
};
