import { FC } from "react";

import { Box, createStyles } from "@mantine/core";

import { DATA_JOINER } from "@Lib/constants/ui";
import { type FilterUI } from "@Lib/types/base";
import { type RaceToScaleTechnologyUI } from "@Lib/types/sectorCompass";

import { RtSHeaderCell } from "../common";
import RaceToScaleRow from "./RaceToScaleRow";

const useStyles = createStyles({
  root: {
    display: "grid",
    gridTemplateColumns: "36ch repeat(4, minmax(0, 1fr))",
  },
});

type RaceToScaleGridProps = {
  dataScales: FilterUI[];
  data: RaceToScaleTechnologyUI[];
};

const RaceToScaleGrid: FC<RaceToScaleGridProps> = ({ dataScales, data }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <Box />
      {dataScales.map(scale => (
        <RtSHeaderCell key={scale.value} label={scale.label} />
      ))}
      {data.map(({ id, name, companies }, idx) => {
        const rowKey = `${id}${DATA_JOINER}${companies.map(company => company.id).join(DATA_JOINER)}`;
        const isFirstRow = idx == 0;

        return (
          <RaceToScaleRow
            key={rowKey}
            isFirstRow={isFirstRow}
            scales={dataScales}
            technology={name}
            companies={companies}
          />
        );
      })}
    </Box>
  );
};

export default RaceToScaleGrid;
