import { FC } from "react";

import { Box, Text, createStyles } from "@mantine/core";
import { Surface, Rectangle, type DefaultLegendContentProps } from "recharts";

import { CHARTS_CONFIG } from "@Components/charts/config";

const useStyles = createStyles((theme, isInteractive: boolean) => ({
  root: {
    marginRight: -theme.spacing.xs,
    textAlign: "right",
    lineHeight: 1,
  },
  item: {
    cursor: isInteractive ? "pointer" : "default",
    display: "inline-flex",
    alignItems: "center",
    gap: theme.other.extraSpacing.xxs,
    marginInline: theme.spacing.xs,
  },
}));

type LegendContentProps = DefaultLegendContentProps & {
  onLegendClick?: (dataKey: string, remeve: boolean) => void;
};

const LegendContent: FC<LegendContentProps> = ({ payload, onLegendClick }) => {
  const isInteractive = !!onLegendClick;
  const { classes } = useStyles(isInteractive);

  if (!payload) {
    return null;
  }

  return (
    <Box className={classes.root}>
      {payload.map(entry => {
        const isVisible = !entry.inactive;
        const handleOnClick = isInteractive
          ? () => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              onLegendClick(entry.dataKey, isVisible);
            }
          : undefined;

        return (
          <Box key={entry.value} onClick={handleOnClick} opacity={isVisible ? 1 : 0.4} className={classes.item}>
            {entry.type === "line" ? (
              <Surface width={CHARTS_CONFIG.legend.iconConfig.line.w} height={CHARTS_CONFIG.legend.iconConfig.line.h}>
                <line x1="2" y1="2.43994" x2="23" y2="2.43994" stroke={entry.color} strokeDasharray="2 2" />
                <circle cx="1.5" cy="2.43994" r="1.5" fill={entry.color} />
                <circle cx="23.5" cy="2.43994" r="1.5" fill={entry.color} />
              </Surface>
            ) : (
              <Surface width={CHARTS_CONFIG.legend.iconConfig.rect.w} height={CHARTS_CONFIG.legend.iconConfig.rect.h}>
                <Rectangle
                  width={CHARTS_CONFIG.legend.iconConfig.rect.w}
                  height={CHARTS_CONFIG.legend.iconConfig.rect.h}
                  fill={entry.color}
                />
              </Surface>
            )}
            <Text span size={CHARTS_CONFIG.mainFontSize}>
              {entry.value}
            </Text>
          </Box>
        );
      })}
    </Box>
  );
};

export default LegendContent;
