import { FC } from "react";

import { Group } from "@mantine/core";
import { useTranslation } from "react-i18next";

import BasicChip from "@Components/BasicChip/BasicChip";
import { NotableChipColors } from "@Lib/config/sectorCompass";
import { type EntityEmojiBE } from "@Lib/types/base";
import { type NotableStatus } from "@Lib/types/projects";

type NotableChipRowProps = {
  status?: Nullable<NotableStatus>;
  sector?: Nullable<EntityEmojiBE>;
  showSector?: boolean;
};

const NotableChipRow: FC<NotableChipRowProps> = ({ status, sector, showSector = false }) => {
  const { t } = useTranslation();

  if (!status) {
    return null;
  }

  return (
    <Group spacing="xs">
      <BasicChip chipVariant={NotableChipColors[status]} size="xs">
        {t(`sectorCompassPage.sections.notable.card.chips.${status}`)}
      </BasicChip>
      {showSector && (
        <BasicChip chipVariant="grey" size="xs">
          {sector?.emoji} {sector?.name}
        </BasicChip>
      )}
    </Group>
  );
};

export default NotableChipRow;
