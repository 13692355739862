import { useInfiniteQuery, InfiniteData } from "@tanstack/react-query";

import { QUERIES, NOTIFICATIONS_ITEMS_PER_PAGE } from "@Lib/constants";
import { getAllNotifications } from "@Lib/services/downloadNotifications";
import { type NotificationsResponse, type NotificationUI } from "@Lib/types/downloadNotifications";
import { extractNextPageParam } from "@Lib/utils/queries";

const dataSelector = ({
  pages,
  pageParams,
}: InfiniteData<NotificationsResponse>): InfiniteData<{ count: number; result: NotificationUI[] }> => ({
  pages: pages.map(page => ({
    result: page.results.map(({ file_size, created_at, ...rest }) => ({
      fileSize: file_size,
      createdAt: created_at,
      ...rest,
    })),
    count: page.count,
  })),
  pageParams,
});

export const useGetAllNotifications = () =>
  useInfiniteQuery({
    queryKey: QUERIES.getAllNotifications(),
    queryFn: ({ pageParam = 1 }) => getAllNotifications({ page: pageParam, perPage: NOTIFICATIONS_ITEMS_PER_PAGE }),
    getNextPageParam: ({ next }) => extractNextPageParam(next),
    select: dataSelector,
    refetchOnMount: false,
  });
