import { FC } from "react";

import { Trans } from "react-i18next";

import NoDataMessageBase from "@Components/charts/common/NoDataMessageBase";
import { useSavedSearchFiltersStore } from "@Lib/store/investmentDash/filters";

const NoDataMessage: FC = () => {
  const savedSearchApplied = useSavedSearchFiltersStore(state => Boolean(Object.keys(state.filters).length));

  return (
    <NoDataMessageBase
      noDataDescription={
        savedSearchApplied && (
          <Trans i18nKey="investmentDashPage.savedSearches.noDataDisclaimer" components={{ br: <br /> }} />
        )
      }
    />
  );
};

export default NoDataMessage;
