import { FC } from "react";

import { shallow } from "zustand/shallow";

import DateRangeControlBase from "@Components/charts/common/controls/DateRangeControlBase";
import { useInvestmentChartsStore } from "@Lib/store/sectorCompass/investment";

type DateRangeControlProps = {
  rangeLabels?: string[];
};

const DateRangeControl: FC<DateRangeControlProps> = ({ rangeLabels }) => {
  const { initSlider, slider, setXRangeValue, setSliderRange } = useInvestmentChartsStore(
    ({ initSlider, slider, setXRangeValue, setSliderRange }) => ({
      initSlider,
      slider,
      setXRangeValue,
      setSliderRange,
    }),
    shallow
  );

  return (
    <DateRangeControlBase
      rangeLabels={rangeLabels}
      initSlider={initSlider}
      slider={slider}
      setXRangeValue={setXRangeValue}
      setSliderRange={setSliderRange}
    />
  );
};

export default DateRangeControl;
