import { FC } from "react";

import { Group, createStyles } from "@mantine/core";

import { type ChartConfig } from "@Lib/types/base";

import ChartTypeControl from "./controls/ChartTypeControl";
import DatasetControl from "./controls/DatasetControl";
import DateRangeControl from "./controls/DateRangeControl";
import DealsToggle from "./controls/DealsToggle";
import SectorControl from "./controls/SectorControl";
import StageControl from "./controls/StageControl";

const useStyles = createStyles(theme => ({
  controls: {
    padding: theme.spacing.sm,
    borderBottom: theme.other.getDefaultBorder(theme),
    justifyContent: "space-between",
  },
}));

type ChartControlsProps = {
  rangeLabels?: string[];
  stageConfig?: ChartConfig;
  primarySectorId: number;
};

const ChartControls: FC<ChartControlsProps> = ({ rangeLabels, stageConfig = [], primarySectorId }) => {
  const { classes } = useStyles();

  return (
    <Group className={classes.controls}>
      <Group spacing="sm">
        <DateRangeControl rangeLabels={rangeLabels} />
        <DatasetControl />
      </Group>
      <Group spacing="sm">
        <ChartTypeControl />
        <StageControl config={stageConfig} />
        <SectorControl primarySectorId={primarySectorId} />
        <DealsToggle />
      </Group>
    </Group>
  );
};

export default ChartControls;
