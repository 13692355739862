import { useEffect, FC } from "react";

import { Box, createStyles } from "@mantine/core";

import { useDeploymentFilterStore } from "@Lib/store/sectorCompass/deployment";

import DateRangeFilter from "./DateRangeFilter";
import LocationFilter from "./LocationFilter";
import ScaleFilter from "./ScaleFilter";
import StatusFilter from "./StatusFilter";

const useStyles = createStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing.sm,
    fontWeight: theme.other.fontWeight.regular,
    paddingRight: theme.spacing.xs,
  },
}));

type FiltersProps = {
  projectsStartDate: number;
  projectsEndDate: number;
};

const Filters: FC<FiltersProps> = props => {
  const { classes } = useStyles();

  const clearFilters = useDeploymentFilterStore(state => state.clearFilters);

  useEffect(() => {
    return () => clearFilters();
  }, [clearFilters]);

  return (
    <Box className={classes.root}>
      <LocationFilter />
      <DateRangeFilter {...props} />
      <StatusFilter />
      <ScaleFilter />
    </Box>
  );
};

export default Filters;
