import { useCallback, memo, FC } from "react";

import { MultiSelect, createStyles } from "@mantine/core";
import { useTranslation } from "react-i18next";

import { useUserTrackingContext } from "@Context/UserTracking";
import { USER_TRACKING_PROPERTIES } from "@Lib/constants";
import { useGetGeographyFilterData } from "@Lib/hooks/filters";
import { useDeploymentFilterStore } from "@Lib/store/sectorCompass/deployment";

const useStyles = createStyles({
  root: {
    minWidth: 200,
    maxWidth: 350,
  },
  input: {
    minHeight: 32,
  },
  values: {
    minHeight: 30,
  },
  searchInput: {
    lineHeight: "30px",
  },
});

const LocationFilter: FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { trackFilters } = useUserTrackingContext();

  const filterField = "project_location_id";
  const { data = [] } = useGetGeographyFilterData();

  const filterValue = useDeploymentFilterStore(useCallback(state => state.filters[filterField] || [], [filterField]));
  const setFilter = useDeploymentFilterStore(state => state.setFilter);
  const resetFilters = useDeploymentFilterStore(state => state.resetFilters);

  const onChange = useCallback((value: typeof filterValue) => {
    if (!value || !value.length) {
      resetFilters([filterField]);
      return;
    }

    setFilter(filterField, value);

    trackFilters({
      filter: filterField,
      value: value,
      mechanism: USER_TRACKING_PROPERTIES.filtersMechanism.sectorCompassDeployment,
    });
  }, []);

  return (
    <MultiSelect
      classNames={classes}
      value={filterValue}
      data={data}
      onChange={onChange}
      placeholder={t("sectorCompassPage.sections.deployment.filters.location.placeholder")}
      searchable
    />
  );
};

LocationFilter.displayName = "LocationFilter";

export default memo(LocationFilter);
