import { useQuery } from "@tanstack/react-query";
import { User } from "firebase/auth";

import { QUERIES } from "@Lib/constants/queries";
import { fetchMe, firebaseLogout } from "@Lib/services/user";
import { type ErrorCodes, CustomError } from "@Lib/types/error";
import { type UserBEMergedWithFBData, type UserFE } from "@Lib/types/user";
import { formatError } from "@Lib/utils/errorHandlers";
import { showErrorNotification } from "@Lib/utils/notifications";

const dataSelector = (userData: UserBEMergedWithFBData): UserFE => {
  if (!userData) {
    return null;
  }

  const {
    id,
    email,
    first_name,
    last_name,
    access_level,
    has_subscriptions,
    organization_id,
    remaining_xlsx_user_downloads,
    remaining_pdf_user_downloads,
    remaining_pdf_organization_downloads,
    remaining_trial_days,
    ...fbUserFieldsWeUse
  } = userData;

  return {
    ...fbUserFieldsWeUse,
    fullName: first_name && last_name ? `${first_name} ${last_name}` : "",
    localId: id.toString(),
    email: email,
    firstName: first_name,
    lastName: last_name,
    accessLevel: access_level,
    hasSubscriptions: has_subscriptions,
    isTrialUser: access_level === "TRIAL",
    organizationId: organization_id,
    remainingXlsxUserDownloads: remaining_xlsx_user_downloads,
    remainingPdfUserDownloads: remaining_pdf_user_downloads,
    remainingPdfOrganizationDownloads: remaining_pdf_organization_downloads,
    remainingTrialDays: remaining_trial_days,
  };
};

export const useFetchMe = (user: Nullable<User>, setLoginErrorCode: (errorCode: Nullable<ErrorCodes>) => void) =>
  useQuery({
    queryKey: QUERIES.getUserData(),
    queryFn: () => fetchMe(user),
    select: dataSelector,
    enabled: Boolean(user),
    initialData: null,
    retry: false,
    onSuccess: () => {
      setLoginErrorCode(null);
    },
    onError: (e: CustomError) => {
      firebaseLogout();
      setLoginErrorCode(e.status);
      showErrorNotification({ id: "query-error", message: formatError(e) });
    },
  });
