import { SECTOR_GROUP_OTHER_ID } from "@Lib/constants";

export const baseColors = {
  axisLine: "#000000",
  black: "#000000",
  barCursor: "#EBEBEB33",
  refLine: "#EFF0F4",
};

export const dealsCountLine = "#5DE7C6";
export const groupOther = "#E0E0E0";

export const sectorBarsById: Record<number, string> = {
  707: "#03989E", // Transportation
  704: "#5350EF", // Energy
  705: "#F2A89E", // Food & Land Use
  706: "#97C076", // Industry
  703: "#F5C572", // Climate Management
  701: "#EC6A5C", // Built Environment
  702: "#7998F3", // Carbon
  999: "#999999", // This is a fallback
};

export const sectorDarkerBars: Record<string, string> = {
  "#03989E": "#014042", // Transportation
  "#5350EF": "#191C43", // Energy
  "#F2A89E": "#6C4843", // Food & Land Use
  "#97C076": "#3F5132", // Industry
  "#F5C572": "#6C542A", // Climate Management
  "#EC6A5C": "#5C1D19", // Built Environment
  "#7998F3": "#2C3952", // Carbon
  grey: "#A9A9A9", // This is a fallback
};

export const sectorLighterBars: Record<string, string> = {
  "#03989E": "#ACE9EC", // Transportation
  "#5350EF": "#D5D3FB", // Energy
  "#F2A89E": "#FADCD8", // Food & Land Use
  "#97C076": "#D3EAE6", // Industry
  "#F5C572": "#FDF1DC", // Climate Management
  "#EC6A5C": "#FADAD6", // Built Environment
  "#7998F3": "#DDE5FC", // Carbon
  grey: "#D3D3D3", // This is a fallback
};

export const stageBarsById: Record<number, string> = {
  17: "#282B56", // Growth
  16: "#47509B", // Series D
  15: "#5350EF", // Series C
  14: "#B5B2F8", // Series B
  13: "#E8E7FF", // Series A
  12: "#F4F4FF", // Seed
  [SECTOR_GROUP_OTHER_ID]: groupOther, // Other
  999: "#999999", // This is a fallback
};
// These are use in `src/lib/hooks/investors/useGetSnapshotData.ts`
// TODO: Remove these and switch to using `sectorBarsById`
export const stageBars = ["#282B56", "#47509B", "#5350EF", "#B5B2F8", "#E8E7FF", "#F4F4FF", groupOther];

export const dealTypeById: Record<number, string> = {
  27: "#03989E", // Acquisition
  31: "#F2A89E", // IPO
  32: "#5350EF", // SPAC
};

export const projectStatusById: Record<number, string> = {
  4: "#03989E", // Operational
  3: "#5350EF", // Construction
  1: "#F2A89E", // Preparation
};
