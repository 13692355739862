import { FC } from "react";

import { Box, createStyles } from "@mantine/core";

import { type CompassCompany } from "@Lib/types/sectorCompass";

import CompanyItem from "./CompanyItem";

const useStyles = createStyles({
  compnaniesGridItem: {
    gridColumn: "1 / -1",
    lineHeight: 0,
  },
  compnaniesContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: 4,
  },
});

type CompaniesRowProps = {
  data: CompassCompany[];
};

const CompaniesRow: FC<CompaniesRowProps> = ({ data }) => {
  const { classes } = useStyles();
  return (
    <Box className={classes.compnaniesGridItem}>
      <Box className={classes.compnaniesContainer}>
        {data.map(company => (
          <CompanyItem key={company.id} company={company} />
        ))}
      </Box>
    </Box>
  );
};

export default CompaniesRow;
