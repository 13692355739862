import { useEffect, useCallback } from "react";

import { useListState } from "@mantine/hooks";

export const useExpandState = (
  initiallyExpanded: string[] = []
): [string[], (id: string, expanded: boolean) => void, () => void] => {
  const [state, handlers] = useListState(initiallyExpanded);

  useEffect(() => {
    if (initiallyExpanded.length) {
      handlers.setState(initiallyExpanded);
    }
  }, [initiallyExpanded]);

  const onExpand = useCallback((id: string, expanded: boolean) => {
    if (expanded) {
      handlers.append(id);
      return;
    }
    handlers.setState(current => current.filter(expandedId => expandedId !== id));
  }, []);

  const resetExpand = useCallback(() => {
    handlers.setState([]);
  }, []);

  return [state, onExpand, resetExpand];
};
