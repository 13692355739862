import { FC } from "react";

import { Center } from "@mantine/core";
import { useTranslation } from "react-i18next";

import NoDataMessageBase from "@Components/charts/common/NoDataMessageBase";
import ErrorBox from "@Components/ErrorBox/ErrorBox";
import { ProjectsGroupByEnum } from "@Lib/constants/api";
import { DOWNLOAD_IDS } from "@Lib/constants/ui";
import { useGetProjectsChartData } from "@Lib/hooks/sectorCompass";

import { ProjectsChartContainer, ProjectsChart } from "./common";

type ProjectsProgressChartProps = {
  sectorId: number;
};

const ProjectsProgressChart: FC<ProjectsProgressChartProps> = ({ sectorId }) => {
  const { t } = useTranslation();

  const { isLoading, isError, data } = useGetProjectsChartData(sectorId, ProjectsGroupByEnum.progress);

  return (
    <ProjectsChartContainer
      id={DOWNLOAD_IDS.projectsProgressId}
      titleKey={ProjectsGroupByEnum.progress}
      units={data?.units}
    >
      {isLoading ? (
        <Center h={"100%"}>{t("charts.loadingText")}</Center>
      ) : isError ? (
        <ErrorBox text={t("charts.errorText")} />
      ) : data.dataset.length === 0 ? (
        <NoDataMessageBase />
      ) : (
        <ProjectsChart
          units={data.units}
          dataset={data.dataset}
          config={data.config}
          tooltipWithTitle={false}
          noteText={t("sectorCompassPage.sections.deployment.progressNoteText")}
        />
      )}
    </ProjectsChartContainer>
  );
};

export default ProjectsProgressChart;
