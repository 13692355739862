import { SUPPORT_EMAIL } from "@Lib/constants";
import { type ValidDataValues, type MagnitudeInfo } from "@Lib/types/base";

/**
 * Get magnitude and magnitude factor based on the largest number.
 *
 * @param {number} largestNumber - The largest number to determine the magnitude for.
 * @returns {{ magnitude: string, magnitudeFactor: number }} An object containing the magnitude and magnitude factor.
 */
export const getMagnitudeInfo = (largestNumber: number): MagnitudeInfo => {
  let magnitude = "";
  let magnitudeFactor = 1;

  if (largestNumber >= 1e9) {
    magnitude = "B";
    magnitudeFactor = 1e9;
  } else if (largestNumber >= 1e6) {
    magnitude = "M";
    magnitudeFactor = 1e6;
  } else if (largestNumber >= 1e3) {
    magnitude = "K";
    magnitudeFactor = 1e3;
  }

  return { magnitude, magnitudeFactor };
};

/**
 * Format a numeric value as a money value.
 * @param value - The value to format. Can be a single value or an array of values.
 * @param options - Additional options for formatting using Intl.NumberFormat. This can include properties such as `minimumFractionDigits` or `maximumFractionDigits`.
 * @param locale - The locale to use for formatting. Defaults to "en-US".
 * @returns The formatted money value as a string.
 */
export const formatMoney = (value: ValidDataValues, options: Intl.NumberFormatOptions = {}, locale = "en-US") => {
  const toFormat = Array.isArray(value) ? value[0] : value;

  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "USD",
    notation: "compact",
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
    compactDisplay: "short",
    ...options,
  }).format(+toFormat);
};

/**
 * Generates the mailto URL with a predefined message title and receiver email.
 * @param {string} receiverEmail - The email address of the receiver.
 * @param {string} [title] - The predefined message title (optional).
 * @param {string} [body] - The predefined email body (optional).
 * @returns {string} The generated mailto URL.
 */
export const getMailToHref = (receiverEmail: string, title?: string, body?: string): string => {
  const mailtoUrl = new URL(`mailto:${receiverEmail}`);
  const params = new URLSearchParams();

  title && params.set("subject", title);
  body && params.set("body", body);

  mailtoUrl.search = params.toString();

  return mailtoUrl.href;
};

/**
 * Generates the mailto URL for contacting support with a predefined message title and body.
 * @param {string} [title] - The predefined message title (optional).
 * @param {string} [body] - The predefined email body (optional).
 * @returns {string} The generated mailto URL for contacting support.
 */
export const getSupportMailToHref = (title?: string, body?: string): string => {
  return getMailToHref(SUPPORT_EMAIL, title, body);
};

/**
 * Converts a number of bytes into a human-readable string with appropriate units (B, KB, MB, etc.).
 *
 * @param {number} bytes - The number of bytes to convert.
 * @returns {string} A string representation of the bytes in a human-readable format.
 *
 * @example
 * // returns '1.88 MB'
 * formatBytes(1969249);
 *
 * @example
 * // returns '0 B'
 * formatBytes(0);
 */
export const formatBytes = (bytes: number): string => {
  if (bytes === 0) return "0 B";
  const k = 1000;
  const sizes = ["B", "KB", "MB", "GB", "TB"];
  const i = Math.min(Math.floor(Math.log(bytes) / Math.log(k)), sizes.length - 1);
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};
