import { type SCContextKeys } from "../types/sectorCompass";

export enum MarketTabsEnum {
  sightlines = "sightlines",
  context = "context",
  framework = "framework",
  marketMap = "map",
  investment = "chart",
  deployment = "deployment",
  notable = "notable",
}

export const SC_CONTEXT_CONFIG: SCContextKeys = ["problem", "basics", "questions", "drivers", "limiters", "signposts"];

export enum SectorCompassesSortingEnum {
  newest = "newest",
  vertical = "vertical",
}

export enum NotableChipColors {
  Positive = "green",
  Underperforming = "yellow",
  Canceled = "red",
}
