import { createContext, useContext } from "react";

import { type ErrorCodes } from "@Lib/types/error";
import { type UserFE } from "@Lib/types/user";

export type AuthContextValues = { user: UserFE; loginErrorCode: Nullable<ErrorCodes> };

export const AuthContext = createContext<AuthContextValues>(null!);

AuthContext.displayName = "AuthContext";

export const useAuthContext = () => useContext(AuthContext);
