import { useMemo, FC } from "react";

import { List } from "@mantine/core";
import { useTranslation } from "react-i18next";

import { NestedCheckboxItem } from "@Components/Dropdown/common";
import Dropdown from "@Components/Dropdown/Dropdown";
import { useExpandState } from "@Lib/hooks/common";
import { useGetCompassTaxonomyFilterData } from "@Lib/hooks/sectorCompass";
import { useChartsFilterStore } from "@Lib/store/sectorCompass/investment";

type SectorControlProps = {
  primarySectorId: number;
};

const SectorControl: FC<SectorControlProps> = ({ primarySectorId }) => {
  const { t } = useTranslation();

  const { data = [] } = useGetCompassTaxonomyFilterData(primarySectorId);

  const initiallyExpanded = useMemo(() => data.map(path => path.value), [data]);
  const [expandedCache, handleOnExpand] = useExpandState(initiallyExpanded);

  const setTaxonomyFilter = useChartsFilterStore(state => state.setTaxonomyFilter);
  const checkedIds = useChartsFilterStore(state => state.filters.sectorIdsLocal || {});

  const handleSetFilter = (add: boolean, value: string) => {
    setTaxonomyFilter({
      action: add ? "add" : "remove",
      filterField: "company_primary_sector_id",
      localField: "sectorIdsLocal",
      value,
    });
  };

  return (
    <Dropdown btnText={t("filters.taxonomy.label")} variant="outline" position="bottom-end">
      <List listStyleType="none" w={240} px={"xs"} mr={4}>
        {data.map(item => (
          <NestedCheckboxItem
            key={item.value}
            checkedIds={checkedIds}
            setFilter={handleSetFilter}
            onExpand={handleOnExpand}
            expandedCache={expandedCache}
            {...item}
          />
        ))}
      </List>
    </Dropdown>
  );
};

export default SectorControl;
