import { FC } from "react";

import { Text, createStyles, TextProps } from "@mantine/core";
import DOMPurify from "dompurify";

const useStyles = createStyles((theme, bordered: boolean) => ({
  root: {
    color: theme.colors.grey[8],
    display: "flex",
    flexDirection: "column",
  },
  content: {
    fontSize: theme.fontSizes.md,
    [theme.fn.smallerThan("lg")]: {
      fontSize: theme.fontSizes.sm,
    },
    ...(bordered && { border: theme.other.getHTMLParagraphBorder(theme), borderRadius: theme.radius.xs }),
    flex: 1,
    "& strong": {
      fontWeight: theme.other.fontWeight.bold,
    },
    "& h4": {
      marginBlock: "1em",
    },
    "& p": {
      marginBlock: "0.6em",
    },
    "& ul": {
      margin: 0,
      paddingLeft: "1.6em",
      paddingRight: "0.8em",
    },
    "& ul li": {
      marginBlock: "0.6em",
      listStyleType: "none !important",
      position: "relative",
      "&::before": {
        content: "' '",
        left: "-0.7em",
        top: "0.55em",
        borderWidth: "0.2em",
        borderColor: theme.colors.indigo[5],
        borderStyle: "solid",
        borderRadius: "0.2em",
        position: "absolute",
      },
      "& ul li::before": {
        borderWidth: "0",
      },
    },
  },
}));

type HTMLParagraphContentProps = TextProps & {
  content: string;
  bordered: boolean;
};

const HTMLParagraphContent: FC<HTMLParagraphContentProps> = ({ content, bordered, ...textProps }) => {
  const { classes } = useStyles(bordered);
  const chunk = DOMPurify.sanitize(content);

  return <Text {...textProps} className={classes.content} dangerouslySetInnerHTML={{ __html: chunk }} />;
};

export default HTMLParagraphContent;
