import { useQuery } from "@tanstack/react-query";

import { QUERIES } from "@Lib/constants";
import { getCompassTaxonomyFilterData } from "@Lib/services/sectorCompass";
import { type SectorFilterBE } from "@Lib/types/filters";
import { mapSectorBEToCheckboxUI } from "@Lib/utils/queries";

const dataSelector = (data: SectorFilterBE) => mapSectorBEToCheckboxUI("company", [data]);

export const useGetCompassTaxonomyFilterData = (sectorId: number) =>
  useQuery({
    queryKey: QUERIES.getCompassTaxonomyFilter(sectorId),
    queryFn: () => getCompassTaxonomyFilterData(sectorId),
    select: dataSelector,
    enabled: !!sectorId,
    staleTime: Infinity,
  });
