import { type WorkerToMainMessage, type MainToWorkerMessage, type WithSource } from "@Lib/types/downloadNotifications";

import { MainMsgTypeEnum } from "../constants/downloadNotifications";

export const getPollingStartMessage = (
  token: string,
  serverTimeDelta: number,
  notificationId: string
): MainToWorkerMessage => {
  return sourceMessage<MainToWorkerMessage>({
    type: MainMsgTypeEnum.POLLING_START,
    url: `/v1/app/notifications/${notificationId}`,
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    serverTimeDelta,
    notificationId,
  });
};

export const sourceMessage = <T extends WorkerToMainMessage | MainToWorkerMessage>(message: T): WithSource<T> => {
  return {
    ...message,
    source: "sightline-climate",
  };
};
