import { useQuery } from "@tanstack/react-query";

import { QUERIES } from "../../constants";
import { getGeographyFilterData } from "../../services/filters";
import { mapEntityBEToFilterUI } from "../../utils/queries";

export const useGetGeographyFilterData = (searchTerm?: string) =>
  useQuery({
    queryKey: QUERIES.getFilterGeography(),
    queryFn: () => getGeographyFilterData(searchTerm),
    select: mapEntityBEToFilterUI,
    staleTime: Infinity,
  });
