import { useQuery } from "@tanstack/react-query";

import { QUERIES } from "@Lib/constants";
import { getAllSightlines } from "@Lib/services/sightlines";
import { type SightlinesResponse } from "@Lib/types/sightline";
import { sightlineCardDataSelector } from "@Lib/utils/queries";

const dataSelector = (data: SightlinesResponse) => {
  return data.results.map(sightlineCardDataSelector);
};

export const useGetSightlinesBySectorId = (sectorId?: number) =>
  useQuery({
    queryKey: QUERIES.getSightlinesBySectorId(sectorId),
    queryFn: () =>
      getAllSightlines<SightlinesResponse>({
        page: 1,
        perPage: 6,
        filters: {
          sector_id: sectorId,
        },
      }),
    select: dataSelector,
  });
