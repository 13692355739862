import { FC, PropsWithChildren } from "react";

import { useTranslation } from "react-i18next";
import { ComposedChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";

import withResponsiveContainer, {
  type WithResponsiveContainerProps,
} from "@Components/charts/common/withResponsiveContainer";
import { CHARTS_CONFIG } from "@Components/charts/config";
import { type MagnitudeInfo } from "@Lib/types/base";
import { type ChartDataTypes, type TooltipBarData } from "@Lib/types/deals";

import LegendContent from "./LegendContent";
import TooltipBarContent from "./TooltipBarContent";

const { colors, margin, xPadding, labels, formatters, animationDuration } = CHARTS_CONFIG;

type ChartPlotProps = PropsWithChildren<
  WithResponsiveContainerProps & {
    dataset: Record<string, number | string>[];
    chartDataType: ChartDataTypes;
    setActiveIndex?: (index?: number) => void;
    onLegendClick?: (dataKey: string, remeve: boolean) => void;
    activeBar: TooltipBarData;
    yAxisMagnitudeInfo: MagnitudeInfo;
  }
>;

const ChartPlot: FC<ChartPlotProps> = ({
  chartDataType,
  dataset,
  setActiveIndex,
  onLegendClick,
  activeBar,
  yAxisMagnitudeInfo,
  children,
  ...responsoveProps
}) => {
  const { t } = useTranslation();

  const handleActiveIndex: (typeof ComposedChart.defaultProps)["onMouseMove"] = input => {
    setActiveIndex && setActiveIndex(input?.activeTooltipIndex);
  };

  return (
    <ComposedChart
      data={dataset}
      margin={margin}
      barCategoryGap={"4%"}
      onMouseMove={handleActiveIndex}
      {...responsoveProps}
    >
      <CartesianGrid strokeDasharray="5 4" vertical={false} stroke={colors.refLine} />
      {children}
      <XAxis
        dataKey="dateRange"
        tickLine={false}
        padding={xPadding}
        tickFormatter={formatters.datesAxis}
        axisLine={{ stroke: colors.refLine }}
        domain={chartDataType === "percentage" ? [0, "dataMax"] : [0, "auto"]}
        tick={labels.ticks}
      />
      <YAxis
        dataKey="total"
        tickLine={false}
        axisLine={false}
        tick={labels.ticks}
        tickCount={chartDataType === "percentage" ? 5 : 8}
        tickFormatter={
          chartDataType === "percentage" ? formatters.percentageAxis : formatters.getFundingAxis(yAxisMagnitudeInfo)
        }
      />
      <YAxis
        yAxisId="dealsCount"
        orientation="right"
        dataKey="dealsCount"
        label={labels.getYLabel2(t("charts.dealsCount.yAxis2"))}
        tickLine={false}
        axisLine={false}
        tick={labels.ticks}
        tickCount={chartDataType === "percentage" ? 5 : 8}
        allowDecimals={false}
      />
      <Tooltip
        cursor={false}
        content={
          <TooltipBarContent chartDataType={chartDataType} activeBar={activeBar} magnitudeInfo={yAxisMagnitudeInfo} />
        }
        animationDuration={animationDuration}
      />
      <Legend content={<LegendContent onLegendClick={onLegendClick} />} />
    </ComposedChart>
  );
};

export default withResponsiveContainer(ChartPlot);
