import { type NestedObject } from "@Lib/types/base";

import { getFiltersStore, createFilterActiveSelector, getAPIFiltersSelector } from "../factories/filters";

type FiltersStoreSightlines = Partial<{
  search: string;
  sectorIdsLocal: NestedObject;
  sector_id: string[];
  topicIdsLocal: NestedObject;
  topic_id: string[];
}>;

export const useFiltersStore = getFiltersStore<FiltersStoreSightlines>("Sightlines Filters");

/** Universal Group Filters */
const universalFields = new Set(["sectorIdsLocal", "sector_id"]);

/** Sightline Topic Group Filters */
const topicFields = new Set(["topicIdsLocal", "topic_id"]);

const allFilterFields = new Set([...universalFields, ...topicFields]);

export const hasActiveFilterSelector = createFilterActiveSelector(allFilterFields);
export const universalFilterActiveSelector = createFilterActiveSelector(universalFields);
export const topicFilterActiveSelector = createFilterActiveSelector(topicFields);

/**
 * Keys that must not be sent to BE
 */
const uiFieldsSet = new Set(["sectorIdsLocal", "topicIdsLocal"]);

export const filterForAPISelector = getAPIFiltersSelector(uiFieldsSet);
