import { FC } from "react";

import { Area, AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";

import withResponsiveContainer, {
  WithResponsiveContainerProps,
} from "@Components/charts/common/withResponsiveContainer";
import { CHARTS_CONFIG, PROJECTS_OPERATIONAL_DATE_CHART_CONFIG } from "@Components/charts/config";
import { ProjectsChartConfig, ProjectsOperationsChartDatum } from "@Lib/types/sectorCompass";

import { LegendContent, TooltipContent } from "../common";
import { getTotalKey, extartKeyFromTotalKey } from "../common/utils";

const { labels, colors } = CHARTS_CONFIG;
const { CHART_MARGIN, capacityTickFormatter, capacityFormatter } = PROJECTS_OPERATIONAL_DATE_CHART_CONFIG;

type ProjectAreaChartProps = WithResponsiveContainerProps & {
  units: string;
  dataset: ProjectsOperationsChartDatum[];
  config: ProjectsChartConfig[];
};

const ProjectAreaChart: FC<ProjectAreaChartProps> = ({ units, dataset, config, ...responsiveProps }) => {
  return (
    <AreaChart margin={CHART_MARGIN} data={dataset} {...responsiveProps}>
      <CartesianGrid strokeDasharray="0" vertical={false} stroke={colors.refLine} />
      {config.map(item => (
        <Area
          type="linear"
          key={item.dataKey}
          dataKey={getTotalKey(item.dataKey)}
          stackId="a"
          stroke={item.fill}
          fill={item.fill}
          fillOpacity={1}
          isAnimationActive={false}
          activeDot={false}
        />
      ))}
      <XAxis type="category" dataKey="group" tickLine={false} axisLine={false} tick={labels.ticks} />
      <YAxis
        type="number"
        tickLine={false}
        axisLine={false}
        tick={labels.ticks}
        tickCount={5}
        orientation="right"
        tickFormatter={capacityTickFormatter}
      />
      <Tooltip content={<TooltipContent withTitle units={units} formatter={capacityFormatter} hideProjectsCount />} />
      <Legend content={<LegendContent legendValueFixer={extartKeyFromTotalKey} />} />
    </AreaChart>
  );
};

export default withResponsiveContainer(ProjectAreaChart);
