import { FirebaseError } from "firebase/app";
import { AuthErrorCodes } from "firebase/auth";
import { t } from "i18next";

import { CustomError } from "@Lib/types/error";

export const formatFirebaseError = (error: FirebaseError): string => {
  switch (error.code) {
    case AuthErrorCodes.INVALID_EMAIL:
      return t("errors.firebase.invalidEmail");
    case AuthErrorCodes.INVALID_PASSWORD:
      return t("errors.firebase.invalidPassword");
    case AuthErrorCodes.USER_DISABLED:
      return t("errors.firebase.disabled");
    case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
      return t("errors.firebase.tooManyAttempts");
    case AuthErrorCodes.USER_DELETED:
      return t("errors.firebase.deleted");
    case AuthErrorCodes.WEAK_PASSWORD:
      return t("errors.firebase.passwordTooWeak");
    default:
      return t("errors.generic");
  }
};

export const formatError = (e: unknown): string => {
  if (e instanceof FirebaseError) {
    return formatFirebaseError(e);
  }

  if (e instanceof CustomError) {
    return t(`errors.http.${e.status || "default"}`);
  }

  if (e instanceof Error) {
    return e.message;
  }

  return t("errors.generic");
};
