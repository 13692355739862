import { useCallback } from "react";

import { useWebWorkerContext } from "@Context/WebWorker";
import { firebaseAuth } from "@Lib/config/firebase";
import { getServerTimeDelta } from "@Lib/utils/api";
import { getPollingStartMessage } from "@Lib/utils/downloadNotifications";

export const useStartPolling = () => {
  const { worker } = useWebWorkerContext();

  const handleStartPolling = useCallback(
    async (notificationId: string) => {
      const user = firebaseAuth.currentUser;
      const token = await user?.getIdToken();

      if (!token || !worker) {
        return;
      }

      const message = getPollingStartMessage(token, getServerTimeDelta(), notificationId);
      worker.postMessage(message);
    },
    [worker]
  );

  return {
    handleStartPolling,
  };
};
