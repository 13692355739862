import { FC } from "react";

import { Text } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import { Trans } from "react-i18next";

import { ReactComponent as CheckCircleIcon } from "@Assets/check-circle.svg";

const SubmitOrganisationSuccess: FC<ContextModalProps> = () => {
  return (
    <Text align="center" px={"sm"} pb={"sm"} mt={-16} maw={300}>
      <Text color="teal.3" mb={"xs"}>
        <CheckCircleIcon width={40} height={40} />
      </Text>
      <Trans
        i18nKey="globalSearch.submitOrganisation.successText"
        components={{ title: <Text weight={600} mb={"xs"} /> }}
      />
    </Text>
  );
};

export default SubmitOrganisationSuccess;
