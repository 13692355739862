import { FC } from "react";

import { Button, createStyles, Grid, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import DetailsGridRow from "@Components/Profile/DetailsSection/DetailsGridRow";
import { type ProjectUI } from "@Lib/types/projects";
import { generateLinkUrl } from "@Lib/utils/routes";

const useStyles = createStyles(theme => ({
  root: {
    width: "300px",
    paddingBlock: theme.spacing.sm,
  },
}));

type InfoProps = {
  project: ProjectUI;
};

const InfoContent: FC<InfoProps> = ({ project }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { progress, companies, scale, sector, technology, metrics, id } = project;

  if (!project) {
    return <Text>{t("projectDetailsPage.noData")}</Text>;
  }

  return (
    <Grid gutter={0} columns={24} className={classes.root}>
      <DetailsGridRow
        title={t("projectDetailsPage.infoTitles.ownedBy")}
        rowData={{
          dataType: "companyLinks",
          data: companies,
        }}
      />
      <DetailsGridRow title={t("projectDetailsPage.infoTitles.status")} rowData={{ data: progress }} />
      <DetailsGridRow title={t("projectDetailsPage.infoTitles.scale")} rowData={{ data: scale }} />
      <DetailsGridRow title={t("projectDetailsPage.infoTitles.sector")} rowData={{ data: sector }} />
      <DetailsGridRow title={t("projectDetailsPage.infoTitles.technology")} rowData={{ data: technology }} />
      {metrics.map(metric => (
        <DetailsGridRow
          title={metric.name}
          key={metric.id}
          rowData={{
            dataType: "metric",
            data: metric.value,
            maxValue: metric.maxValue,
            metricUnit: metric.units,
          }}
        />
      ))}
      <Button component={Link} to={generateLinkUrl("projectById", id)} w="100%">
        {t("sectorCompassPage.sections.deployment.map.info.button")}
      </Button>
    </Grid>
  );
};

export default InfoContent;
