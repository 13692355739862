import { useEffect, FC, ChangeEvent } from "react";

import { Checkbox, List, Group, Collapse, Text, createStyles } from "@mantine/core";

import ChevronExpand from "@Components/ChevronExpand/ChevronExpand";
import { type NestedObject } from "@Lib/types/base";
import { type CheckboxFilterUI } from "@Lib/types/filters";
import { getChackboxState } from "@Lib/utils/taxonomyHandlers";

import CheckboxLabel from "./CheckboxLabel";

const useStyles = createStyles((theme, searchable: boolean) => ({
  root: {
    "& > div": {
      width: "100%",
    },
  },
  stickyLabel: {
    fontSize: theme.fontSizes.md,
    fontWeight: theme.other.fontWeight.semiBold,
    position: "sticky",
    top: searchable ? 52 : 0,
    backgroundColor: theme.white,
    paddingBlock: theme.spacing.xs,
    zIndex: 1,
    borderBottom: theme.other.getDefaultBorder(theme),
  },
  checkbox: {
    paddingBlock: 4,
    fontWeight: theme.other.fontWeight.regular,
    "& label": {
      color: theme.colors.grey[8],
      "&:hover": {
        color: theme.colors.grey[9],
      },
    },
  },
}));

type NestedCheckboxItemProps = CheckboxFilterUI & {
  checkedIds: NestedObject;
  setFilter: (checked: boolean, value: string) => void;
  expandedCache: string[];
  forceExpand?: boolean;
  isChild?: boolean;
  onExpand?: (id: string, expanded: boolean) => void;
  interactiveRoot?: boolean;
  searchable?: boolean;
  searchTerm?: string;
};

const NestedCheckboxItem: FC<NestedCheckboxItemProps> = ({
  value,
  label,
  children,
  checkedIds,
  setFilter,
  isChild = false,
  forceExpand = false,
  onExpand,
  expandedCache,
  interactiveRoot = false,
  searchable = false,
  searchTerm = "",
}) => {
  const { classes } = useStyles(searchable);

  const isChecked = getChackboxState(value, checkedIds);
  const isExpanded = forceExpand || expandedCache.includes(value);

  useEffect(() => {
    if (!children || !onExpand) {
      return;
    }

    for (const child of children) {
      if (getChackboxState(child.value, checkedIds)) {
        onExpand(value, isChecked);
        break;
      }
    }
  }, []);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.currentTarget.checked, value);
    onExpand && onExpand(value, e.currentTarget.checked);
  };

  const handleExpand = () => {
    onExpand && onExpand(value, !isExpanded);
  };

  const getListItemContent = () => {
    if (!isChild) {
      if (interactiveRoot) {
        return (
          <Group position="apart" noWrap spacing={0} className={classes.stickyLabel}>
            <Checkbox
              label={<CheckboxLabel label={label} searchable={searchable} searchTerm={searchTerm} />}
              checked={isChecked}
              onChange={handleOnChange}
              size={"xs"}
              transitionDuration={0}
              color="indigo"
            />
            {children && (
              <ChevronExpand expanded={isExpanded} disabled={false} handleExpand={handleExpand} type="light" />
            )}
          </Group>
        );
      }

      return <Text className={classes.stickyLabel}>{label}</Text>;
    }

    return (
      <Group position="apart" noWrap spacing={0} mt={"xs"}>
        <Checkbox
          label={<CheckboxLabel label={label} searchable={searchable} searchTerm={searchTerm} />}
          checked={isChecked}
          onChange={handleOnChange}
          size={"xs"}
          transitionDuration={0}
          color="indigo"
          className={classes.checkbox}
        />
        {children && <ChevronExpand expanded={isExpanded} disabled={false} handleExpand={handleExpand} type="light" />}
      </Group>
    );
  };

  return (
    <List.Item className={classes.root}>
      {getListItemContent()}

      {children && (
        <Collapse in={isExpanded} transitionDuration={200}>
          <List listStyleType="none" withPadding pl={isChild || interactiveRoot ? 20 : 4} py={4}>
            {children.map(item => (
              <NestedCheckboxItem
                key={item.value}
                isChild
                setFilter={setFilter}
                checkedIds={checkedIds}
                onExpand={onExpand}
                expandedCache={expandedCache}
                forceExpand={forceExpand}
                searchable={searchable}
                searchTerm={searchTerm}
                {...item}
              />
            ))}
          </List>
        </Collapse>
      )}
    </List.Item>
  );
};

export default NestedCheckboxItem;
