import { FC } from "react";

import { Box, createStyles } from "@mantine/core";

import NoDataMessageBase from "@Components/charts/common/NoDataMessageBase";
import Loader from "@Components/Loader/Loader";
import { useGetDeploymentMapCoordinates } from "@Lib/hooks/sectorCompass";

import Map from "./Map";

const useStyles = createStyles({
  root: {
    position: "relative",
    height: "70vh",
    width: "100%",
  },
});

type DeploymentMapProps = {
  sectorId: number;
  sectorPath: string[];
};

const DeploymentMap: FC<DeploymentMapProps> = ({ sectorId, sectorPath }) => {
  const { classes } = useStyles();
  const rootSectorId = parseInt(sectorPath[0]);

  const { data, isLoading } = useGetDeploymentMapCoordinates(sectorId);

  if (isLoading) {
    return (
      <Box className={classes.root}>
        <Loader />
      </Box>
    );
  }

  if (!data) {
    return (
      <Box className={classes.root}>
        <NoDataMessageBase />
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Map data={data} rootSectorId={rootSectorId} />
    </Box>
  );
};

export default DeploymentMap;
