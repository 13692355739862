import { type PaginationDataPayload } from "@Lib/types/base";
import { type SightlinesFullResponse, type SightlineBE, type FactorBE } from "@Lib/types/sightline";

import { appApi, goldApi } from "./api";

//TODO: Change the endpoint string when BE changes
export const getAllSightlines = async <T = SightlinesFullResponse>({
  page,
  perPage,
  filters,
}: Omit<PaginationDataPayload, "sorting">) => {
  const { data } = await goldApi.get<T>("/insights", {
    params: {
      page,
      page_size: perPage,
      ...filters,
    },
  });

  return data;
};

//TODO: Change the endpoint string when BE changes
export const getSightlineById = async (sightlineId: string) => {
  const { data } = await goldApi.get<SightlineBE>(`/insights/${sightlineId}`);

  return data;
};

export const makeCallRequest = async (sightlineId?: string) =>
  await appApi.post("/call-requests", {
    insight: sightlineId,
  });

export const getSightlineReadinessFactors = async () => {
  const { data } = await goldApi.get<FactorBE[]>("/factors");

  return data;
};
