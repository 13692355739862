import { FC } from "react";

import { useTranslation } from "react-i18next";
import { Bar, Line, LabelList } from "recharts";

import { useDealsChartData, useChartHoverState, useSetMagnitudeInfo } from "@Components/charts/common/hooks";
import NoDataMessageBase from "@Components/charts/common/NoDataMessageBase";
import { CHARTS_CONFIG } from "@Components/charts/config";
import { type MagnitudeInfo, type ChartConfig, type BarDatum } from "@Lib/types/base";
import {
  type ChartDataKeys,
  type ChartItemUI,
  type DatasetTypes,
  type ChartDataTypes,
  type TooltipBarData,
} from "@Lib/types/deals";

import ChartPlot from "./ChartPlot";
import LineDot from "./LineDot";
import LineLabelContent from "./LineLabelContent";

const { line, animationDuration, formatters, labels } = CHARTS_CONFIG;

type DealsChartBaseProps = {
  config: ChartConfig;
  fundingDataset: ChartItemUI[];
  hiddenDataKeys: ChartDataKeys[];
  datasetType: DatasetTypes;
  chartDataType: ChartDataTypes;
  xRangeValue?: [number, number];
  setMagnitudeInfo: (magnitudeInfo: MagnitudeInfo) => void;
  magnitudeInfo: MagnitudeInfo;
  onBarClick?: (barDatum: BarDatum) => void;
  NoDataComponent?: FC;
};

const DealsChartBase: FC<DealsChartBaseProps> = ({
  config = [],
  fundingDataset = [],
  hiddenDataKeys,
  datasetType,
  chartDataType,
  xRangeValue,
  setMagnitudeInfo,
  magnitudeInfo,
  onBarClick,
  NoDataComponent = NoDataMessageBase,
}) => {
  const { t } = useTranslation();

  const dataset = useDealsChartData<ChartItemUI>({
    fundingDataset,
    datasetType,
    chartDataType,
    xRangeValue,
  });

  useSetMagnitudeInfo(
    dataset.map(item => item.total),
    setMagnitudeInfo
  );

  const {
    activeIndex,
    setActiveIndex,
    activeBar,
    setActiveBarData,
    unsetActiveBarData,
    isLineActive,
    setLineActive,
    setLineInactive,
  } = useChartHoverState<TooltipBarData>();

  if (!fundingDataset.length) {
    return <NoDataComponent />;
  }

  const visibleBars = config.filter(item => !hiddenDataKeys.includes(item.dataKey) && item.display === "bar");
  const lastBarDataKey = visibleBars[visibleBars.length - 1].dataKey;

  return (
    <ChartPlot
      chartDataType={chartDataType}
      dataset={dataset}
      setActiveIndex={setActiveIndex}
      activeBar={activeBar}
      yAxisMagnitudeInfo={magnitudeInfo}
    >
      {config.map(datum => {
        const { display, dataKey, color } = datum;
        const isHidden = hiddenDataKeys.includes(dataKey);
        const showTotalLabel = dataKey === lastBarDataKey && chartDataType !== "percentage";

        if (display === "line") {
          return (
            <Line
              key={dataKey}
              hide={isHidden}
              dataKey={dataKey}
              strokeDasharray={line.strokeDasharray}
              yAxisId="dealsCount"
              name={t("charts.dealsCount.dealsBySector.legend.dealsCount")}
              stroke={color}
              strokeWidth={2}
              dot={line.getDot(color)}
              activeDot={
                <LineDot {...line.getActiveDot(color)} onMouseOver={setLineActive} onMouseOut={setLineInactive} />
              }
              animationDuration={animationDuration}
              isAnimationActive={false}
            >
              <LabelList
                dataKey="dealsCount"
                position="top"
                offset={10}
                style={line.labelList}
                stroke={color}
                content={
                  <LineLabelContent
                    style={line.labelList}
                    stroke={color}
                    isLineActive={isLineActive}
                    activeIndex={activeIndex}
                  />
                }
              />
            </Line>
          );
        }

        const onClick = onBarClick ? () => onBarClick(datum) : undefined;

        return (
          <Bar
            key={dataKey}
            hide={isHidden}
            dataKey={dataKey}
            stackId="a"
            fill={color}
            animationDuration={animationDuration}
            isAnimationActive={false}
            onMouseEnter={setActiveBarData}
            onMouseLeave={unsetActiveBarData}
            onClick={onClick}
            style={{ cursor: "pointer" }}
          >
            {showTotalLabel && (
              <LabelList
                dataKey="total"
                formatter={formatters.getFundingAxis(magnitudeInfo)}
                position="top"
                {...labels.bar}
              />
            )}
          </Bar>
        );
      })}
    </ChartPlot>
  );
};

export default DealsChartBase;
