import { MantineTheme, InputProps, type DefaultMantineColor } from "@mantine/core";

import { type ThemeGetter } from "@Lib/types/base";

const getCommonRoot = (theme: MantineTheme) => ({
  color: theme.colors.grey[9],
});

const getCheckBoxLabel = (theme: MantineTheme) => ({
  cursor: "pointer",
  paddingLeft: theme.spacing.xs,
  color: theme.colors.grey[9],
});

const getCommonLabel = (theme: MantineTheme) => ({
  color: "inherit",
  fontWeight: theme.other.fontWeight.semiBold,
  marginBottom: theme.other.extraSpacing.xxs,
});

const getCommonInput = (theme: MantineTheme) => ({
  color: "inherit",
  borderColor: theme.other.getDefaultBorderColor(theme),

  "&::placeholder": {
    color: theme.colors.grey[5],
  },
  "&:focus, &:focus-within": {
    borderColor: theme.colors.teal[5],
  },
});

const getCommonInputPadding = (theme: MantineTheme) => ({
  paddingInline: theme.spacing.sm,
  paddingBlock: theme.spacing.xs,
});

const getCommonReuired = (theme: MantineTheme) => ({
  color: theme.colors.red[5],
});

const getCommonInvalid = (theme: MantineTheme) => ({
  color: theme.colors.red[5],
  borderColor: theme.colors.red[5],
  "&::placeholder": {
    color: theme.colors.red[5],
  },
  "&:focus, &:focus-within": {
    borderColor: theme.colors.red[5],
  },
});

const getCommonError = (theme: MantineTheme) => ({
  color: theme.colors.red[5],
});

const getCommonDropdown = (theme: MantineTheme) => ({
  backgroundColor: theme.white,
  borderColor: theme.other.getDefaultBorderColor(theme),
  overflow: "hidden",
});

const getCommonDropdownItem = (theme: MantineTheme) => ({
  paddingInline: theme.spacing.sm,
  paddingBlock: theme.other.extraSpacing.xxs,
  color: theme.colors.grey[9],

  "&[data-hovered]": {
    backgroundColor: theme.colors.grey[2],
  },

  "&[data-selected]": {
    backgroundColor: theme.colors.teal[5],
    color: theme.white,
  },
});

export const inputStyles: ThemeGetter<InputProps> = theme => ({
  root: getCommonRoot(theme),
  label: getCommonLabel(theme),

  icon: {
    color: theme.colors.grey[5],
  },

  input: {
    ...getCommonInput(theme),
    ...getCommonInputPadding(theme),
  },

  required: getCommonReuired(theme),
  invalid: getCommonInvalid(theme),
  error: getCommonError(theme),
});

export const passwordInputStyles: ThemeGetter<InputProps> = theme => ({
  root: getCommonRoot(theme),
  label: getCommonLabel(theme),

  input: {
    ...getCommonInput(theme),
    ...getCommonInputPadding(theme),
  },

  innerInput: {
    color: "inherit",
    border: "none",
    "&::placeholder": {
      color: theme.colors.grey[5],
    },
  },

  required: getCommonReuired(theme),
  invalid: getCommonInvalid(theme),
  error: getCommonError(theme),
});

export const selectInputStyles: ThemeGetter<InputProps> = theme => ({
  root: getCommonRoot(theme),
  label: getCommonLabel(theme),

  input: {
    ...getCommonInput(theme),
    ...getCommonInputPadding(theme),
  },

  dropdown: getCommonDropdown(theme),

  itemsWrapper: {
    gap: theme.other.extraSpacing.xxs,
  },

  item: getCommonDropdownItem(theme),
});

export const multiSelectInputStyles: ThemeGetter<InputProps> = theme => ({
  label: getCommonLabel(theme),

  input: {
    ...getCommonInput(theme),
    paddingLeft: theme.spacing.xs,
    paddingRight: theme.spacing.md,
  },

  dropdown: getCommonDropdown(theme),

  itemsWrapper: {
    gap: theme.other.extraSpacing.xxs,
  },

  item: getCommonDropdownItem(theme),

  defaultValue: {
    color: theme.colors.grey[9],
    backgroundColor: theme.colors.grey[2],
    paddingBlock: 3,
    paddingLeft: theme.spacing.xs,
    borderRadius: 4,
  },

  defaultValueRemove: {
    margin: 0,
  },
});

export const checkboxStyles: ThemeGetter<{ color?: DefaultMantineColor }> = (theme, { color = "teal" }) => {
  return {
    body: {
      alignItems: "center",
    },
    input: {
      cursor: "pointer",
      borderColor: theme.colors.grey[6],
      "&:checked": {
        borderColor: theme.colors[color][5],
        backgroundColor: theme.colors[color][5],
      },
    },
    label: getCheckBoxLabel(theme),
  };
};

export const radioStyles: ThemeGetter = theme => ({
  body: {
    alignItems: "center",
  },
  radio: {
    cursor: "pointer",
    borderColor: theme.colors.grey[6],
    "&:checked": {
      borderColor: theme.colors.teal[5],
      backgroundColor: theme.colors.teal[5],
    },
  },
  label: getCheckBoxLabel(theme),
});

export const datePickerStyles: ThemeGetter = theme => ({
  label: getCommonLabel(theme),
});
