import { useQuery } from "@tanstack/react-query";

import { QUERIES } from "@Lib/constants";
import { getProjectStatusFilterData } from "@Lib/services/filters";
import { mapEntityBEToFilterUI } from "@Lib/utils/queries";

export const useGetProjectStatusFilterData = () =>
  useQuery({
    queryKey: QUERIES.getFilterProjectStatus(),
    queryFn: getProjectStatusFilterData,
    select: mapEntityBEToFilterUI,
    staleTime: Infinity,
  });
