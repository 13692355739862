import { FC } from "react";

import { Select, createStyles } from "@mantine/core";

import { useGetFilterUI } from "@Lib/hooks/common";
import { type TranslationKeys } from "@Lib/i18n/config";
import { type ChartDataTypes } from "@Lib/types/deals";

export const chartTypesSelectData: Array<{
  value: ChartDataTypes;
  labelKey: TranslationKeys;
}> = [
  {
    value: "periodic",
    labelKey: "charts.dealsCount.chartTypes.periodic",
  },
  {
    value: "cumulative",
    labelKey: "charts.dealsCount.chartTypes.cumulative",
  },
  {
    value: "percentage",
    labelKey: "charts.dealsCount.chartTypes.percentage",
  },
];

const useStyles = createStyles(theme => ({
  dataTypesControl: {
    width: 120,
    ...theme.other.getForceInputHeight(32),
  },
}));

type ChartTypeControlBaseProps = {
  chartDataType: ChartDataTypes;
  handleOnChange: (value: ChartDataTypes) => void;
};

const ChartTypeControlBase: FC<ChartTypeControlBaseProps> = ({ chartDataType, handleOnChange }) => {
  const { classes } = useStyles();

  const translatedChartTypesSelectData = useGetFilterUI(chartTypesSelectData);

  return (
    <Select
      data={translatedChartTypesSelectData}
      value={chartDataType}
      onChange={handleOnChange}
      className={classes.dataTypesControl}
    />
  );
};

export default ChartTypeControlBase;
