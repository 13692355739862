import { type MagnitudeInfo } from "@Lib/types/base";
import { type DatasetTypes, type ChartDataTypes } from "@Lib/types/deals";
import { getSliderStep, getCorrectionValue } from "@Lib/utils/chart";

import createStore from "../createStore";

type ChartStore<T> = {
  magnitudeInfo: MagnitudeInfo;
  setMagnitudeInfo: (magnitudeInfo: MagnitudeInfo) => void;
  hiddenDataKeys: T[];
  setHiddenDataKey: (dataKey: T, hideData: boolean) => void;
  setHiddenDataKeys: (hiddenDataKeys: T[]) => void;
  resetState: () => void;
};

/**
 * A factory that returns a base store for chart data
 * @param storeName Store name visible in debug tools
 * @returns Chart data store
 */
export const getChartStore = <T = string>(storeName: string) => {
  const initialState: Pick<ChartStore<T>, "magnitudeInfo" | "hiddenDataKeys"> = {
    magnitudeInfo: { magnitude: "", magnitudeFactor: 1 },
    hiddenDataKeys: [],
  };

  return createStore<ChartStore<T>>(
    set => ({
      ...initialState,
      setMagnitudeInfo: magnitudeInfo =>
        set({ magnitudeInfo: magnitudeInfo }, false, {
          type: `${storeName}: setMagnitudeInfo`,
          payload: magnitudeInfo,
        }),
      setHiddenDataKey: (dataKey, hideData) => {
        set(
          state => {
            const nextHiddenDataKeys = hideData
              ? [...state.hiddenDataKeys, dataKey]
              : state.hiddenDataKeys.filter(key => key !== dataKey);
            return { hiddenDataKeys: nextHiddenDataKeys };
          },
          false,
          {
            type: `${storeName}: setHiddenDataKey`,
            payload: {
              dataKey,
              hideData,
            },
          }
        );
      },
      setHiddenDataKeys: hiddenDataKeys => {
        set({ hiddenDataKeys }, false, { type: `${storeName}: setVisibleBars`, payload: hiddenDataKeys });
      },
      resetState: () => set(initialState, false, `${storeName}: resetState`),
    }),
    storeName
  );
};

interface SliderData {
  step: number;
  labels: string[];
  range: [number, number];
}

type ChartControlsStore = {
  datasetType: DatasetTypes;
  prevDatasetType: DatasetTypes;
  setDatasetType: (datasetType: DatasetTypes) => void;
  // Controls dataset display "periodic" | "cumulative" | "percentage"
  chartDataType: ChartDataTypes;
  setChartDataType: (chartDataType: ChartDataTypes) => void;
  // Controls dataset Start / End date
  slider: SliderData;
  initSlider: (labels: string[]) => void;
  setSliderRange: (range: [number, number]) => void;
  xRangeValue?: [number, number];
  setXRangeValue: (xRangeValue: [number, number]) => void;
  resetSlider: () => void;
  resetState: () => void;
};

export const getChartControlsStore = (storeName: string, chartDataType: ChartDataTypes = "periodic") => {
  const initialState: Pick<
    ChartControlsStore,
    "datasetType" | "prevDatasetType" | "chartDataType" | "slider" | "xRangeValue"
  > = {
    datasetType: "halfYear",
    prevDatasetType: "halfYear",
    chartDataType,
    slider: {
      step: 1,
      labels: [],
      range: [0, 100],
    },
    xRangeValue: undefined,
  };

  return createStore<ChartControlsStore>(
    set => ({
      ...initialState,
      // Controls state
      setDatasetType: datasetType =>
        set(
          state => ({
            prevDatasetType: state.datasetType,
            datasetType,
          }),
          false,
          {
            type: `${storeName}: setDatasetType`,
            payload: datasetType,
          }
        ),
      setChartDataType: chartDataType =>
        set({ chartDataType }, false, {
          type: `${storeName}: setChartDataType`,
          payload: chartDataType,
        }),
      initSlider: labels =>
        set(
          state => {
            const nextNumberOfLabels = labels.length;
            /**
             * `initSlider` has beed called from outside with the same labels
             */
            if (nextNumberOfLabels === state.slider.labels.length) {
              return state;
            }

            const currStep = state.slider.step;
            const currXRangeValue = state.xRangeValue;
            const correctionValue = getCorrectionValue(state.prevDatasetType, state.datasetType);
            const nextStep = getSliderStep(nextNumberOfLabels);

            /**
             * We set slider data for the first time `currXRangeValue === undefined` or
             * Chart dataset is not changed `correctionValue === null`
             **/
            if (currXRangeValue === undefined || correctionValue === null) {
              return {
                slider: {
                  labels,
                  step: nextStep,
                  range: [0, 100],
                },
              };
            }

            const nextMaxIndex = nextNumberOfLabels - 1;
            const nextXRangeValue: [number, number] = [0, nextMaxIndex];

            if (currStep < nextStep) {
              // We have less labels, bigger step
              nextXRangeValue[0] = Math.floor(currXRangeValue[0] / correctionValue);
              nextXRangeValue[1] = Math.floor(currXRangeValue[1] / correctionValue);
            } else {
              // We have more labels, smaller step
              nextXRangeValue[0] = currXRangeValue[0] * correctionValue;
              nextXRangeValue[1] = Math.min(nextMaxIndex, currXRangeValue[1] * correctionValue + correctionValue - 1);
            }

            return {
              xRangeValue: nextXRangeValue,
              slider: {
                labels,
                step: nextStep,
                range: [nextXRangeValue[0] * nextStep, nextXRangeValue[1] * nextStep],
              },
            };
          },
          false,
          {
            type: `${storeName}: initSlider`,
            payload: labels,
          }
        ),
      setSliderRange: range =>
        set(
          state => ({
            slider: {
              ...state.slider,
              range,
            },
          }),
          false,
          {
            type: `${storeName}: setSliderRange`,
            payload: range,
          }
        ),
      setXRangeValue: xRangeValue =>
        set({ xRangeValue }, false, {
          type: `${storeName}: setXRangeValue`,
          payload: xRangeValue,
        }),
      resetSlider: () =>
        set(
          state => ({
            slider: {
              ...state.slider,
              range: [0, 100],
            },
            xRangeValue: undefined,
          }),
          false,
          `${storeName}: resetSlider`
        ),
      resetState: () => set(initialState, false, `${storeName}: resetState`),
    }),
    storeName
  );
};
