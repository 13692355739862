import { baseColors } from "@Context/Theme/chartsColors";
import { type MagnitudeInfo } from "@Lib/types/base";
import { formatMoney } from "@Lib/utils/formatters";

const mainFontSize = 12;
const smallFontSize = 10;

export const CHARTS_CONFIG = {
  colors: baseColors,
  minBarSize: 4,
  mainFontSize,
  smallFontSize,
  margin: {
    top: 26,
    right: 18,
    left: -6,
    bottom: 12,
  },
  animationDuration: 250,
  xPadding: { left: 8, right: 8 },
  legend: {
    iconConfig: {
      rect: {
        w: 11,
        h: 5,
      },
      line: {
        w: 25,
        h: 4,
      },
    },
  },
  line: {
    getDot: (color: string) => ({ r: 2, strokeDasharray: "none", fill: color }),
    getActiveDot: (color: string) => ({ strokeWidth: 2, r: 5, fill: "white", stroke: color }),
    strokeDasharray: "3 3",
    labelList: { textShadow: "0px 0px 8px white", fill: baseColors.black, fontSize: mainFontSize },
    offsetTop: 30,
  },
  labels: {
    getYLabel1: (value: string) => ({
      value,
      angle: -90,
      position: "insideCenter",
      dx: -24,
      fontSize: mainFontSize,
    }),
    getYLabel2: (value: string) => ({
      value,
      angle: -90,
      position: "insideCenter",
      dx: 24,
      fontSize: mainFontSize,
      fill: baseColors.black,
    }),
    ticks: {
      fontSize: mainFontSize,
      fill: baseColors.black,
    },
    bar: {
      fontSize: mainFontSize,
      fill: baseColors.black,
    },
    barSmall: {
      fontSize: smallFontSize,
      fill: baseColors.black,
    },
  },
  formatters: {
    percentageAxis: (value: number) => `${value}%`,
    getFundingAxis: (magnitudeInfo: MagnitudeInfo) => (value: number) => {
      const result = value / magnitudeInfo.magnitudeFactor;

      return `${result >= 9.95 ? Math.round(result).toFixed(0) : (Math.round(result * 10) / 10).toFixed(1)}`;
    },
    datesAxis: (value: string) => {
      if (/\s/.test(value)) {
        return `${value.slice(0, 2)}'${value.slice(-2)}`;
      }

      return value;
    },
    tooltipMoney: (value = 0, dataKey?: string | number) => {
      if (dataKey === "dealsCount") {
        return value;
      }

      if (value === 0) {
        return formatMoney(value);
      }
      return formatMoney(value, {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1,
      });
    },
    tooltipPercentage: (value = 0, dataKey?: string | number) => {
      if (dataKey === "dealsCount") {
        return value;
      }

      return `${Math.round(value * 10) / 10}%`;
    },
    money: (value: number) => {
      return formatMoney(value);
    },
    magnitudeNumber: (value: number) => {
      return Intl.NumberFormat("en-US", {
        notation: "compact",
        maximumFractionDigits: 1,
        compactDisplay: "short",
      })
        .format(value)
        .slice(0, -1);
    },
  },
};

export const ORGANIZATION_STAGE_CHART_CONFIG = {
  CHART_MARGIN: {
    left: 8,
    right: 56,
    top: 16,
    bottom: 10,
  },
};

export const ANNUAL_CHART_CONFIG = {
  CHART_MARGIN: { top: 24, bottom: 8, right: 16, left: 16 },
  BAR_CATEGORY_GAP: 4,
  BAR_GAP: 1.5,
  MAX_DOMAIN_MULTIPLIER: 1.12,
  MAX_DOMAIN_MULTIPLIER_ARROW: 1.1,
  isLabelFlipped: (labelText: string, barWidth: number): boolean => {
    const CHAR_WIDTH_LIMIT = 5;

    return labelText.length * CHAR_WIDTH_LIMIT > barWidth;
  },
  ARROW_LABEL_OFFSET: 16,
  getYOffset: (label: string, labelFlipped: boolean, offset = 0): number => {
    const resolveOffset = () => {
      if (!labelFlipped) {
        return 16;
      }

      const labelLength = label.length;
      if (labelLength <= 3 && label.includes(".")) {
        return 24;
      }

      return labelLength * 10;
    };

    return resolveOffset() + offset;
  },
};

export const TOP_DEALS_CHART_CONFIG = {
  CHART_MARGIN: {
    top: 16,
    right: 16,
    bottom: 8,
    left: 0,
  },
  BAR_CATEGORY_GAP: 6,
  DUMMY_DATA_AMOUNT: -1,
};

export const EXITS_CHART_CONFIG = {
  CHART_MARGIN: {
    top: 16,
    right: 8,
    bottom: 8,
    left: 8,
  },
  BAR_CATEGORY_GAP: 4,
};

export const PROJECTS_PROGRESS_CHART_CONFIG = {
  CHART_MARGIN: {
    top: 0,
    right: 8,
    bottom: 8,
    left: 0,
  },
  BAR_CATEGORY_GAP: 4,
  Y_AXES_LABEL_WIDTH: 129,
  capacityTickFormatter: (value: number): string =>
    Intl.NumberFormat("en-US", {
      maximumFractionDigits: 1,
    }).format(value),
  capacityFormatter: (value: number): string => {
    if (value === 0) {
      return "0.0";
    }

    const valueFixed = Math.round(value * 100) / 100;
    const remainder = valueFixed - Math.floor(valueFixed);
    const formatedResult = Intl.NumberFormat("en-US", {
      maximumFractionDigits: 1,
    }).format(valueFixed);

    if (remainder >= 0.95) {
      return `${formatedResult}.0`;
    }

    return formatedResult;
  },
};

export const PROJECTS_OPERATIONAL_DATE_CHART_CONFIG = {
  ...PROJECTS_PROGRESS_CHART_CONFIG,
  CHART_MARGIN: {
    top: 0,
    right: 8,
    bottom: 8,
    left: 16,
  },
};
