import { FC } from "react";

import { Box, createStyles, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

import { LegendUI } from "@Lib/types/sectorCompass";

const useStyles = createStyles(theme => ({
  root: {
    width: "200px",
    position: "absolute",
    bottom: theme.spacing.md,
    right: 10,
    backgroundColor: theme.white,
    padding: theme.spacing.sm,
    borderRadius: theme.spacing.xs,
  },
  title: {
    fontWeight: theme.other.fontWeight.semiBold,
    paddingBottom: theme.spacing.xs,
  },
  legendItem: {
    fontSize: theme.fontSizes.sm,
    display: "flex",
    alignItems: "center",
    gap: "10px",
    flexWrap: "nowrap",
  },
  dot: {
    width: "10px",
    height: "10px",
    flexShrink: 0,
    flexGrow: 0,
    borderRadius: "50%",
    marginRight: "5px",
  },
}));

type LegendProps = {
  legend: LegendUI[];
  legendColorMap: Record<string, string>;
};

const Legend: FC<LegendProps> = ({ legend, legendColorMap }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <Text className={classes.title}>{t("sectorCompassPage.sections.deployment.map.legend.title")}</Text>
      {legend?.map(item => (
        <Box key={item.id} className={classes.legendItem}>
          <Box className={classes.dot} style={{ backgroundColor: legendColorMap[item.id] }} />
          <Text>{item.name}</Text>
        </Box>
      ))}
    </Box>
  );
};

export default Legend;
