import { FC } from "react";

import { Group } from "@mantine/core";
import { useTranslation } from "react-i18next";

import BasicChip from "@Components/BasicChip/BasicChip";

type SightlineChipRowProps = {
  isPaid?: boolean;
  rootSector?: string;
};

const SightlineChipRow: FC<SightlineChipRowProps> = ({ isPaid, rootSector }) => {
  const { t } = useTranslation();

  if (!isPaid && !rootSector) {
    return null;
  }

  return (
    <Group spacing="xs">
      {isPaid && (
        <BasicChip size="xs" chipVariant="green">
          {t("sightlinePage.badgeExclusive")}
        </BasicChip>
      )}
      {rootSector && (
        <BasicChip size="xs" chipVariant="grey">
          {rootSector}
        </BasicChip>
      )}
    </Group>
  );
};

export default SightlineChipRow;
