import { FC } from "react";

import { Box, createStyles, Text } from "@mantine/core";
import { useParams } from "react-router-dom";

import Loader from "@Components/Loader/Loader";
import { useGetCompassById } from "@Lib/hooks/sectorCompass";

import RtSInfoContentRow from "./RtSInfoContentRow";

const useStyles = createStyles(theme => ({
  subtitle: {
    maxWidth: 450,
    marginBottom: theme.spacing.sm,
  },
}));

type RtSInfoContentProps = {
  subtitle: string;
};

const RtSInfoContent: FC<RtSInfoContentProps> = ({ subtitle }) => {
  const { classes } = useStyles();

  const { compassId } = useParams();
  const { isLoading, data } = useGetCompassById(compassId);

  const capacityUnits = data?.capacityUnits;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box>
      <Text className={classes.subtitle}>{subtitle}</Text>
      {data?.scales.map((scale, idx) => (
        <RtSInfoContentRow key={scale.id} scale={scale} isRoot={idx === 0} capacityUnits={capacityUnits} />
      ))}
    </Box>
  );
};

export default RtSInfoContent;
