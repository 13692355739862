import { forwardRef, useMemo, useEffect } from "react";

import { Box, BoxProps } from "@mantine/core";
import { useTranslation } from "react-i18next";

import ChartContainer from "@Components/charts/common/ChartContainer";
import ResponsiveWrapper from "@Components/charts/common/ResponsiveWrapper";
import { GoToLink, LinkRow } from "@Components/GoToLink";
import PageSection from "@Components/PageSection/PageSection";
import { ROUTES } from "@Lib/constants";
import { DOWNLOAD_IDS } from "@Lib/constants/ui";
import { useGetStageChartData, useGetSectorChartData } from "@Lib/hooks/deals";
import { useFiltersStore } from "@Lib/store/investmentDash/filters";
import {
  useInvestmentChartsStore,
  useStageChartStore,
  useSectorChartStore,
  useChartsFilterStore,
  filterForAPISelector,
} from "@Lib/store/sectorCompass/investment";

import ChartControls from "./ChartControls";
import DealsSectorChart from "./DealsSectorChart";
import DealsStageChart from "./DealsStageChart";
import DownloadButton from "./DownloadButton";

type InvestmentSectionProps = BoxProps & {
  sectorId: number;
  marketName: string;
  sectorPath: string[];
};

const InvestmentSection = forwardRef<HTMLDivElement, InvestmentSectionProps>(
  ({ sectorId, marketName, sectorPath, ...boxProps }, ref) => {
    const { t } = useTranslation();

    const setTaxonomyFilter = useFiltersStore(state => state.setTaxonomyFilter);
    const clearFilters = useFiltersStore(state => state.clearFilters);

    const handleLinkClick = () => {
      clearFilters();
      setTaxonomyFilter({
        action: "add",
        filterField: "company_primary_sector_id",
        localField: "companyPrimarySectorIdsLocal",
        value: sectorPath.join("_"),
      });
    };

    const datasetType = useInvestmentChartsStore(state => state.datasetType);
    const resetState = useInvestmentChartsStore(state => state.resetState);

    const magnitudeInfoStage = useStageChartStore(state => state.magnitudeInfo);
    const resetStageState = useStageChartStore(state => state.resetState);

    const magnitudeInfoSector = useSectorChartStore(state => state.magnitudeInfo);
    const resetSectorState = useSectorChartStore(state => state.resetState);

    const storeFilters = useChartsFilterStore(filterForAPISelector);
    const resetFiltersState = useChartsFilterStore(state => state.clearFilters);

    // Pass filter values to queries
    const [queryStageConfig, queryStage] = useGetStageChartData({
      datasetType,
      primarySectorId: sectorId,
      storeFilters,
    });

    const [querySectorConfig, querySector] = useGetSectorChartData({
      datasetType,
      primarySectorId: sectorId,
      storeFilters,
    });

    useEffect(() => {
      return () => {
        resetState();
        resetStageState();
        resetSectorState();
        resetFiltersState();
      };
    }, []);

    const rangeLabels = useMemo(() => queryStage.data?.map(item => item.dateRange), [queryStage.data]);

    return (
      <PageSection
        ref={ref}
        title={t("sectorCompassPage.sections.investment.title")}
        buttons={[<DownloadButton key="charts-download" sectorId={sectorId} marketName={marketName} />]}
        {...boxProps}
      >
        <ChartControls
          rangeLabels={rangeLabels}
          stageConfig={queryStageConfig.data?.config}
          primarySectorId={sectorId}
        />

        <ResponsiveWrapper>
          <ChartContainer
            id={DOWNLOAD_IDS.stageChartId}
            label={t("charts.dealsCount.dealsByStage.label")}
            labelAppendix={t("charts.dealsCount.dealsByStage.labelAppendix", {
              magnitude: magnitudeInfoStage.magnitude,
            })}
            isLoading={queryStage.isLoading || queryStageConfig.isLoading}
            isError={queryStage.isError || queryStageConfig.isError}
          >
            <DealsStageChart config={queryStageConfig.data?.config} fundingDataset={queryStage.data} />
          </ChartContainer>
          <ChartContainer
            id={DOWNLOAD_IDS.sectorChartId}
            label={t("charts.dealsCount.dealsBySector.label")}
            labelAppendix={t("charts.dealsCount.dealsBySector.labelAppendix", {
              magnitude: magnitudeInfoSector.magnitude,
            })}
            isLoading={querySector.isLoading || querySectorConfig.isLoading}
            isError={querySector.isError || querySectorConfig.isError}
          >
            <DealsSectorChart config={querySectorConfig.data?.config} fundingDataset={querySector.data} />
          </ChartContainer>
        </ResponsiveWrapper>

        <Box onClick={handleLinkClick}>
          <LinkRow>
            <GoToLink to={ROUTES.investment} text={t("sectorCompassPage.sections.investment.link", { marketName })} />
          </LinkRow>
        </Box>
      </PageSection>
    );
  }
);

export default InvestmentSection;
