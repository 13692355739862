export const SORT_FIELDS_MAP = {
  deals: {
    // Options: deal_date, deal_type_name, primary_sector_name, funding_usd
    date: "deal_date",
    stage: "deal_type_name",
    sectorName: "primary_sector_name",
    funding: "funding_usd",
  },
  companies: {
    // Options: name, stage, last_deal_date, funding, primary_sector_name, city_display_name, founded_date, company_size_range, status
    name: "name",
    latestStage: "stage",
    funding: "funding",
    sector: "primary_sector_name",
    location: "city_display_name",
    yearFounded: "founded_date",
    companySize: "company_size_range", // Doesn't seem to work
    status: "status",
    latestDealDate: "last_deal_date",
  },
  investors: {
    // Options: name, fund_size_range, is_lead, last_investment_date, city_display_name
    name: "name",
    fundSize: "fund_size_range",
    isLead: "is_lead",
    lastInvestment: "last_investment_date",
    location: "city_display_name",
    dealInvestedCount: "deal_invested_count",
  },
  projects: {
    name: "name",
    notableStatusName: "notable_status",
    sector: "sector",
    technology: "technology",
    progress: "progress",
    scale: "scale",
    location: "location",
    operationDate: "operation_date",
  },
};
