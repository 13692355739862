import { useQueries } from "@tanstack/react-query";

import { QUERIES, DealsGroupByEnum } from "@Lib/constants";
import { getDealsChartsData } from "@Lib/services/deals";
import { type DealsChartResponse, type DealsChartServiceInput } from "@Lib/types/deals";
import { chartDataSelector, getDealsSectorChartConfig } from "@Lib/utils/chart";

type ChartConfig = {
  config: ReturnType<typeof getDealsSectorChartConfig>;
};

const configSelector = (response: DealsChartResponse): ChartConfig => {
  return {
    config: getDealsSectorChartConfig(response.results),
  };
};

export const useGetSectorChartData = ({
  datasetType,
  storeFilters,
  primarySectorId,
}: Omit<DealsChartServiceInput, "chartVariant">) =>
  useQueries({
    queries: [
      {
        // We NEED config to react on storeFilters change, since we drill down inside sectors and chart legend must be recreated
        queryKey: QUERIES.getDealsSectorChartData("config", storeFilters, primarySectorId),
        queryFn: () =>
          getDealsChartsData({
            chartVariant: DealsGroupByEnum.sector,
            datasetType: "config",
            storeFilters,
            primarySectorId,
          }),
        select: configSelector,
      },
      {
        queryKey: QUERIES.getDealsSectorChartData(datasetType, storeFilters, primarySectorId),
        queryFn: () =>
          getDealsChartsData({
            chartVariant: DealsGroupByEnum.sector,
            datasetType,
            storeFilters,
            primarySectorId,
          }),
        select: chartDataSelector,
      },
    ],
  });
