import { createContext, useContext } from "react";

// Define the shape of the context
interface RaceToScaleContextType {
  hoveredId: string | null;
  setHoveredId: (id: string) => void;
  unsetHoveredId: () => void;
}

// Create the context with a default value
export const RaceToScaleContext = createContext<RaceToScaleContextType>(null!);

// Custom hook for using the context
export const useRaceToScaleContext = () => useContext(RaceToScaleContext);
