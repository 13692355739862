import { FC, PropsWithChildren } from "react";

import { Box, createStyles } from "@mantine/core";

const useStyles = createStyles(theme => ({
  root: {
    padding: theme.spacing.sm,
    backgroundColor: theme.white,
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    columnGap: theme.spacing.sm,
    rowGap: theme.spacing.md,
    overflow: "hidden",
    [theme.fn.smallerThan("md")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.fn.smallerThan("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
}));

type ContextContainerProps = PropsWithChildren & {
  downloadId: string;
};

const ContextContainer: FC<ContextContainerProps> = ({ downloadId, children }) => {
  const { classes } = useStyles();

  return (
    <Box id={downloadId}>
      <Box className={classes.root}>{children}</Box>
    </Box>
  );
};

export default ContextContainer;
