import { FC } from "react";

import { Bar, BarChart, XAxis, YAxis, Tooltip, Legend, LabelList, Rectangle } from "recharts";

import withResponsiveContainer, {
  WithResponsiveContainerProps,
} from "@Components/charts/common/withResponsiveContainer";
import { CHARTS_CONFIG, PROJECTS_PROGRESS_CHART_CONFIG } from "@Components/charts/config";
import { type ProjectsChartDatum, type ProjectsChartConfig } from "@Lib/types/sectorCompass";

import BarLabel from "./BarLabel";
import LegendContent from "./LegendContent";
import TooltipContent from "./TooltipContent";

const { animationDuration, colors, labels } = CHARTS_CONFIG;
const { CHART_MARGIN, BAR_CATEGORY_GAP, Y_AXES_LABEL_WIDTH, capacityFormatter } = PROJECTS_PROGRESS_CHART_CONFIG;

type ProjectsChartProps = WithResponsiveContainerProps & {
  units: string;
  dataset: ProjectsChartDatum[];
  config: ProjectsChartConfig[];
  tooltipWithTitle?: boolean;
  noteText?: string;
};

const ProjectsChart: FC<ProjectsChartProps> = ({
  units,
  dataset,
  config,
  tooltipWithTitle = true,
  noteText,
  ...responsiveProps
}) => {
  return (
    <BarChart
      data={dataset}
      layout="vertical"
      margin={CHART_MARGIN}
      barCategoryGap={`${BAR_CATEGORY_GAP}%`}
      {...responsiveProps}
    >
      {config.map((item, idx) => (
        <Bar key={item.dataKey} dataKey={item.dataKey} stackId="a" fill={item.fill} isAnimationActive={false}>
          {idx === config.length - 1 && (
            <LabelList
              offset={4}
              dataKey="group"
              formatter={capacityFormatter}
              content={<BarLabel dataset={dataset} />}
              {...labels.bar}
            />
          )}
        </Bar>
      ))}
      <XAxis type="number" dataKey="total" domain={[0, (dataMax: number) => dataMax * 1.25]} hide />
      <YAxis
        type="category"
        dataKey="group"
        axisLine={{ stroke: colors.axisLine }}
        tickLine={false}
        tick={labels.ticks}
        interval={0}
        width={Y_AXES_LABEL_WIDTH}
      />
      <Tooltip
        cursor={<Rectangle fill={colors.barCursor} />}
        content={<TooltipContent withTitle={tooltipWithTitle} units={units} formatter={capacityFormatter} />}
        animationDuration={animationDuration}
      />
      <Legend content={<LegendContent noteText={noteText} />} />
    </BarChart>
  );
};

export default withResponsiveContainer(ProjectsChart);
