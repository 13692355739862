import { useEffect, useState, useCallback, FC } from "react";

import { Box, Group, Text, RangeSlider, BoxProps } from "@mantine/core";
import { useTranslation } from "react-i18next";

const getXRangeIndex = (value: number, step: number) => Math.round(value / step);
const getSliderLabel = (leftLabel: string, rightLabel: string) => `${leftLabel} - ${rightLabel}`;
const MIN_WIDTH = 230;

type DateRangeControlBaseProps = BoxProps & {
  rangeLabels?: string[];
  initSlider: (labels: string[]) => void;
  slider: {
    step: number;
    labels: string[];
    range: [number, number];
  };
  setXRangeValue: (xRangeValue: [number, number]) => void;
  setSliderRange: (range: [number, number]) => void;
  disabled?: boolean;
};

const DateRangeControlBase: FC<DateRangeControlBaseProps> = ({
  rangeLabels,
  initSlider,
  slider,
  setXRangeValue,
  setSliderRange,
  disabled,
  ...boxProps
}) => {
  const { t } = useTranslation();
  const [rangeLabel, setRangeLabel] = useState("");

  useEffect(() => {
    if (!rangeLabels) {
      return;
    }

    initSlider(rangeLabels);
    setRangeLabel(getSliderLabel(rangeLabels[0], rangeLabels[rangeLabels.length - 1]));
  }, [rangeLabels]);

  const handleChangeEnd = useCallback(
    (rangeValue: [number, number]) => {
      if (disabled || !rangeLabels) {
        return;
      }

      const leftIdx = getXRangeIndex(rangeValue[0], slider.step);
      const rightIdx = getXRangeIndex(rangeValue[1], slider.step);
      setXRangeValue([leftIdx, rightIdx]);
      setRangeLabel(getSliderLabel(rangeLabels[leftIdx], rangeLabels[rightIdx]));
    },
    [disabled, rangeLabels, slider.step]
  );

  const getTooltipLabel = useCallback(
    (value: number) => rangeLabels && rangeLabels[getXRangeIndex(value, slider.step)],
    [rangeLabels, slider.step]
  );

  return (
    <Box w={MIN_WIDTH} miw={MIN_WIDTH} {...boxProps}>
      <Group mb={"xs"} position="apart">
        <Text span size={"sm"} weight={600}>
          {t("charts.dealsCount.dateRangeSlider")}
        </Text>
        <Text span size={"sm"}>
          {rangeLabel}
        </Text>
      </Group>

      <RangeSlider
        size={"xs"}
        value={slider.range}
        onChange={setSliderRange}
        onChangeEnd={handleChangeEnd}
        label={getTooltipLabel}
        minRange={slider.step}
        step={slider.step}
        styles={theme => ({
          thumb: {
            pointerEvents: disabled ? "none" : "initial",
          },
          track: {
            "&:before": {
              background: theme.colors.grey[2],
            },
          },
          bar: {
            backgroundColor: disabled ? theme.colors.grey[3] : theme.colors.teal[3],
          },
        })}
        disabled={disabled}
      />
    </Box>
  );
};

export default DateRangeControlBase;
