import { useEffect, FC } from "react";

import { type Marker } from "@googlemaps/markerclusterer";
import { useMap } from "@vis.gl/react-google-maps";

import { useGetDeploymentMapProjects } from "@Lib/hooks/sectorCompass";
import { type Point } from "@Lib/types/sectorCompass";

import LoadingContent from "./LoadingContent";
import MultipleProjectsContent from "./MultipleProjectsContent";
import SingleProjectContent from "./SingleProjectContent";

type LocationTooltipProps = {
  selectedMarker: Marker;
  selectedPoint: Point;
  handleInfoWindowClose: () => void;
};

const LocationTooltip: FC<LocationTooltipProps> = ({ selectedMarker, selectedPoint, handleInfoWindowClose }) => {
  const map = useMap();

  const projectIds = selectedPoint.projects.map(project => project.id);
  const { isLoading, projects } = useGetDeploymentMapProjects(projectIds);

  useEffect(() => {
    const mapClickListener = map?.addListener("click", handleInfoWindowClose);

    return () => {
      if (mapClickListener) {
        google.maps.event.removeListener(mapClickListener);
      }
    };
  }, [map]);

  if (isLoading) {
    return <LoadingContent selectedMarker={selectedMarker} />;
  }

  if (projects.length === 1) {
    return <SingleProjectContent selectedMarker={selectedMarker} project={projects[0]} />;
  }

  return <MultipleProjectsContent selectedMarker={selectedMarker} projects={projects} />;
};

export default LocationTooltip;
