import { MantineThemeOverride, MantineTheme } from "@mantine/core";

import { baseColors, dealsCountLine, sectorBarsById } from "./chartsColors";
import {
  appShellStyles,
  headerStyles,
  buttonStyles,
  actionIconStyles,
  menuStyles,
  modalStyles,
  notificationStyles,
  imageStyles,
  tabsStyles,
} from "./componentsStyles";
import {
  passwordInputStyles,
  inputStyles,
  selectInputStyles,
  multiSelectInputStyles,
  checkboxStyles,
  radioStyles,
  datePickerStyles,
} from "./inputStyles";
import { tableStyles, paginationStyles } from "./tableStyles";

export const themeConfig: MantineThemeOverride = {
  colors: {
    grey: [
      "#FFFFFF", // white
      "#FAFAFA", // 100
      "#F3F4F5", // 200
      "#E0E0E0", // 300
      "#B2B2B2", // 400
      "#8C8C8C", // 500 main
      "#666666", // 600
      "#404040", // 700
      "#333333", // 800
      "#000000", // black
    ],
    teal: [
      "#E6F5F5", // 50
      "#B1DFE1", // 100
      "#8BD0D2", // 200
      "#56BABE", // 300
      "#35ADB1", // 400
      "#03989E", // 500 main
      "#038A90", // 600
      "#026C70", // 700
      "#025457", // 800
      "#014042", // 900
    ],
    indigo: [
      // Blue in design
      "#F4F4FF", // 50
      "#E8E7FF", // 100
      "#D5D3FB", // 200
      "#B5B2F8", // 300
      "#8280F6", // 400
      "#5350EF", // 500 main
      "#47509B", // 600
      "#353A71", // 700
      "#282B56", // 800
      "#191C43", // 900
    ],
    pink: [
      "#FFF9F8", // 50
      "#FFF6F5", // 100
      "#FCE8E6", // 200
      "#FADCD8", // 300
      "#F6C2BB", // 400
      "#F2A89E", // 500 main
      "#DA928A", // 600
      "#A97770", // 700
      "#815A55", // 800
      "#6C4843", // 900
    ],
    red: [
      "#FFF8F8", // 50
      "#FDF0EF", // 100
      "#FADAD6", // 200
      "#F7C3BE", // 300
      "#F2978D", // 400
      "#EC6A5C", // 500 main
      "#CA5E50", // 600
      "#A44D41", // 700
      "#682824", // 800
      "#5C1D19", // 900
    ],
    yellow: [
      "#FFFCF8", // 50
      "#FFF8ED", // 100
      "#FDF1DC", // 200
      "#FBE8C7", // 300
      "#F8D69C", // 400
      "#F5C572", // 500 main
      "#CCAA55E5", // 600
      "#A98A52", // 700
      "#80683E", // 800
      "#6C542A", // 900
    ],
    green: [
      "#F5F9F1", // 50
      "#DFEBD5", // 100
      "#CFE2C0", // 200
      "#B9D5A3", // 300
      "#ACCD91", // 400
      "#97C076", // 500 main
      "#89AF6B", // 600
      "#6B8854", // 700
      "#536A41", // 800
      "#3F5132", // 900
    ],
    blue: [
      // Light Blue in design
      "#F8FAFF", // 50
      "#ECF1FF", // 100
      "#DDE5FC", // 200
      "#C9D6FA", // 300
      "#A1B7F7", // 400
      "#7998F3", // 500 main
      "#7189BD", // 600
      "#56698F", // 700
      "#41506D", // 800
      "#2C3952", // 900
    ],
  },
  primaryColor: "teal",
  primaryShade: 5,
  fontFamily: "'Inter', sans-serif",
  lineHeight: "150%",
  defaultRadius: "xs",
  headings: {
    fontFamily: "'Inter', sans-serif",
    fontWeight: 700,
    sizes: {
      h1: {
        fontSize: 42,
      },
      h2: {
        fontSize: 32,
      },
      h3: {
        fontSize: 24,
      },
      h4: {
        fontSize: 18,
      },
    },
  },
  breakpoints: {
    xs: 375,
    sm: 800,
    md: 1000,
    lg: 1200,
    xl: 1680,
  },
  spacing: {
    xs: 8,
    sm: 16,
    md: 24,
    lg: 32,
    xl: 40,
  },
  fontSizes: {
    xs: 10, //12
    sm: 12, //14
    md: 14, //16
    lg: 16, //18
    xl: 18, //20
  },
  radius: {
    xs: 2,
    sm: 6,
    md: 8,
    lg: 12,
    xl: 16,
  },
  shadows: {
    sm: "0px 7px 7px -5px rgba(0, 0, 0, 0.04), 0px 10px 15px -5px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.05);",
    md: "0px 0px 10px rgba(0, 0, 0, 0.1);",
  },
  other: {
    compassSolutionColor: dealsCountLine,
    chartsColors: {
      baseColors,
      dealsCountLine,
      sectorBarsById,
    },
    extraSpacing: {
      xxs: 4,
    },
    fontSizes: {
      xxs: 8, //10 xxs -> xs
      xxl: 20,
    },
    fontWeight: {
      regular: 400,
      semiBold: 600,
      bold: 700,
    },
    defaultTransition: { transition: "all .3s ease-in-out" },
    getLinkStyle: (theme: MantineTheme, flexed = false) => {
      const linkState = {
        textDecoration: "none",
        color: theme.colors.teal[5],
        "&:hover": {
          textDecoration: "none",
          color: theme.colors.teal[6],
        },
      };

      if (flexed) {
        return {
          display: "inline-flex",
          alignItems: "center",
          gridGap: theme.other.extraSpacing.xxs,
          ...linkState,
        };
      }

      return linkState;
    },
    getDefaultBorderColor: (theme: MantineTheme) => {
      return theme.colors.grey[3];
    },
    getDefaultBorder: (theme: MantineTheme, isInput = false) => {
      if (isInput) {
        return `1px solid ${theme.colors.teal[5]}`;
      }

      return `1px solid ${theme.other.getDefaultBorderColor(theme)}`;
    },
    getHTMLParagraphBorder: (theme: MantineTheme) => {
      return `0.1em solid ${theme.colors.grey[2]}`;
    },
    getActiveFilterIndicator: (theme: MantineTheme, active: boolean) => {
      if (!active) {
        return {};
      }

      return {
        paddingLeft: 11,
        position: "relative",
        "&:after": {
          content: "''",
          position: "absolute",
          left: 0,
          top: 7,
          bottom: 0,
          width: 3,
          backgroundColor: theme.colors.indigo[5],
          borderRadius: theme.radius.xs,
        },
      };
    },
    getForceInputHeight: (height: number) => ({
      "& .mantine-Input-input": {
        height: height,
        minHeight: height,
      },
    }),
    getButtonLeftIconSpace: (marginRight: number) => ({
      "& .mantine-Button-leftIcon": {
        marginRight,
      },
    }),
    getRowHover: (theme: MantineTheme) => ({
      "&:hover": {
        backgroundColor: theme.colors.teal[0],
      },
    }),
    getFilterChipEffect: (theme: MantineTheme) => ({
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.colors.indigo[1],
        boxShadow: theme.shadows.sm,
      },
      "&:active": {
        transform: "scale(0.99)",
      },
    }),
  },
  components: {
    AppShell: {
      styles: appShellStyles,
    },
    Header: {
      styles: headerStyles,
    },
    Button: {
      styles: buttonStyles,
    },
    ActionIcon: {
      styles: actionIconStyles,
    },
    Menu: {
      styles: menuStyles,
    },
    Modal: {
      styles: modalStyles,
    },
    Notification: {
      styles: notificationStyles,
    },
    Image: {
      styles: imageStyles,
    },
    PasswordInput: {
      styles: passwordInputStyles,
    },
    Autocomplete: {
      styles: selectInputStyles,
    },
    TextInput: {
      styles: inputStyles,
    },
    NumberInput: {
      styles: inputStyles,
    },
    Select: {
      styles: selectInputStyles,
    },
    MultiSelect: {
      styles: multiSelectInputStyles,
    },
    Checkbox: {
      styles: checkboxStyles,
    },
    Radio: {
      styles: radioStyles,
    },
    DatePicker: {
      styles: datePickerStyles,
    },
    Tabs: {
      styles: tabsStyles,
    },
    Table: {
      styles: tableStyles,
    },
    Pagination: {
      styles: paginationStyles,
    },
    ScrollArea: {
      defaultProps: {
        scrollbarSize: 6,
      },
    },
    ScrollAreaAutosize: {
      defaultProps: {
        scrollbarSize: 6,
      },
    },
  },
};
