import { useRef, FC } from "react";

import { Box, Text, createStyles } from "@mantine/core";

import { type CompassRow, type RowTypes } from "@Lib/types/sectorCompass";

import CompaniesRow from "./CompaniesRow";

const useStyles = createStyles(
  (
    theme,
    {
      num_columns,
      col_width,
      startCol,
      rowType,
    }: {
      num_columns: number;
      col_width: number;
      startCol: number;
      rowType: RowTypes;
    }
  ) => ({
    root: {
      display: "grid",
      gridGap: theme.spacing.xs,
      gridTemplateColumns: `repeat(${num_columns}, auto)`,
      gridTemplateRows: "min-content auto",
      padding: theme.spacing.xs,
      border: `2px solid ${rowType === "area" ? theme.colors.indigo[5] : theme.other.compassSolutionColor}`,
      backgroundColor: theme.white,
      borderRadius: theme.radius.xs,
      // Position component within parent
      gridColumn: `${startCol} / span ${col_width}`,
    },
    titleContainer: {
      gridColumn: "1 / -1",
    },
    title: {
      backgroundColor: rowType === "area" ? theme.colors.indigo[5] : theme.other.compassSolutionColor,
      color: rowType === "area" ? theme.white : theme.colors.grey[9],
      fontSize: theme.fontSizes.xs,
      fontWeight: rowType === "area" ? theme.other.fontWeight.bold : theme.other.fontWeight.regular,
      textAlign: "center",
      padding: "0.4em 0.6em",
      borderRadius: theme.radius.xs,
      display: "inline-block",
    },
  })
);

type MapRowProps = {
  data: CompassRow;
  startCol: number;
  rowType?: RowTypes;
};

const MapRow: FC<MapRowProps> = ({ data, startCol, rowType = "area" }) => {
  const { name, num_columns, col_width, rows, companies } = data;

  const { classes } = useStyles({ num_columns, col_width, startCol, rowType });
  const startColRef = useRef(1);

  const hasRows = rows.length > 0;
  const hasCompanies = companies.length > 0;

  return (
    <Box className={classes.root}>
      <Box className={classes.titleContainer}>
        <Text span className={classes.title}>
          {name}
        </Text>
      </Box>

      {hasRows &&
        rows.map(row => {
          startColRef.current = 1;

          return row.map(rowItem => {
            const startCol = startColRef.current;
            startColRef.current = startColRef.current + rowItem.col_width;

            return <MapRow key={rowItem.id} rowType="solution" data={rowItem} startCol={startCol} />;
          });
        })}
      {hasCompanies && <CompaniesRow data={companies} />}
    </Box>
  );
};

export default MapRow;
