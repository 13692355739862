import { useMemo, FC, ChangeEvent } from "react";

import { CheckboxMenuItem } from "@Components/Dropdown/common";
import Dropdown from "@Components/Dropdown/Dropdown";
import { type FilterUI } from "@Lib/types/base";
import { getFilterIDsUpdate } from "@Lib/utils/chart";

type CheckboxFilterBaseProps = {
  config: FilterUI[];
  label: string;
  filterIDs: string[];
  setFilterIDs: (filterIds: string[]) => void;
  hightlight?: boolean;
  disabled?: boolean;
};

const CheckboxFilterBase: FC<CheckboxFilterBaseProps> = ({
  config,
  label,
  filterIDs,
  setFilterIDs,
  hightlight,
  disabled,
}) => {
  const allFilterIDs = useMemo(() => {
    return config.map(item => item.value);
  }, [config]);

  return (
    <Dropdown btnText={label} variant="outline" position="bottom" hightlight={hightlight} disabled={disabled}>
      {config.map(({ label, value }) => {
        const isChecked = filterIDs.includes(value);
        const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
          const { checked } = e.target;
          const update = getFilterIDsUpdate(checked, value, filterIDs, allFilterIDs);

          setFilterIDs(update);
        };

        return <CheckboxMenuItem key={value} isChecked={isChecked} label={label} onChange={handleOnChange} />;
      })}
    </Dropdown>
  );
};

export default CheckboxFilterBase;
