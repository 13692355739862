import { useCallback, FC } from "react";

import ChartTypeControlBase from "@Components/charts/common/controls/ChartTypeControlBase";
import { useInvestmentChartsStore } from "@Lib/store/sectorCompass/investment";
import { type ChartDataTypes } from "@Lib/types/deals";

const ChartTypeControl: FC = () => {
  const chartDataType = useInvestmentChartsStore(state => state.chartDataType);
  const setChartDataType = useInvestmentChartsStore(state => state.setChartDataType);

  const handleOnChange = useCallback((value: ChartDataTypes) => {
    setChartDataType(value);
  }, []);

  return <ChartTypeControlBase chartDataType={chartDataType} handleOnChange={handleOnChange} />;
};

export default ChartTypeControl;
