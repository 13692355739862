import { QUERIES } from "@Lib/constants";
import { type FilterConfig } from "@Lib/types/filters";

export const CONFIG: FilterConfig = {
  companySectorIdsLocal: {
    type: "checkbox",
    labelKey: "filterChipsLabel.taxonomy",
    fieldKey: "companySectorIdsLocal",
    linkedField: "sector_id",
    queryKey: QUERIES.getFilterTaxonomy(),
  },
  dealTypesIdsLocal: {
    type: "checkbox",
    labelKey: "filterChipsLabel.dealTypes",
    fieldKey: "dealTypesIdsLocal",
    linkedField: "deal_type_id",
    queryKey: QUERIES.getFilterDealTypes(),
  },
  dealSizeRangeLocal: {
    type: "range",
    labelKey: "filterChipsLabel.dealSize",
    fieldKey: "dealSizeRangeLocal",
    dataFields: ["deal_funding_range_min", "deal_funding_range_max"],
    isCurrency: true,
  },
  dealDateRangeLocal: {
    type: "date-range",
    labelKey: "filterChipsLabel.dealDate",
    fieldKey: "dealDateRangeLocal",
    dateDataFields: ["deal_date_range_after", "deal_date_range_before"],
  },
  company_location_id: {
    type: "location",
    fieldKey: "company_location_id",
    labelKey: "filterChipsLabel.companyLocation",
  },
  companyIdLocal: {
    type: "autocomplete",
    labelKey: "filterChipsLabel.companyName",
    fieldKey: "companyIdLocal",
    linkedField: "company_id",
  },
  company_status_id: {
    type: "multi",
    labelKey: "filterChipsLabel.companyStatuses",
    fieldKey: "company_status_id",
    queryKey: QUERIES.getFilterCompanyStatuses(),
  },
  company_stage_id: {
    type: "multi",
    labelKey: "filterChipsLabel.companyStages",
    fieldKey: "company_stage_id",
    queryKey: QUERIES.getFilterCompanyStages(),
  },
  companyFundingRangeLocal: {
    type: "range",
    labelKey: "filterChipsLabel.companyFunding",
    fieldKey: "companyFundingRangeLocal",
    dataFields: ["company_total_funding_range_min", "company_total_funding_range_max"],
    isCurrency: true,
  },
  company_tech_type_id: {
    type: "multi",
    labelKey: "filterChipsLabel.companyTechTypes",
    fieldKey: "company_tech_type_id",
    queryKey: QUERIES.getFilterTechType(),
  },
  companyFoundedDateRangeLocal: {
    type: "date-range",
    labelKey: "filterChipsLabel.companyFoundedDate",
    fieldKey: "companyFoundedDateRangeLocal",
    dateDataFields: ["company_founded_date_range_after", "company_founded_date_range_before"],
  },
  companyLatestDealDateRangeLocal: {
    type: "date-range",
    labelKey: "filterChipsLabel.companyLatestDealDate",
    fieldKey: "companyLatestDealDateRangeLocal",
    dateDataFields: ["company_last_deal_date_range_after", "company_last_deal_date_range_before"],
  },
  company_size_id: {
    type: "multi",
    labelKey: "filterChipsLabel.companySize",
    fieldKey: "company_size_id",
    queryKey: QUERIES.getFilterCompanySizes(),
  },
  investor_location_id: {
    type: "location",
    labelKey: "filterChipsLabel.investorLocation",
    fieldKey: "investor_location_id",
  },
  investorIdLocal: {
    type: "autocomplete",
    labelKey: "filterChipsLabel.investorName",
    fieldKey: "investorIdLocal",
    linkedField: "investor_id",
  },
  investorTypesIdsLocal: {
    type: "checkbox",
    labelKey: "filterChipsLabel.investorType",
    fieldKey: "investorTypesIdsLocal",
    linkedField: "investor_type_id",
    queryKey: QUERIES.getFilterInvestorType(),
  },
  investor_fund_size_id: {
    type: "single",
    labelKey: "filterChipsLabel.investorFundSizes",
    fieldKey: "investor_fund_size_id",
    queryKey: QUERIES.getFilterFundSizes(),
  },
  investor_is_lead: {
    type: "single",
    labelKey: "filterChipsLabel.investorIsLead",
    fieldKey: "investor_is_lead",
    // TODO: Adjust accordingly - `queryKey` is not relevant for this case
    queryKey: ["dummy-key"],
  },
  investmentsRangeLocal: {
    type: "range",
    labelKey: "filterChipsLabel.investorInvestments",
    fieldKey: "investmentsRangeLocal",
    dataFields: ["investor_num_investments_range_min", "investor_num_investments_range_max"],
  },
  leadInvestmentsRangeLocal: {
    type: "range",
    labelKey: "filterChipsLabel.investorLeadInvestments",
    fieldKey: "leadInvestmentsRangeLocal",
    dataFields: ["investor_num_lead_investments_range_min", "investor_num_lead_investments_range_max"],
  },
  lastInvestmentDateRangeLocal: {
    type: "date-range",
    labelKey: "filterChipsLabel.investorLastInvestment",
    fieldKey: "lastInvestmentDateRangeLocal",
    dateDataFields: ["investor_last_investment_date_range_after", "investor_last_investment_date_range_before"],
  },
  project_location_id: {
    type: "location",
    labelKey: "filterChipsLabel.projectLocation",
    fieldKey: "project_location_id",
  },
  project_progress_id: {
    type: "multi",
    labelKey: "filterChipsLabel.projectStatus",
    fieldKey: "project_progress_id",
    queryKey: QUERIES.getFilterProjectStatus(),
  },
  project_scale_id: {
    type: "multi",
    labelKey: "filterChipsLabel.projectScale",
    fieldKey: "project_scale_id",
    queryKey: QUERIES.getFilterProjectScale(),
  },
  projectOperationDateRangeLocal: {
    type: "date-range",
    labelKey: "filterChipsLabel.projectOperational",
    fieldKey: "projectOperationDateRangeLocal",
    dateDataFields: ["project_operation_date_range_after", "project_operation_date_range_before"],
  },
};
