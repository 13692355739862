import { DATA_JOINER, DATE_FORMAT_FULL_YEAR } from "@Lib/constants";
import {
  type SortUI,
  type FilterUI,
  type EntityBE,
  type UnapprovedEntityBE,
  type SortDTO,
  type PaginationDataResponse,
} from "@Lib/types/base";
import { type MarketMapBE } from "@Lib/types/capitalStack";
import { type CompanyBE, type CompanyUI } from "@Lib/types/company";
import { type DealsBE, type DealsUI, type DealsChartResponse } from "@Lib/types/deals";
import {
  type EntityFilterResponse,
  type CheckboxFilterUI,
  type SectorFilterResponse,
  type CheckboxFilterResponse,
  type SectorFilterBE,
} from "@Lib/types/filters";
import { type AnnualChartDatum, type AnnualBaseDatum, type AnnualDatasets } from "@Lib/types/investmentDash";
import { type InvestorBE, type InvestorUI } from "@Lib/types/investors";
import {
  type FAQResponse,
  type GroupedFAQUI,
  type GroupedFAQs,
  type DefinitionTypeBE,
  type DefinitionTypeUI,
  type SectorDefinitionBE,
  type SectorDefinitionUI,
} from "@Lib/types/methodology";
import {
  type OrganizationBE,
  type OrganizationUI,
  type SubsidiaryBE,
  type SubsidiaryUI,
} from "@Lib/types/organization";
import {
  type ProjectTableBE,
  type ProjectBE,
  type ProjectUI,
  type NotableProjectDataUI,
  type ProjectTableUI,
  type NotableProjectsResponse,
  type NotableProjectUI,
} from "@Lib/types/projects";
import { type SavedSearchBE, type SavedSearchUI } from "@Lib/types/searches";
import { type SectorCompassCardUI, type SectorCompassListResponse } from "@Lib/types/sectorCompass";
import { type SightlineBE, type SightlineUI, type SightlineCardUI } from "@Lib/types/sightline";
import { type MakeStringValue } from "@Lib/types/utils";
import { generateLinkUrl } from "@Lib/utils/routes";

import { getFormattedDate } from "./date";
import { adjustSearchesData } from "./savedSearch";

/** general */
export const extractNextPageParam = (next: Nullable<string>) => {
  if (!next) {
    return undefined;
  }

  const nextPage = new URL(next).searchParams.get("page");
  if (nextPage === null) {
    return undefined;
  }

  return nextPage;
};

/** Data mappers */
export const parseEntityBEId = <T extends EntityBE>(entity: T) => {
  const result: MakeStringValue<T, "id"> = {
    ...entity,
    id: entity.id.toString(),
  };

  return result;
};

export const parseUnapprovedEntityBEId = <T extends EntityBE | UnapprovedEntityBE>(entity: T) => ({
  ...entity,
  id: entity.id ? entity.id.toString() : null,
});

export const mapSortUItoBE = (sortMap: Record<string, string>, sorting: Nullable<SortUI>): SortDTO => {
  if (sorting === null || sorting.column === null || sorting.order === null) {
    return null;
  }

  return {
    ordering: sorting.order === "ASC" ? sortMap[sorting.column] : `-${sortMap[sorting.column]}`,
  };
};

export const mapEntityBEToFilterUI = (data: EntityFilterResponse): FilterUI[] =>
  data.map(({ id, name }) => ({
    value: id.toString(),
    label: name,
  }));

export const mapSectorBEToCheckboxUI = (
  context: "company" | "sightline" | "company-details" | "orga",
  data: SectorFilterResponse,
  parentId?: string
): CheckboxFilterUI[] => {
  const contextFilterFn = (child: SectorFilterBE) => {
    switch (context) {
      case "company":
        return child.company_count > 0;
      case "sightline":
        return child.insight_count > 0;
      default:
        return true;
    }
  };

  return data.filter(contextFilterFn).map(({ id, name, emoji, is_primary, children }) => {
    const value = parentId ? `${parentId}${DATA_JOINER}${id}` : id.toString();
    const label = parentId ? name : buildEmojiLabel(emoji, name);

    const filteredChildren = children.filter(contextFilterFn);

    const childrenResult =
      filteredChildren.length > 0 ? mapSectorBEToCheckboxUI(context, filteredChildren, value) : undefined;

    const result: CheckboxFilterUI = {
      label,
      value,
      children: childrenResult,
    };

    if (is_primary !== undefined) {
      result.isPrimary = is_primary;
    }

    return result;
  });
};

export const mapBEToCheckboxUI = (data: CheckboxFilterResponse, parentId?: string): CheckboxFilterUI[] =>
  data.map(({ id, name, children }) => {
    const value = parentId ? `${parentId}${DATA_JOINER}${id}` : id.toString();

    const result: CheckboxFilterUI = {
      label: name,
      value,
      children: children && children.length > 0 ? mapBEToCheckboxUI(children, value) : undefined,
    };

    return result;
  });

/**
 * Handler to transform `null` or `undefined` values to an empty string
 * @param data nullable string value
 * @returns empty string or input value
 */
export const nullableToString = (data?: Nullable<string>): string => {
  if (!data) {
    return "";
  }

  return data;
};

/**
 * Handler to transform `null` or `undefined` values to an empty array
 * @param data nullable string array
 * @returns empty array or input value
 */
export const nullableToArray = (data?: Nullable<string[]>): string[] => {
  if (!data) {
    return [];
  }

  return data;
};

/**
 * Handler to transform `null` or `undefined` values to zero
 * @param data nullable number value
 * @returns zero or input value
 */
export const nullableToZero = (data?: Nullable<number>): number => {
  if (!data) {
    return 0;
  }

  return data;
};

export const nullableToObjectArray = <T>(data?: Nullable<T[]>): T[] => {
  if (!data) {
    return [];
  }

  return data;
};

/**
 * Handler that generates a label combination of emoji and label,
 * missing emoji is ignored, while missing label is marked with `-`
 * @param emoji emoji string data
 * @param label label of entity
 * @returns a string contatenating emoji and label
 */
export const buildEmojiLabel = (emoji?: Nullable<string>, label?: Nullable<string>): string => {
  if (!emoji && !label) {
    return "";
  }

  return [emoji, label || "-"].filter(Boolean).join(" ");
};

/** Data selectors */
export const getTotalPages = (perPage: number, dto: PaginationDataResponse) => Math.ceil(dto.count / perPage);

export const dealDataSelector = ({
  id,
  deal_date,
  deal_type_name,
  primary_sector,
  funding_usd,
  company,
  overview,
  investors,
}: DealsBE): DealsUI => ({
  id: id.toString(),
  date: nullableToString(deal_date),
  stage: nullableToString(deal_type_name),
  sectorName: buildEmojiLabel(primary_sector?.emoji, primary_sector?.name),
  funding: funding_usd,
  companyId: nullableToString(company?.id.toString()),
  companyName: nullableToString(company?.name),
  overview: nullableToString(overview),
  leadInvestors: investors.filter(investor => investor.is_lead).map(parseUnapprovedEntityBEId),
  nonLeadInvestors: investors.filter(investor => !investor.is_lead).map(parseUnapprovedEntityBEId),
});

const getSimilarSectorPath = (similar_sector_path: CompanyBE["similar_sector_path"]) => {
  return similar_sector_path
    ? similar_sector_path.map((sector, idx) => (idx !== 0 ? sector.name : buildEmojiLabel(sector.emoji, sector.name)))
    : [];
};

export const companyDataSelector = ({
  id,
  logo,
  name,
  overview,
  stage,
  funding,
  primary_sector,
  city_display_name,
  founded_date,
  company_size_range,
  tech_types,
  status,
  last_deal_date,
  description,
  website,
  root_sector,
  taxonomy,
  similar_sector_path,
  compasses,
}: CompanyBE): CompanyUI => ({
  id: id.toString(),
  logo: nullableToString(logo),
  name: nullableToString(name),
  overview: nullableToString(overview),
  latestStage: nullableToString(stage),
  funding: nullableToZero(funding),
  sector: buildEmojiLabel(primary_sector?.emoji, primary_sector?.name),
  sectorNew: primary_sector,
  location: nullableToString(city_display_name),
  yearFounded: nullableToString(getFormattedDate(founded_date, DATE_FORMAT_FULL_YEAR)),
  companySize: nullableToString(company_size_range),
  techType: tech_types.map(tech => tech.name).join(", "),
  status: nullableToString(status),
  latestDealDate: nullableToString(last_deal_date),
  description: nullableToString(description),
  website: nullableToString(website),
  verticalText: buildEmojiLabel(root_sector?.emoji, root_sector?.name),
  sectorPaths: taxonomy ? mapSectorBEToCheckboxUI("company-details", taxonomy) : [],
  similarSector: similar_sector_path
    ? buildEmojiLabel(
        similar_sector_path[similar_sector_path.length - 1].emoji,
        similar_sector_path[similar_sector_path.length - 1].name
      )
    : "",
  similarSectorPath: getSimilarSectorPath(similar_sector_path),
  compasses: sectorCompassDataSelector(nullableToObjectArray(compasses)),
});

export const investorDataSelector = ({
  id,
  logo,
  name,
  overview,
  investor_types,
  fund_size_range,
  is_lead,
  last_investment_date,
  city_display_name,
  sectors,
  description,
}: InvestorBE): InvestorUI => ({
  id: id.toString(),
  logo: nullableToString(logo),
  name: nullableToString(name),
  overview: overview ? overview : nullableToString(description),
  fundSize: nullableToString(fund_size_range),
  isLead: is_lead,
  lastInvestment: nullableToString(last_investment_date),
  type: nullableToArray(investor_types),
  verticalsNames: nullableToArray((sectors ?? []).map(sector => buildEmojiLabel(sector?.emoji, sector?.name))),
  location: nullableToString(city_display_name),
  description: nullableToString(description),
});

export const organizationDataSelector = ({
  id,
  name,
  description,
  logo,
  company_size_range,
  fund_size_range,
  stage,
  primary_sector,
  sectors,
  overview,
  website,
  last_deal_date,
  funding,
  status,
  city_display_name,
  tech_types,
  root_sector,
  is_lead,
  deepest_primary_sector,
  founded_date,
  taxonomy,
  last_investment_date,
  compasses,
  investor_types,
  parents,
  n_projects,
  n_subsidiaries,
}: OrganizationBE): OrganizationUI => ({
  id: id.toString(),
  name,
  description: nullableToString(description),
  logo: nullableToString(logo),
  companySizeRange: company_size_range, // Accepts `null`
  fundingSizeRange: last_investment_date ? nullableToString(fund_size_range) : null, // Accepts `null`
  stage, // Accepts `null`
  primarySector: primary_sector ? buildEmojiLabel(primary_sector?.emoji, primary_sector?.name) : null, // Accepts `null`
  sectors: sectors ? sectors.map(sector => buildEmojiLabel(sector?.emoji, sector?.name)) : null, // Accepts `null`
  overview: nullableToString(overview),
  website: nullableToString(website),
  lastDealDate: last_deal_date, // Accepts `null`
  funding, // Accepts `null`
  status, // Accepts `null`
  cityDisplayName: nullableToString(city_display_name),
  techTypes: tech_types.length ? tech_types.map(tech => tech.name).join(", ") : null, // Accepts `null`
  rootSector: buildEmojiLabel(root_sector?.emoji, root_sector?.name),
  isLead: is_lead, // Accepts `null`
  deepestPrimarySector: buildEmojiLabel(deepest_primary_sector?.emoji, deepest_primary_sector?.name),
  foundedDate: nullableToString(founded_date),
  taxonomy: taxonomy ? mapSectorBEToCheckboxUI("company-details", taxonomy) : [],
  lastInvestmentDate: last_investment_date, // Accepts `null`
  compasses: sectorCompassDataSelector(nullableToObjectArray(compasses)),
  investorTypes: investor_types?.length ? investor_types : null, // Accepts `null`
  parents: parents.length ? parents.map(parseEntityBEId) : null, // Accepts `null`
  nProjects: n_projects,
  nSubsidiaries: n_subsidiaries,
});

export const subsidiaryDataSelector = ({
  id,
  name,
  logo,
  overview,
  city_display_name,
  subsidiary_type,
}: SubsidiaryBE): SubsidiaryUI => ({
  id: id.toString(),
  name: nullableToString(name),
  logo: nullableToString(logo),
  overview: nullableToString(overview),
  location: nullableToString(city_display_name),
  subsidiaryType: nullableToString(subsidiary_type),
});

export const projectTableDataSelector = ({
  companies,
  operation_date,
  participant_types,
  notable_status_name,
  capacity,
  ...rest
}: ProjectTableBE): ProjectTableUI =>
  parseEntityBEId({
    companies: companies.map(parseEntityBEId),
    operationDate: operation_date,
    participantTypes: nullableToArray(participant_types),
    notableStatusName: notable_status_name,
    capacity: nullableToString(capacity),
    ...rest,
  });

export const projectDataSelector = ({
  companies,
  progresses,
  milestones,
  metrics,
  participants,
  taxonomy,
  latitude,
  longitude,
  location,
  timeline,
  site_images,
  revised_date,
  notable_status_name,
  notable_data,
  // The below field is replaced by `companies` field
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  company,
  ...rest
}: ProjectBE): ProjectUI => {
  const mapNotableData = (data: ProjectBE["notable_data"]): ProjectUI["notableData"] => {
    if (!data) {
      return null;
    }

    return Object.keys(data).reduce((accum, curr) => {
      const parsedKey = curr as keyof typeof data;
      if (curr !== "overview") {
        return {
          ...accum,
          [curr]: data[parsedKey],
        };
      }

      const overview = `<ul>${Object.keys(data.overview)
        .map(key => {
          const parsedKey = key as keyof (typeof data)["overview"];
          return `<li>${data.overview[parsedKey]}</li>`;
        })
        .join("")}</ul>`;

      return {
        ...accum,
        overview,
      };
    }, {} as NotableProjectDataUI);
  };

  return parseEntityBEId({
    companies: companies.map(parseEntityBEId),
    progresses: progresses.map(({ progress_date, is_projection, ...rest }) => ({
      progressDate: progress_date,
      isProjection: is_projection,
      ...parseEntityBEId(rest),
    })),
    milestones: milestones.map(({ is_attained, is_na, attained_date, ...rest }) => ({
      isAttained: is_attained,
      isNa: is_na,
      attainedDate: attained_date,
      ...parseEntityBEId(rest),
    })),
    metrics: metrics?.map(({ max_value, ...rest }) => ({
      maxValue: max_value,
      ...parseEntityBEId(rest),
    })),
    participants: participants?.map(({ participant_type, ...rest }) => ({
      participantType: participant_type,
      ...parseEntityBEId(rest),
    })),
    coordinates: {
      location,
      latitude,
      longitude,
      siteImages: site_images.map(({ image, object_fit, published_date }) => ({
        image,
        objectFit: object_fit,
        publishedDate: published_date,
      })),
    },
    location,
    sectorPaths: taxonomy ? mapSectorBEToCheckboxUI("company-details", taxonomy) : [],
    timeline: timeline.map(({ event_month, event_type, ...rest }) => ({
      eventMonth: event_month,
      eventType: event_type,
      ...rest,
    })),
    revisedDate: revised_date,
    notableStatusName: notable_status_name,
    notableData: mapNotableData(notable_data),
    ...rest,
  });
};

export const notableProjectsDataSelector = ({ results }: NotableProjectsResponse): NotableProjectUI[] => {
  const mapProject = ({
    id,
    name,
    companies,
    revised_date,
    notable_status_name,
    sector,
  }: NotableProjectsResponse["results"][0]): NotableProjectUI =>
    parseEntityBEId({
      id,
      name,
      companies: companies.map(parseEntityBEId),
      location,
      revisedDate: revised_date,
      notableStatusName: notable_status_name,
      sector,
    });

  return results.map(mapProject);
};

export const sectorCompassDataSelector = (data: SectorCompassListResponse): SectorCompassCardUI[] => {
  return data.map(({ card, has_projects, published_at, republished_at, ...compass }) => ({
    cardImgSrc: card,
    hasProjects: has_projects,
    publishedAt: published_at,
    republishedAt: republished_at,
    ...compass,
  }));
};

export const sightlineDataSelector = ({
  id,
  name,
  root_sector,
  is_notable,
  author,
  published_at,
  cover_image,
  contents,
  data_url,
  pdf_url,
  has_data,
  has_pdf,
  is_paid,
  overview,
  readiness,
}: SightlineBE): SightlineUI => ({
  id: id.toString(),
  title: name,
  rootSector: buildEmojiLabel(root_sector?.emoji, root_sector?.name),
  isNotable: is_notable,
  author: {
    id: author.id.toString(),
    firstName: author.first_name,
    lastName: author.last_name,
    fullName: author.first_name
      ? author.last_name
        ? `${author.first_name} ${author.last_name}`
        : author.first_name
      : author.last_name,
    title: author.title,
    avatar: nullableToString(author.avatar),
    isActiveResearchAnalyst: author.is_active_research_analyst,
  },
  publishedAt: published_at,
  coverImage: cover_image,
  contents: contents.sort((a, b) => a.position - b.position),
  dataUrl: data_url ?? undefined,
  pdfUrl: pdf_url ?? undefined,
  hasData: has_data,
  hasPdf: has_pdf,
  isPaid: is_paid,
  overview,
  readiness: {
    sectors: readiness.sectors.map(sector => ({
      sectorName: sector.sector_name,
      rootSectorName: sector.root_sector_name,
      rootSectorEmoji: sector.root_sector_emoji,
    })),
    impact: readiness.impact?.name,
    factors: readiness.factors.map(factor => ({
      id: factor.id.toString(),
      name: factor.name,
    })),
  },
});

export const sightlineCardDataSelector = ({
  id,
  name,
  root_sector,
  is_notable,
  cover_image,
  has_data,
  has_pdf,
  is_paid,
  published_at,
  overview,
}: SightlineBE): SightlineCardUI => ({
  id: id.toString(),
  title: name,
  rootSector: buildEmojiLabel(root_sector?.emoji, root_sector?.name),
  isNotable: is_notable,
  coverImage: cover_image,
  hasData: has_data,
  hasPdf: has_pdf,
  isPaid: is_paid,
  publishedAt: published_at,
  overview,
});

export const searchDataSelector = ({ id, name, data, is_subscribed }: SavedSearchBE): SavedSearchUI => ({
  id: id.toString(),
  name,
  filtersState: {
    filters: adjustSearchesData(data.filters),
  },
  isSubscribed: is_subscribed,
});

export const capitalMarketMapDataSelector = ({ rows, ...rest }: MarketMapBE, isPublic: boolean): MarketMapBE => ({
  rows: rows.map(rowArray =>
    rowArray.map(row => ({
      ...row,
      companies: row.companies.map(company => ({
        ...company,
        website: isPublic ? company.website : generateLinkUrl("organizationById", company.id.toString()),
      })),
    }))
  ),
  ...rest,
});

export const getAnnualInvestmentDataSelector =
  (getBarGroupColors?: (data: DealsChartResponse["results"]) => string[]) =>
  (response: DealsChartResponse): AnnualDatasets => {
    const getChartDatumBase = (data: DealsChartResponse["results"]) =>
      data.reduce((accum, item) => {
        return {
          ...accum,
          [item.period.label]: 0,
        };
      }, {});

    const objFunding: Record<string, AnnualChartDatum> = {},
      objDealsCount: Record<string, AnnualChartDatum> = {},
      chartDatumBase = getChartDatumBase(response.results);

    const colors = getBarGroupColors && getBarGroupColors(response.results);

    let groupIdx = 0;
    for (const item of response.results) {
      const { funding_usd: funding, deal_count: dealsCount } = item;

      const { name: groupName } = item.group;
      const { label: periodLabel } = item.period;

      if (!objFunding[groupName]) {
        const base: AnnualBaseDatum = {
          group: groupName,
          fill: colors ? colors[groupIdx] : "#97c067",
        };

        objFunding[groupName] = { ...base, ...chartDatumBase, [periodLabel]: funding } as AnnualChartDatum;
        objDealsCount[groupName] = { ...base, ...chartDatumBase, [periodLabel]: dealsCount } as AnnualChartDatum;
        groupIdx++;
        continue;
      }

      objFunding[groupName][periodLabel] += funding;
      objDealsCount[groupName][periodLabel] += dealsCount;
    }

    return {
      funding: Object.values(objFunding),
      dealsCount: Object.values(objDealsCount),
    };
  };

export const taxonomyDefinitionsDataSelector = ({
  id,
  name,
  emoji,
  description,
  children,
}: SectorDefinitionBE): SectorDefinitionUI => ({
  id,
  name,
  emoji,
  description,
  childCount: children?.length ?? 0,
  children: (children ?? []).map(({ id, name, emoji, description, child_count }) => ({
    id,
    name,
    emoji,
    description,
    childCount: child_count,
    children: [],
  })),
});

export const faqDataSelector = (faqData: FAQResponse): GroupedFAQUI[] => {
  const groupedFAQs = faqData.reduce((acc, faq) => {
    const { group, question, answer } = faq;
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push({ question, answer });
    return acc;
  }, {} as GroupedFAQs);

  return Object.keys(groupedFAQs).map(group => ({
    groupName: group,
    items: groupedFAQs[group],
  }));
};

export const definitionDataSelector = (item: DefinitionTypeBE): DefinitionTypeUI => {
  const { children, ...rest } = item;
  return {
    ...rest,
    children: children ? children.map(definitionDataSelector) : undefined,
  };
};
