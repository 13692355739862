import {
  type NotificationsPayload,
  type NotificationsResponse,
  type NotificationByIdBE,
} from "@Lib/types/downloadNotifications";

import { appApi } from "./api";

export const getAllNotifications = async ({ page, perPage }: NotificationsPayload) => {
  const { data } = await appApi.get<NotificationsResponse>("/notifications", {
    params: {
      page,
      page_size: perPage,
    },
  });

  return data;
};

export const getNotificationById = async (notificationId: string) => {
  const { data } = await appApi.get<NotificationByIdBE>(`/notifications/${notificationId}`);

  return data;
};

export const deleteNotificationById = async (notificationId: number) => {
  await appApi.delete<void>(`/notifications/${notificationId}`);
};

export const getReadyNotificationCount = async () => {
  const { data } = await appApi.get<{ count: number }>("/notifications/count");

  return data;
};

export const getDownloadFile = async (notificationId: string) => {
  const { data } = await appApi.get<Blob>(`/notifications/${notificationId}/download`, {
    responseType: "blob",
  });

  return data;
};
