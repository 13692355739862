import { useQuery } from "@tanstack/react-query";

import { QUERIES, SERVER_TIMESTAMP_DELTA_STORAGE_KEY } from "@Lib/constants/queries";
import { getServerTime } from "@Lib/services/user";
import { getCurrentTimestampUTC } from "@Lib/utils/signature";

export const useGetAndSetServerTime = () =>
  useQuery({
    queryKey: QUERIES.getServerTime(),
    queryFn: async () => {
      /**
       * The timestamp delta (in seconds) provided below will be utilized consistently in the authenticated networking process.
       * This ensures that the user's time settings do not disrupt the generation of the signature.
       */
      const localTimestamp = getCurrentTimestampUTC();
      const { timestamp } = await getServerTime();

      const delta = timestamp - localTimestamp;
      sessionStorage.setItem(SERVER_TIMESTAMP_DELTA_STORAGE_KEY, delta.toString());

      return timestamp;
    },
  });
