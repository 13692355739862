import { useEffect } from "react";

import { type MagnitudeInfo } from "@Lib/types/base";
import { getMagnitudeInfo } from "@Lib/utils/formatters";

export const useSetMagnitudeInfo = (input: number | number[], setter: (magnitudeInfo: MagnitudeInfo) => void): void => {
  const value = typeof input === "number" ? input : Math.max(...input, 0);

  useEffect(() => {
    const magnitudeInfo = getMagnitudeInfo(value);
    setter(magnitudeInfo);
  }, [value]);
};
