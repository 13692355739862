import { FC } from "react";

import { Text, createStyles, TextProps } from "@mantine/core";
import { Link } from "react-router-dom";

import { ReactComponent as RightArrow } from "@Assets/r-arrow.svg";
import ChevronButtonIcon from "@Components/icons/ChevronButtonIcon";

const useStyles = createStyles(theme => ({
  root: {
    fontWeight: theme.other.fontWeight.semiBold,
    ...theme.other.getLinkStyle(theme, true),
  },
}));

type GoToLinkProps = TextProps & {
  text: string;
  to: string;
  isGoBack?: boolean;
};

const GoToLink: FC<GoToLinkProps> = ({ text, to, className, isGoBack = false, ...textProps }) => {
  const { classes, cx } = useStyles();

  return (
    <Text className={cx(classes.root, className)} size="sm" {...textProps} to={to} component={Link}>
      {isGoBack && <ChevronButtonIcon />}
      {text}
      {!isGoBack && <RightArrow />}
    </Text>
  );
};

export default GoToLink;
