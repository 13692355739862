import { FC } from "react";

import { Rectangle, type LabelProps } from "recharts";

type LineLabelContentProps = LabelProps & {
  isLineActive: boolean;
  activeIndex?: number;
  index?: number;
};

const LineLabelContent: FC<LineLabelContentProps> = ({
  x,
  y,
  stroke,
  offset = 0,
  value,
  style,
  index,
  activeIndex,
  isLineActive,
}) => {
  if (value === undefined || x === undefined || y === undefined || index !== activeIndex || !isLineActive) {
    return null;
  }

  const xCoord = typeof x === "number" ? x : Number(x);
  const yCoord = (typeof y === "number" ? y : Number(y)) - offset;

  const padding = 6;
  const fontSize = style?.fontSize && typeof style.fontSize === "number" ? style.fontSize : 12; // Set your desired font size

  // Calculate the width of the text to set the background width
  const rectWidth = value.toString().length * fontSize * 0.6;

  // Calculate the width and height of the background with padding
  const bgWidth = rectWidth + 2 * padding;
  const bgHeight = fontSize + 5;

  return (
    <g>
      <filter id="drop-shadow">
        <feDropShadow dx="1" dy="1" stdDeviation="2" floodColor="rgba(0, 0, 0, 0.2)" />
      </filter>
      <Rectangle
        filter="url(#drop-shadow)"
        fill={stroke}
        fillOpacity={0.9}
        x={xCoord - bgWidth / 2}
        y={yCoord - bgHeight}
        width={bgWidth}
        height={bgHeight}
        radius={2}
      />
      <text x={xCoord} y={yCoord} dy={-4} textAnchor="middle" style={style}>
        {value}
      </text>
    </g>
  );
};

export default LineLabelContent;
