import { FC } from "react";

import { Button, createStyles } from "@mantine/core";
import { useTranslation } from "react-i18next";

import { type DatasetTypes } from "@Lib/types/deals";

const useStyles = createStyles(theme => ({
  datasetsControls: {
    padding: 3,
    gap: 3,
    borderRadius: theme.radius.xs,
    backgroundColor: theme.colors.grey[2],
    height: 30,
    "& button": {
      height: "100%",
      whidth: 60,
      border: "transparent",
      backgroundColor: "transparent",
      fontWeight: theme.other.fontWeight.semiBold,
      fontSize: theme.fontSizes.xs,
      color: theme.colors.grey[8],

      "&.active": {
        color: theme.colors.teal[5],
        backgroundColor: theme.white,
      },
    },
  },
}));

type DatasetControlBaseProps = {
  datasetType: DatasetTypes;
  setDatasetType: (datasetType: DatasetTypes) => void;
};

const DatasetControlBase: FC<DatasetControlBaseProps> = ({ datasetType, setDatasetType }) => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();

  const setQuarterYear = () => {
    setDatasetType("quarterYear");
  };
  const setHalfYear = () => {
    setDatasetType("halfYear");
  };
  const setYear = () => {
    setDatasetType("year");
  };

  return (
    <Button.Group className={classes.datasetsControls}>
      <Button variant="default" onClick={setQuarterYear} className={cx({ active: datasetType === "quarterYear" })}>
        {t("charts.dealsCount.datasetTypes.quarterYear")}
      </Button>
      <Button variant="default" onClick={setHalfYear} className={cx({ active: datasetType === "halfYear" })}>
        {t("charts.dealsCount.datasetTypes.halfYear")}
      </Button>
      <Button variant="default" onClick={setYear} className={cx({ active: datasetType === "year" })}>
        {t("charts.dealsCount.datasetTypes.year")}
      </Button>
    </Button.Group>
  );
};

export default DatasetControlBase;
