import { FC } from "react";

import { Text, Highlight } from "@mantine/core";

type CheckboxLabelProps = {
  label: string;
  searchable: boolean;
  searchTerm: string;
};

const CheckboxLabel: FC<CheckboxLabelProps> = ({ label, searchable, searchTerm }) => {
  if (searchable) {
    return (
      <Highlight span highlight={searchTerm} highlightColor="indigo.3" size={"sm"}>
        {label}
      </Highlight>
    );
  }

  return (
    <Text span size={"sm"}>
      {label}
    </Text>
  );
};

export default CheckboxLabel;
