import { MantineColor } from "@mantine/core";

/**
 * Calculates the contrast color (either black or white) based on the provided background color.
 * @param {string} bgColor - The background color in the format '#RRGGBB'.
 * @returns {string} The contrast color ('#000000' or '"#ffffff'). If the provided color format is invalid, returned color is '#000000'.
 */
export const getContrastColor = (bgColor: string): string => {
  const isValidColor = /^#[0-9a-fA-F]{6}$/.test(bgColor);
  if (!isValidColor) {
    // eslint-disable-next-line no-console
    console.info(`Invalid color format: ${bgColor}. Color must be in the format '#RRGGBB'.`);
    return "#000000";
  }

  const r = parseInt(bgColor.slice(1, 3), 16);
  const g = parseInt(bgColor.slice(3, 5), 16);
  const b = parseInt(bgColor.slice(5, 7), 16);

  // Calculate luminance for RGBColor
  const luminance = r * 0.299 + g * 0.587 + b * 0.114;
  return luminance > 128 ? "#000000" : "#ffffff";
};

/**
 * Lightens or darkens a given color by a specified percentage.
 * @param {string} color - The color to be lightened or darkened in the format '#RRGGBB'.
 * @param {number} percent - The percentage by which to lighten (positive value) or darken (negative value) the color.
 * @returns {string} The resulting color after lightening or darkening. If the provided color format is invalid, returned color is the passed input `color`
 */
export const lightenDarkenColor = (color: string, percent: number): string => {
  const isValidColor = /^#[0-9a-fA-F]{6}$/.test(color);
  if (!isValidColor) {
    // eslint-disable-next-line no-console
    console.info(`Invalid color format: ${color}. Color must be in the format '#RRGGBB'.`);
    return color;
  }

  // Ensure the percent is within the range [-100, 100]
  const adjustedPercent = Math.max(Math.min(percent, 100), -100);

  // Convert the hex color to RGB
  let r = parseInt(color.slice(1, 3), 16);
  let g = parseInt(color.slice(3, 5), 16);
  let b = parseInt(color.slice(5, 7), 16);

  // Calculate the adjustment value
  const adjustValue = Math.floor((adjustedPercent / 100) * 255);

  // Lighten or darken each RGB component
  r = Math.min(255, Math.max(0, r + adjustValue));
  g = Math.min(255, Math.max(0, g + adjustValue));
  b = Math.min(255, Math.max(0, b + adjustValue));

  // Convert the RGB values back to hex
  const resultColor = `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, "0")}`;

  return resultColor.toUpperCase();
};

/**
 * Generate an array of color shades between two given colors.
 * @param startColor The starting color in hexadecimal format.
 * @param endColor The ending color in hexadecimal format.
 * @param count The number of shades to generate between the start and end colors.
 * @returns An array of color shades between the start and end colors.
 */
export const generateColorShades = (startColor: string, endColor: string, count: number): string[] => {
  /**
   * Convert hex color to RGB object.
   * @param hex The hexadecimal color string.
   * @returns An object containing the red, green, and blue components of the color.
   */
  const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
    // Remove the leading #
    hex = hex.slice(1);
    // Convert shorthand hex color to full hex
    if (hex.length === 3) {
      hex = hex
        .split("")
        .map(char => char + char)
        .join("");
    }
    // Convert hex to RGB
    return {
      r: parseInt(hex.substring(0, 2), 16),
      g: parseInt(hex.substring(2, 4), 16),
      b: parseInt(hex.substring(4, 6), 16),
    };
  };

  /**
   * Convert RGB values to hexadecimal color string.
   * @param r The red component of the color.
   * @param g The green component of the color.
   * @param b The blue component of the color.
   * @returns The hexadecimal representation of the color.
   */
  const rgbToHex = (r: number, g: number, b: number): string => {
    const toHex = (c: number): string => c.toString(16).padStart(2, "0");

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`.toUpperCase();
  };

  // Get RGB values of the start and end colors
  const startRgb = hexToRgb(startColor);
  const endRgb = hexToRgb(endColor);

  // Calculate step size for each color component
  const stepR: number = (endRgb.r - startRgb.r) / (count - 1);
  const stepG: number = (endRgb.g - startRgb.g) / (count - 1);
  const stepB: number = (endRgb.b - startRgb.b) / (count - 1);

  // Generate color shades
  const shades: string[] = [];
  for (let i = 0; i < count; i++) {
    // Calculate RGB values for each shade
    const shadeR: number = Math.round(startRgb.r + i * stepR);
    const shadeG: number = Math.round(startRgb.g + i * stepG);
    const shadeB: number = Math.round(startRgb.b + i * stepB);
    // Convert RGB to hex and push to shades array
    shades.push(rgbToHex(shadeR, shadeG, shadeB));
  }

  return shades;
};

/**
 * Generate an array of color shades between a darker limit color, a base color, and a lighter limit color.
 * The base color will be in the middle of the resulting array.
 * @param options An object containing parameters for generating color shades.
 * @param options.baseColor The base color in hexadecimal format.
 * @param options.endDarkColor The darker limit color in hexadecimal format.
 * @param options.endLightColor The lighter limit color in hexadecimal format.
 * @param options.count The total number of shades to generate.
 * @returns An array of color shades between the dark and light limits.
 */
export const generateDarkToLightColorShades = ({
  baseColor,
  endDarkColor,
  endLightColor,
  count,
}: {
  baseColor: string;
  endDarkColor: string;
  endLightColor: string;
  count: number;
}): string[] => {
  const middle = Math.ceil(count / 2);
  const darkShades = generateColorShades(endDarkColor, baseColor, middle);
  const lightShades = generateColorShades(baseColor, endLightColor, count - middle + 1);

  const shades = [...darkShades.slice(0, -1), baseColor, ...lightShades.slice(1)];

  return shades;
};

/**
 * Changes the opacity of a given color by adjusting the alpha channel.
 *
 * @param hexColor - The original color in hexadecimal format.
 * @param percentage - The desired opacity percentage (0-100).
 *
 * @returns A new color with the adjusted opacity in hexadecimal format.
 *
 * @example
 * ```typescript
 * const originalColor = "#FF0000"; // Red color
 * const newColor = changeOpacity(originalColor, 50); // "#FF000080" (50% opacity red)
 * ```
 */
export const changeColorOpacity = (hexColor: MantineColor, percentage: number) => {
  const hex = hexColor.replace("#", "");

  const alpha = Math.round((percentage / 100) * 255)
    .toString(16)
    .padStart(2, "0");

  return `#${hex}${alpha}`;
};
