import { useMemo, useCallback, FC } from "react";

import { shallow } from "zustand/shallow";

import DateRangeControlBase from "@Components/charts/common/controls/DateRangeControlBase";
import { useUserTrackingContext } from "@Context/UserTracking";
import { USER_TRACKING_PROPERTIES } from "@Lib/constants";
import { useDeploymentFilterStore, useDeploymentChartControlsStore } from "@Lib/store/sectorCompass/deployment";
import { type SliderStep } from "@Lib/types/sectorCompass";
import { getFromToString } from "@Lib/utils/userTracking";

type DateRangeFilterProps = {
  projectsStartDate: number;
  projectsEndDate: number;
};

const DateRangeFilter: FC<DateRangeFilterProps> = ({ projectsStartDate, projectsEndDate }) => {
  const { trackFilters } = useUserTrackingContext();

  const { initSlider, slider, setXRangeValue, setSliderRange } = useDeploymentChartControlsStore(
    ({ initSlider, slider, setXRangeValue, setSliderRange }) => ({
      initSlider,
      slider,
      setXRangeValue,
      setSliderRange,
    }),
    shallow
  );
  const setFilters = useDeploymentFilterStore(state => state.setFilters);

  const { rangeLabels, rangeFilterValues } = useMemo(() => {
    const generateYearlyPeriods = (
      startYear: number,
      endYear: number
    ): { rangeLabels: string[]; rangeFilterValues: SliderStep[] } => {
      const rangeFilterValues: SliderStep[] = [];
      const rangeLabels: string[] = [];

      for (let year = startYear; year <= endYear; year++) {
        const label = year.toString();
        rangeLabels.push(label);
        rangeFilterValues.push({
          label,
          dateRangeStart: `${year}-01-01`,
          dateRangeEnd: `${year}-12-31`,
        });
      }

      return { rangeLabels, rangeFilterValues };
    };

    return generateYearlyPeriods(projectsStartDate, projectsEndDate);
  }, [projectsStartDate, projectsEndDate]);

  const handleXRangeValue = useCallback<typeof setXRangeValue>(
    groupRange => {
      setXRangeValue(groupRange);
      const after = rangeFilterValues[groupRange[0]].dateRangeStart;
      const before = rangeFilterValues[groupRange[1]].dateRangeEnd;

      setFilters(["project_operation_date_range_after", "project_operation_date_range_before"], [after, before]);

      trackFilters({
        filter: "date_range",
        value: getFromToString(before, after),
        mechanism: USER_TRACKING_PROPERTIES.filtersMechanism.investmentDashGlobalFilter,
      });
    },
    [rangeFilterValues]
  );

  return (
    <DateRangeControlBase
      rangeLabels={rangeLabels}
      initSlider={initSlider}
      slider={slider}
      setXRangeValue={handleXRangeValue}
      setSliderRange={setSliderRange}
    />
  );
};

export default DateRangeFilter;
