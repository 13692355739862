import { useState, FC } from "react";

import { type Marker } from "@googlemaps/markerclusterer";
import { Box, Text, Collapse, createStyles } from "@mantine/core";
import { InfoWindow } from "@vis.gl/react-google-maps";

import ChevronExpand from "@Components/ChevronExpand/ChevronExpand";
import { type ProjectUI } from "@Lib/types/projects";

import InfoContent from "./InfoContent";
import InfoContentHeader from "./InfoContentHeader";

const useStyles = createStyles(theme => ({
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontWeight: theme.other.fontWeight.bold,
    paddingBlock: 12,
    borderBottom: theme.other.getDefaultBorder(theme),
  },
  closed: {
    cursor: "pointer",
  },
  content: {
    borderBottom: theme.other.getDefaultBorder(theme),
  },
}));

type MultipleProjectstProps = {
  selectedMarker: Marker;
  projects: ProjectUI[];
};

const MultipleProjectsContent: FC<MultipleProjectstProps> = ({ selectedMarker, projects }) => {
  const { classes, cx } = useStyles();

  const [visibleProjectIdx, setVisibleProjectIdx] = useState(0);

  return (
    <InfoWindow anchor={selectedMarker}>
      {projects.map((project, idx) => {
        const visible = idx === visibleProjectIdx;
        const handleOpen = () => setVisibleProjectIdx(idx);

        return (
          <Box key={project.id}>
            {!visible && (
              <Box
                className={cx(classes.title, {
                  [classes.closed]: !visible,
                })}
                onClick={handleOpen}
              >
                <Text>{project.name}</Text>
                <ChevronExpand expanded={visible} type="indigo" />
              </Box>
            )}
            <Collapse in={visible} className={classes.content}>
              <InfoContentHeader
                logo={project.companies[0].logo}
                projectName={project.name}
                projectCountry={project.location}
                pt={"sm"}
              />
              <InfoContent key={project.id} project={project} />
            </Collapse>
          </Box>
        );
      })}
    </InfoWindow>
  );
};

export default MultipleProjectsContent;
