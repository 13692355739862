import { FC } from "react";

import { Text, createStyles, TextProps } from "@mantine/core";

const useStyles = createStyles(theme => ({
  title: {
    fontWeight: theme.other.fontWeight.bold,
    color: theme.colors.teal[5],
    fontSize: theme.fontSizes.lg,
    [theme.fn.smallerThan("lg")]: {
      fontSize: theme.fontSizes.md,
    },
  },
}));

type HTMLParagraphTitleProps = TextProps & {
  title: string;
};

const HTMLParagraphTitle: FC<HTMLParagraphTitleProps> = ({ title, ...textProps }) => {
  const { classes } = useStyles();

  return (
    <Text {...textProps} className={classes.title}>
      {title}
    </Text>
  );
};

export default HTMLParagraphTitle;
