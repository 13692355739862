import { useEffect, useState, useCallback, useMemo, FC } from "react";

import { MarkerClusterer, type Marker } from "@googlemaps/markerclusterer";
import { useMap } from "@vis.gl/react-google-maps";

import { type Point } from "@Lib/types/sectorCompass";

import customRenderer from "./CustomClusterer";
import LocationTooltip from "./LocationTooltip";
import PointMarker from "./PointMarker";

type MarkerProps = {
  points: Point[];
  legendColorMap: Record<string, string>;
  rootSectorId: number;
};

const CustomMarker: FC<MarkerProps> = ({ points, legendColorMap, rootSectorId }) => {
  const map = useMap();

  const [markers, setMarkers] = useState<Record<string, Marker>>({});
  const [selectedPoint, setSelectedPoint] = useState<Point | null>(null);

  const clusterer = useMemo(() => {
    if (!map) {
      return null;
    }

    return new MarkerClusterer({ map, renderer: customRenderer(rootSectorId) });
  }, [map]);

  useEffect(() => {
    if (clusterer) {
      clusterer.clearMarkers();
      clusterer.addMarkers(Object.values(markers));

      return () => {
        clusterer.clearMarkers();
      };
    }
  }, [clusterer, markers]);

  const setMarkerRef = useCallback((marker: Marker | null, id: string) => {
    setMarkers(prevMarkers => {
      if (marker) {
        return { ...prevMarkers, [id]: marker };
      }

      const filteredMarkers = Object.fromEntries(Object.entries(prevMarkers).filter(([key]) => key !== id));

      return filteredMarkers;
    });
  }, []);

  const handleInfoWindowClose = useCallback(() => {
    setSelectedPoint(null);
  }, []);

  const handleMarkerClick = useCallback((point: Point) => {
    setSelectedPoint(point);
  }, []);

  return (
    <>
      {points.map(point => (
        <PointMarker
          key={point.id}
          color={legendColorMap[point.legendId]}
          point={point}
          onClick={handleMarkerClick}
          setMarkerRef={setMarkerRef}
        />
      ))}
      {selectedPoint && (
        <LocationTooltip
          key={selectedPoint.id}
          selectedMarker={markers[selectedPoint.id]}
          selectedPoint={selectedPoint}
          handleInfoWindowClose={handleInfoWindowClose}
        />
      )}
    </>
  );
};

export default CustomMarker;
