import { useMemo, FC, ChangeEvent } from "react";

import { CheckboxMenuItem } from "@Components/Dropdown/common";
import Dropdown from "@Components/Dropdown/Dropdown";
import { DATA_JOINER } from "@Lib/constants";
import { type ChartConfig } from "@Lib/types/base";
import { getFilterIDsUpdate } from "@Lib/utils/chart";

type BarsFilterControlBaseProps = {
  config: ChartConfig;
  label: string;
  filterIDs: string[];
  setFilterIDs: (filterIds: string[], compositeIds: string[]) => void;
  compositeFilterIDs?: string[];
  partiallyCheckedTooltip?: string;
  mahTarget?: number;
  hightlight?: boolean;
  disabled?: boolean;
};

const BarsFilterControlBase: FC<BarsFilterControlBaseProps> = ({
  config,
  label,
  filterIDs,
  setFilterIDs,
  compositeFilterIDs,
  partiallyCheckedTooltip,
  mahTarget,
  hightlight,
  disabled,
}) => {
  const buttonTargetProps = useMemo(() => ({ mah: mahTarget, disabled }), [mahTarget, disabled]);

  const allFilterIDs = useMemo(
    () => config.map(item => (item.display === "bar" ? item.groupId : "")).filter(result => result.length),
    [config]
  );

  const prevSelectedGroupsMap = useMemo(
    () =>
      compositeFilterIDs?.reduce((map, compositeId) => {
        const groupId = compositeId.split(DATA_JOINER)[0];

        const prevIds = map.get(groupId);
        if (!prevIds) {
          map.set(groupId, [compositeId]);
          return map;
        }

        map.set(groupId, [...prevIds, compositeId]);
        return map;
      }, new Map<string, string[]>()),
    [compositeFilterIDs]
  );

  return (
    <Dropdown
      btnText={label}
      variant="outline"
      position="bottom-end"
      buttonTargetProps={buttonTargetProps}
      hightlight={hightlight}
      disabled={disabled}
    >
      {config.map(datum => {
        if (datum.display === "line") {
          return null;
        }

        const { dataKey, groupId, sectorTaxonomyIds } = datum;
        const isChecked = filterIDs.includes(groupId);
        let isPartiallyChecked = false;

        if (isChecked && compositeFilterIDs) {
          const datumCompositeFilterIDs = compositeFilterIDs.filter(id => id.split(DATA_JOINER)[0] === groupId);
          isPartiallyChecked = datumCompositeFilterIDs.length !== sectorTaxonomyIds.length;
        }

        const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
          const { checked } = e.target;
          const update = getFilterIDsUpdate(checked, groupId, filterIDs, allFilterIDs);

          const filterIdsSet = new Set(update);

          const compositeIdsUpdate = config.reduce<string[]>((accum, datum) => {
            if (datum.display === "bar" && filterIdsSet.has(datum.groupId)) {
              const { groupId, sectorTaxonomyIds } = datum;

              const compositeId = prevSelectedGroupsMap?.get(groupId);
              if (compositeId) {
                accum.push(...compositeId);
              } else {
                accum.push(...sectorTaxonomyIds.map(id => `${groupId}${DATA_JOINER}${id}`));
              }
            }

            return accum;
          }, []);

          setFilterIDs(update, compositeIdsUpdate);
        };

        return (
          <CheckboxMenuItem
            key={dataKey}
            isChecked={isChecked}
            label={dataKey}
            onChange={handleOnChange}
            isPartiallyChecked={isPartiallyChecked}
            partiallyCheckedTooltip={partiallyCheckedTooltip}
          />
        );
      })}
    </Dropdown>
  );
};

export default BarsFilterControlBase;
