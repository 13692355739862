import { useState, useCallback } from "react";

import { useLocation } from "react-router-dom";

import { useUserTrackingContext } from "@Context/UserTracking";
import { DownloadFileTypesEnum } from "@Lib/constants/api";
import { type DownloadHTMLConfig } from "@Lib/types/base";
import { downloadHtml } from "@Lib/utils/download";
import { DownloadNotifiers } from "@Lib/utils/notifications";

export const useDownloadHTMLChunk = (config: DownloadHTMLConfig) => {
  const { elementId, fileName } = config;

  const [loading, setLoading] = useState(false);
  const notifiers = new DownloadNotifiers("download-html");
  const { pathname } = useLocation();
  const { trackDownload, trackDownloadError } = useUserTrackingContext();

  const handleDownload = useCallback(async () => {
    setLoading(true);

    try {
      notifiers.downloadStart();
      await downloadHtml(config);

      notifiers.downloadSuccess();

      trackDownload({
        fileName,
        fileType: DownloadFileTypesEnum.jpegImage,
        pathname,
      });
    } catch {
      notifiers.downloadError();
      trackDownloadError({ fileName, pathname });
    } finally {
      setLoading(false);
    }
  }, [elementId, fileName]);

  return {
    handleDownload,
    isLoading: loading,
  };
};
