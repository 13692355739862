import { Cluster } from "@googlemaps/markerclusterer";

import { sectorBarsById } from "@Context/Theme/chartsColors";

const createClusterContent = (count: number, color: string) => {
  const div = document.createElement("div");

  const baseSize = 40;
  const maxSize = 80;
  const scalingMultiplier = 2;
  const size = Math.min(baseSize + count * scalingMultiplier, maxSize);

  div.style.backgroundColor = color;
  div.style.borderRadius = "50%";
  div.style.width = `${size}px`;
  div.style.height = `${size}px`;
  div.style.display = "flex";
  div.style.border = "4px solid rgba(255, 255, 255, 0.5)";
  div.style.alignItems = "center";
  div.style.justifyContent = "center";
  div.style.color = "white";
  div.style.fontWeight = "bold";
  div.innerText = String(count);

  return div;
};

const customRenderer = (rootSectorId: number) => ({
  render: (cluster: Cluster): google.maps.marker.AdvancedMarkerElement => {
    const { markers, position } = cluster;
    const count = markers?.length || 0;

    const markerOptions: google.maps.marker.AdvancedMarkerElementOptions = {
      position,
      content: createClusterContent(count, sectorBarsById[rootSectorId]),
    };

    return new google.maps.marker.AdvancedMarkerElement(markerOptions);
  },
});

export default customRenderer;
