import { FC } from "react";

import { Text, Center } from "@mantine/core";

type ErrorBoxProps = {
  text: string;
};
const ErrorBox: FC<ErrorBoxProps> = ({ text }) => {
  return (
    <Center h={"100%"} style={{ flex: 1 }}>
      <Text color="red">{text}</Text>
    </Center>
  );
};

export default ErrorBox;
