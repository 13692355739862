import { type QueryKey } from "@tanstack/react-query";

const getFnPrefixer =
  (path: string) =>
  (...args: string[]) =>
  (...args2: QueryKey): QueryKey => {
    return [path, ...args, ...args2];
  };

const fnPrefixerUser = getFnPrefixer("user");
const fnPrefixerDelas = getFnPrefixer("deals");
const fnPrefixerCompany = getFnPrefixer("company");
const fnPrefixerInvestor = getFnPrefixer("investor");
const fnPrefixerOrganization = getFnPrefixer("organization");
const fnPrefixerProject = getFnPrefixer("project");
const fnPrefixerFilter = getFnPrefixer("filters");
const fnPrefixerFilterGeographyLoaction = getFnPrefixer("geography-location");
const fnPrefixerSearches = getFnPrefixer("searches");
const fnPrefixerSectorCompasses = getFnPrefixer("sector-compasses");
const fnPrefixerSightlines = getFnPrefixer("sightlines");
const fnPrefixerGlobalSearch = getFnPrefixer("global-search");
const fnPrefixerInvestmentDash = getFnPrefixer("investment-dash");
const fnPrefixerCharts = getFnPrefixer("charts");
const fnPrefixerAutocomplete = getFnPrefixer("autocomplete");
const fnPrefixerCapitalStack = getFnPrefixer("capital-stack");
const fnPrefixerMethodologyDefinitions = getFnPrefixer("definitions");
const fnPrefixerFAQs = getFnPrefixer("faqs");
const fnPrefixerDownloadNotifications = getFnPrefixer("download-notifications");

/**
 * Currently, neither `mutationCache` nor `useIsMutating` are being utilized.
 * In case you need to access mutation state add the relevant keys below:
 *
 * export const MUTATIONS = {}
 */

export const QUERIES = {
  getSignupInfo: fnPrefixerUser("signup-info"),
  getAccountInfo: fnPrefixerUser("account-info"),
  getSearchedCity: fnPrefixerUser("search-city"),
  getUserData: fnPrefixerUser("fetch-me"),
  getServerTime: fnPrefixerUser("server-time"),
  /** Keys for deals table */
  getAllDeals: fnPrefixerDelas(),
  getLatestDeals: fnPrefixerDelas("latest"),
  /** Keys for companies table */
  getAllCompanies: fnPrefixerCompany(),
  /** Keys for investors table */
  getAllInvestors: fnPrefixerInvestor(),
  /** Keys for organization page */
  getOrganization: fnPrefixerOrganization(),
  getOrganizationSectorList: fnPrefixerCompany("sector-list"),
  getOrganizationSnapshot: fnPrefixerOrganization("snapshot"),
  getOrganizationSubsidiaries: fnPrefixerOrganization("subsidiaries"),
  getOrganizationInvestors: fnPrefixerOrganization("investors"),
  getOrganizationDeals: fnPrefixerOrganization("deals"),
  getOrganizationProjects: fnPrefixerOrganization("projects"),
  getOrganizationPortfolio: fnPrefixerOrganization("portfolio"),
  getOrganizationInvestments: fnPrefixerInvestor("investments"),
  getOrganizationSimilar: fnPrefixerOrganization("similar-companies"),
  /** Keys for projects page and table */
  getAllProjects: fnPrefixerProject("all"),
  getProject: fnPrefixerProject(),
  /** Keys for filter fields data */
  getFiltersPrefix: fnPrefixerFilter(),
  getFilterTaxonomy: fnPrefixerFilter("taxonomy"),
  getFilterDealTypes: fnPrefixerFilter("deals-types"),
  getFilterCompanyName: fnPrefixerAutocomplete("company-name"),
  getFilterCountry: fnPrefixerFilter("country"),
  getFilterCompanyStatuses: fnPrefixerFilter("company-statuses"),
  getFilterCompanyStages: fnPrefixerFilter("company-stages"),
  getFilterTechType: fnPrefixerFilter("tech-type"),
  getFilterCompanySizes: fnPrefixerFilter("company-sizes"),
  getFilterInvestorName: fnPrefixerAutocomplete("investor-name"),
  getFilterInvestorType: fnPrefixerFilter("investor-types"),
  getFilterFundSizes: fnPrefixerFilter("fund-sizes"),
  getFilterSightlineTopics: fnPrefixerFilter("sightline-topics"),
  getFilterGeography: fnPrefixerFilter("geography"),
  getFilterProjectStatus: fnPrefixerFilter("project-status"),
  getFilterProjectScale: fnPrefixerFilter("project-scale"),
  // Must be separated from common key in fnPrefixerFilter due to loading state in FilterChips
  getFilterGeographyLoaction: fnPrefixerFilterGeographyLoaction(),
  /** Keys for saved searches data */
  getAllSearches: fnPrefixerSearches("all"),
  getSearchById: fnPrefixerSearches("search-id"),
  /**  Keys for Market/Sector */
  getAllCompasses: fnPrefixerSectorCompasses("all"),
  getCompassById: fnPrefixerSectorCompasses("compassId"),
  getCompassMap: fnPrefixerSectorCompasses("compass-data", "market-map"),
  getDeploymentMapCoordinates: fnPrefixerSectorCompasses("deployment-map"),
  getCompassProjectsChart: fnPrefixerSectorCompasses("projects-chart"),
  getCompassOperationalChart: fnPrefixerSectorCompasses("projects-chart", "operational-by-date"),
  getCompassRaceToScaleCompanies: fnPrefixerSectorCompasses("race-to-scale-companies"),
  getCompassRaceToScaleProjects: fnPrefixerSectorCompasses("race-to-scale-projects"),
  getCompassTaxonomyFilter: fnPrefixerSectorCompasses("compass-data", "taxonomy-filter"),
  getCompassDataKey: fnPrefixerSectorCompasses("compass-data"),
  getNotableProjects: fnPrefixerSectorCompasses("notable"),
  /** Keys for Sightlines */
  getAllSightlines: fnPrefixerSightlines(),
  getSightlinesBySectorId: fnPrefixerSightlines("sectorId"),
  getSightlineById: fnPrefixerSightlines("sightlineId"),
  getSightlineReadinessFactors: fnPrefixerSightlines("factors"),
  /** Keys for Investment Dashboard */
  getInvestmentDashMarimekko: fnPrefixerInvestmentDash("marimekko-chart"),
  getInvestmentDashMap: fnPrefixerInvestmentDash("market-map"),
  getInvestmentDashAnnual: fnPrefixerInvestmentDash("annual-investment"),
  getInvestmentDashTopClimateDeals: fnPrefixerInvestmentDash("top-climate-deals"),
  getInvestorsLeaderboard: fnPrefixerInvestmentDash("leaderboard"),
  getInvestmentDashExits: fnPrefixerInvestmentDash("exits-chart"),
  /**  Keys for Global Search */
  getGSCompanies: fnPrefixerGlobalSearch("companies"),
  getGSInvestors: fnPrefixerGlobalSearch("investors"),
  getGSSectorCompasses: fnPrefixerGlobalSearch("sector-compasses"),
  getGSProjects: fnPrefixerGlobalSearch("projects"),
  getGSSectors: fnPrefixerGlobalSearch("taxonomy-sectors"),
  getGSSightlines: fnPrefixerGlobalSearch("sightlines"),
  /** Keys for Charts */
  getDealsStageChartData: fnPrefixerCharts("deals-stage"),
  getDealsSectorChartData: fnPrefixerCharts("deals-sector"),
  /** Keys for Capital Stack */
  getCapitalStackInvestors: fnPrefixerCapitalStack("investors"),
  getCapitalStackMap: fnPrefixerCapitalStack("market-map"),
  getCapitalInvestorTypesMap: fnPrefixerCapitalStack("filters", "investor-types"),
  getCapitalStackRegions: fnPrefixerCapitalStack("filters", "regions"),
  //** Keys for Methodology  */
  getTaxonomyDefinitions: fnPrefixerMethodologyDefinitions("taxonomy"),
  getDealTypeDefinitions: fnPrefixerMethodologyDefinitions("deal-type"),
  getInvestorTypeDefinitions: fnPrefixerMethodologyDefinitions("investor-type"),
  getProductTypeDefinitions: fnPrefixerMethodologyDefinitions("product-type"),

  getFAQs: fnPrefixerFAQs(),
  /** Keys for Notifications */
  getAllNotifications: fnPrefixerDownloadNotifications(),
  getNotification: fnPrefixerDownloadNotifications("notificationId"),
};

/**
 * Session storage key that holds the delta (in seconds) between server time and the local machine time.
 * The delta is used as a correction factor for the timestamp used in signature generation.
 */
export const SERVER_TIMESTAMP_DELTA_STORAGE_KEY = "server-timestamp-delta";
