import { FC } from "react";

import { Box, Text, createStyles } from "@mantine/core";
import { type TooltipProps } from "recharts";

import { CHARTS_CONFIG } from "@Components/charts/config";
import { type MagnitudeInfo } from "@Lib/types/base";
import { type ChartDataTypes, type TooltipBarData } from "@Lib/types/deals";

const useStyles = createStyles((theme, borderColor: string | undefined) => ({
  root: {
    border: theme.other.getDefaultBorder(theme),
    borderRadius: theme.radius.xs,
    background: theme.white,
    padding: 3,
    fontSize: theme.fontSizes.xs,
  },
  row: {
    paddingLeft: 4,
    display: "flex",
    gap: 6,
    borderLeft: theme.other.getDefaultBorder(theme),
    borderWidth: 2,
    ...(borderColor ? { borderColor } : {}),
  },
}));

const { formatters } = CHARTS_CONFIG;

type TooltipBarContentProps = TooltipProps<number, string> & {
  chartDataType: ChartDataTypes;
  activeBar: TooltipBarData;
  magnitudeInfo: MagnitudeInfo;
};

const TooltipBarContent: FC<TooltipBarContentProps> = ({ activeBar, chartDataType, magnitudeInfo }) => {
  const { classes } = useStyles(activeBar?.color);

  if (!activeBar) {
    return null;
  }

  const formatedValue =
    chartDataType === "percentage"
      ? formatters.tooltipPercentage(activeBar.value, activeBar.dataKey)
      : formatters.getFundingAxis(magnitudeInfo)(activeBar.value);

  return (
    <Box className={classes.root}>
      <Box className={classes.row}>
        <Text>{activeBar.name}</Text>
        <Text weight={600}>{formatedValue}</Text>
      </Box>
    </Box>
  );
};

export default TooltipBarContent;
