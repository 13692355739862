import { useQuery, useQueries } from "@tanstack/react-query";

import { QUERIES, DealsGroupByEnum } from "@Lib/constants";
import { getDealsChartsData } from "@Lib/services/deals";
import { type DealsChartResponse, type DealsChartServiceInput } from "@Lib/types/deals";
import { getDealsStageChartConfig, chartDataSelector } from "@Lib/utils/chart";

type ChartConfig = {
  config: ReturnType<typeof getDealsStageChartConfig>;
};

const configSelector = (response: DealsChartResponse): ChartConfig => {
  return {
    config: getDealsStageChartConfig(response.results),
  };
};

export const useGetStageChartData = ({
  datasetType,
  storeFilters,
  primarySectorId,
}: Omit<DealsChartServiceInput, "chartVariant">) =>
  useQueries({
    queries: [
      {
        // We don't need config to react on storeFilters change, since we don't drill down inside stages
        queryKey: QUERIES.getDealsStageChartData("config", primarySectorId),
        queryFn: () =>
          getDealsChartsData({
            chartVariant: DealsGroupByEnum.stage,
            datasetType: "config",
            primarySectorId,
          }),
        select: configSelector,
      },
      {
        queryKey: QUERIES.getDealsStageChartData(datasetType, storeFilters, primarySectorId),
        queryFn: () =>
          getDealsChartsData({
            chartVariant: DealsGroupByEnum.stage,
            datasetType,
            storeFilters,
            primarySectorId,
          }),
        select: chartDataSelector,
      },
    ],
  });

export const useGetStageConfig = () =>
  useQuery({
    queryKey: QUERIES.getDealsStageChartData("config"),
    queryFn: () =>
      getDealsChartsData({
        chartVariant: DealsGroupByEnum.stage,
        datasetType: "config",
      }),
    select: configSelector,
  });
