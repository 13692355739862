import { FC, PropsWithChildren } from "react";

import { MantineProvider } from "@mantine/core";

import { themeConfig } from "./themeConfig";

const ThemeProvider: FC<PropsWithChildren> = ({ children }) => (
  <MantineProvider withGlobalStyles withNormalizeCSS theme={themeConfig}>
    {children}
  </MantineProvider>
);

export default ThemeProvider;
