import { useMemo } from "react";

import { useQueries } from "@tanstack/react-query";

import { QUERIES } from "@Lib/constants";
import { getProjectById } from "@Lib/services/projects";
import { projectDataSelector } from "@Lib/utils/queries";

export const useGetDeploymentMapProjects = (projectIds: string[] = []) => {
  const queries = useQueries({
    queries: projectIds.map(projectId => ({
      queryKey: QUERIES.getProject(projectId),
      queryFn: () => getProjectById(projectId),
      select: projectDataSelector,
      staleTime: Infinity,
    })),
  });

  const isLoading = queries.some(query => query.isLoading);

  const projects = useMemo(() => {
    if (isLoading) {
      return [];
    }

    return queries.map(query => query.data).filter(Boolean) as ReturnType<typeof projectDataSelector>[];
  }, [isLoading]);

  return {
    isLoading,
    projects,
  };
};
