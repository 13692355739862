import {
  showNotification,
  hideNotification,
  updateNotification,
  cleanNotifications,
  cleanNotificationsQueue,
  NotificationProps,
} from "@mantine/notifications";
import { t } from "i18next";

export { updateNotification, hideNotification, cleanNotifications, cleanNotificationsQueue };

export enum NotificationColors {
  success = "teal",
  error = "red",
  info = "indigo",
}

export const showSuccessNotification = ({
  title = t("notifications.success.title"),
  color = NotificationColors.success,
  ...rest
}: NotificationProps) =>
  showNotification({
    title,
    color,
    ...rest,
  });

export const showErrorNotification = ({
  title = t("notifications.error.title"),
  color = NotificationColors.error,
  ...rest
}: NotificationProps) =>
  showNotification({
    title,
    color,
    ...rest,
  });

export const showInfoNotification = ({ color = NotificationColors.info, ...rest }: NotificationProps) =>
  showNotification({
    color,
    ...rest,
  });

/**
 * Class representing a set of download notifiers.
 * @class
 */
export class DownloadNotifiers {
  /**
   * Creates an instance of DownloadNotifiers.
   * @constructor
   * @param {string} notificationId - The unique identifier for the notification.
   */
  constructor(public notificationId: string) {
    this.notificationId = notificationId;
  }

  /**
   * Displays a loading notification.
   * @param {string} [message] - The optional message to display in the loading notification.
   */
  downloadStart = (message?: string) => {
    hideNotification(this.notificationId);
    showInfoNotification({
      id: this.notificationId,
      message: message ?? t("notifications.download.loading.message"),
      autoClose: false,
      disallowClose: true,
      loading: true,
    });
  };

  /**
   * Displays a success notification for the completed download.
   * @param {string} [message] - The optional message to display in the success notification.
   */
  downloadSuccess(message?: string) {
    updateNotification({
      id: this.notificationId,
      color: NotificationColors.success,
      title: t("notifications.success.title"),
      message: message ?? t("notifications.download.success.message"),
    });
  }

  /**
   * Displays an error notification for a failed download.
   * @param {string} [message] - The optional message to display in the error notification.
   */
  downloadError(message?: string) {
    updateNotification({
      id: this.notificationId,
      color: NotificationColors.error,
      title: t("notifications.error.title"),
      message: message ?? t("notifications.download.error.message"),
    });
  }
}
