import { DATA_JOINER } from "@Lib/constants";
import { type NestedObject } from "@Lib/types/base";
import { type AcceptedValues } from "@Lib/types/filters";
import { buildNestedObject, deleteKeyFromNestedObject, getDeepestKeys } from "@Lib/utils/taxonomyHandlers";

import createStore from "../createStore";

type Constrain = Record<string, AcceptedValues>;

interface FiltersStoreState<T extends Constrain> {
  filters: T;
  setFilter: <K extends keyof T>(key: K, value: AcceptedValues) => void;
  setFilters: <K extends keyof T>(keys: K[], values: AcceptedValues[]) => void;
  resetFilters: (filterFields: string[]) => void;
  setTaxonomyFilter: <K extends keyof T>(payload: {
    action: "add" | "replace" | "remove";
    filterField: K;
    localField: K;
    value: string;
  }) => void;
  clearFilters: () => void;
  setFiltersState: (filtersState: T) => void;
}

export const getFiltersStore = <T extends Constrain>(storeName: string) => {
  return createStore<FiltersStoreState<T>>(
    set => ({
      /** Filters data */
      filters: {} as T,
      setFilter: (key, value) =>
        set(
          state => ({
            filters: {
              ...state.filters,
              [key]: value,
            },
          }),
          false,
          {
            type: `${storeName}: setFilter`,
            payload: [key, value],
          }
        ),
      setFilters: (keys, values) =>
        set(
          state => {
            const filters = { ...state.filters };

            keys.forEach((key, idx) => {
              filters[key] = values[idx] as never;
            });

            return {
              filters,
            };
          },
          false,
          {
            type: `${storeName}: setFilters`,
            payload: [keys, values],
          }
        ),
      resetFilters: keys =>
        set(
          state => {
            const filters = { ...state.filters };

            for (const key of keys) {
              delete filters[key];
            }

            return {
              filters,
            };
          },
          false,
          {
            type: `${storeName}: resetFilters`,
            payload: [keys],
          }
        ),
      setTaxonomyFilter: payload =>
        set(
          state => {
            const { action, filterField, localField, value } = payload;
            const idsArr = value.split(DATA_JOINER);
            let taxonomyTree = (state.filters[localField] as NestedObject) ?? {};

            switch (action) {
              case "add":
              case "replace":
                const baseTree = action === "add" ? taxonomyTree : {};
                taxonomyTree = buildNestedObject(idsArr, baseTree);

                return {
                  filters: {
                    ...state.filters,
                    [filterField]: getDeepestKeys(taxonomyTree),
                    [localField]: taxonomyTree,
                  },
                };

              case "remove":
                deleteKeyFromNestedObject(idsArr, taxonomyTree);
                const accumulatedKeys = getDeepestKeys(taxonomyTree);

                if (accumulatedKeys.length === 0) {
                  delete state.filters[filterField];
                  delete state.filters[localField];
                  return {
                    filters: state.filters,
                  };
                }

                return {
                  filters: {
                    ...state.filters,
                    [filterField]: accumulatedKeys,
                    [localField]: taxonomyTree,
                  },
                };

              default:
                return state;
            }
          },
          false,
          {
            type: `${storeName}: setTaxonomyFilter`,
            payload,
          }
        ),
      /**
       * Resets the store state
       */
      clearFilters: () =>
        set(
          {
            filters: {} as T,
          },
          false,
          `${storeName}: clearFilters`
        ),
      setFiltersState: filters =>
        set(
          {
            filters: Object.assign({}, filters),
          },
          false,
          { type: `${storeName}: setFiltersState`, payload: filters }
        ),
    }),
    storeName
  );
};

export const createFilterActiveSelector =
  <T extends Constrain>(fieldsSet: Set<string>) =>
  (state: FiltersStoreState<T>) => {
    let hasActiveFilter = false;

    for (const key in state.filters) {
      if (fieldsSet.has(key)) {
        hasActiveFilter = true;
        break;
      }
    }

    return hasActiveFilter;
  };

/**
 * Creates a selector function that extracts API filters from the given FiltersStoreState,
 * excluding certain fields based on the provided UI fields set.
 *
 * @param {Set<string>} uiFieldsSet - A Set containing strings representing UI fields to exclude.
 * @returns A selector function that takes a FiltersStoreState
 *   and returns an object containing filtered API filters.
 *
 * @example
 * const uiFields = new Set(['uiField1', 'uiField2']);
 * const apiFiltersSelector = getAPIFiltersSelector(uiFields);
 * const result = apiFiltersSelector({ filters: yourFiltersStoreState });
 * // Result will contain filtered API filters.
 */
export const getAPIFiltersSelector =
  <T extends Constrain>(uiFieldsSet: Set<string>) =>
  ({ filters }: FiltersStoreState<T>) => {
    const result = {} as Constrain;

    for (const key in filters) {
      const fKey = key as string;
      if (typeof filters[fKey] === "number" && filters[fKey] === Infinity) {
        // Skip number range max fields in case selected range has no upper limit
        continue;
      }
      if (uiFieldsSet.has(fKey)) {
        // Skip all fields that hold data relevant to UI state
        continue;
      }

      result[fKey] = filters[fKey];
    }

    return result;
  };
