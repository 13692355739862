import { useMemo, FC } from "react";

import { Text } from "@mantine/core";

import { type FilterUI } from "@Lib/types/base";
import { type RaceToScaleTechnologyUI } from "@Lib/types/sectorCompass";

import RaceToScaleCell from "./RaceToScaleCell";

type RaceToScaleRowProps = {
  isFirstRow: boolean;
  scales: FilterUI[];
  technology: string;
  companies: RaceToScaleTechnologyUI["companies"];
};

const RaceToScaleRow: FC<RaceToScaleRowProps> = ({ isFirstRow, scales, technology, companies }) => {
  const companiesMap = useMemo(
    () =>
      scales.reduce<Record<string, RaceToScaleTechnologyUI["companies"]>>((acc, scale) => {
        acc[scale.value] = companies.filter(({ scaleId }) => scaleId.toString() === scale.value);

        return acc;
      }, {}),
    [scales]
  );

  return (
    <>
      <Text weight={700} style={{ alignSelf: "center" }}>
        {technology}
      </Text>
      {scales.map(scale => {
        const companies = companiesMap[scale.value];

        return <RaceToScaleCell key={scale.value} isFirstRow={isFirstRow} companies={companies} />;
      })}
    </>
  );
};

export default RaceToScaleRow;
