import { FC } from "react";

import { Text, createStyles, TextProps } from "@mantine/core";

const useStyles = createStyles(theme => ({
  subTitle: {
    color: theme.colors.grey[9],
    fontSize: theme.fontSizes.sm,
    [theme.fn.smallerThan("lg")]: {
      fontSize: theme.fontSizes.xs,
    },
  },
}));

type HTMLParagraphSubTitleProps = TextProps & {
  subTitle: string;
};

const HTMLParagraphSubTitle: FC<HTMLParagraphSubTitleProps> = ({ subTitle, ...textProps }) => {
  const { classes } = useStyles();

  return (
    <Text {...textProps} className={classes.subTitle}>
      {subTitle}
    </Text>
  );
};

export default HTMLParagraphSubTitle;
