import { useEffect, useMemo, FC, PropsWithChildren } from "react";

import mixpanel from "mixpanel-browser";
import { useLocation } from "react-router-dom";

import { useAuthContext } from "@Context/Auth";
import juneAnalytics from "@Lib/config/juneAnalytics";
import { USER_TRACKING_EVENTS, PUBLIC_LANDING_ROUTES, DownloadFileTypesEnum } from "@Lib/constants";
import { isPublicRoute } from "@Lib/utils/routes";

import { UserTrackingContext, type UserTrackingContextValues } from "./UserTrackingContext";

const UserTrackingProvider: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const { user } = useAuthContext();

  //In case any of the auth logic is changed, both useEffects(here and in '/AuthProvider') need to be changed accordingly
  useEffect(() => {
    if (PUBLIC_LANDING_ROUTES.has(location.pathname)) {
      return;
    }

    // User data fetched and we are on a public route
    if (user && isPublicRoute(location.pathname)) {
      trackLogin();

      return;
    }
  }, [location.pathname, user]);

  const trackLogin = () => {
    if (!user) {
      return;
    }

    const context = { groupId: user?.organizationId };

    mixpanel.identify(user.uid);
    juneAnalytics.identify(user.uid);
    mixpanel.register({
      $email: user.email,
    });

    mixpanel.people.set({
      $email: user.email,
      $name: user.fullName,
      created: user.metadata.creationTime,
      last_login: user.metadata.lastSignInTime,
    });

    mixpanel.people.increment("logins");
    juneAnalytics.track(USER_TRACKING_EVENTS.login, {}, { context });
    mixpanel.track(USER_TRACKING_EVENTS.login);
  };

  const trackLogout: UserTrackingContextValues["trackLogout"] = () => {
    const context = { groupId: user?.organizationId };

    juneAnalytics.track(USER_TRACKING_EVENTS.logout, {}, { context });
    mixpanel.track(USER_TRACKING_EVENTS.logout);
  };

  const trackFilters: UserTrackingContextValues["trackFilters"] = properties => {
    const data = typeof properties.value === "string" ? properties.value : properties.value.join(", ");
    const context = { groupId: user?.organizationId };

    mixpanel.track(USER_TRACKING_EVENTS.facets, {
      "Filter Field": properties.filter,
      "Value(s)": data,
      Mechanism: properties.mechanism,
    });
    juneAnalytics.track(
      USER_TRACKING_EVENTS.facets,
      {
        "Filter Field": properties.filter,
        "Value(s)": data,
        Mechanism: properties.mechanism,
      },
      { context }
    );
  };

  const trackPageView: UserTrackingContextValues["trackPageView"] = properties => {
    if (!user || !user.organizationId) {
      return;
    }
    const context = { groupId: user.organizationId };

    juneAnalytics.page("Page", properties.pageName, {}, { context });
    juneAnalytics.track(
      USER_TRACKING_EVENTS.pageView,
      {
        "Page Name": properties.pageName,
        "Page URL": properties.path,
      },
      { context }
    );

    mixpanel.track(USER_TRACKING_EVENTS.pageView, {
      "Page Name": properties.pageName,
      "Page URL": properties.path,
    });
  };

  const trackExploreUnderlyingData: UserTrackingContextValues["trackExploreUnderlyingData"] = properties => {
    const context = { groupId: user?.organizationId };

    mixpanel.track(USER_TRACKING_EVENTS.exploreUnderlyingData, {
      "Chart Name": properties.chartName,
      Path: properties.path,
      Filters: properties.filters,
    });
    juneAnalytics.track(
      USER_TRACKING_EVENTS.exploreUnderlyingData,
      {
        "Chart Name": properties.chartName,
        Path: properties.path,
        Filters: properties.filters,
      },
      { context }
    );
  };

  const trackGSUsedResults: UserTrackingContextValues["trackGSUsedResults"] = properties => {
    const context = { groupId: user?.organizationId };
    mixpanel.track(USER_TRACKING_EVENTS.globalSearch, properties);
    juneAnalytics.track(USER_TRACKING_EVENTS.globalSearch, properties, { context });
  };

  const getSearchTermTracker: UserTrackingContextValues["getSearchTermTracker"] = searchFiled => properties => {
    const context = { groupId: user?.organizationId };

    mixpanel.track(USER_TRACKING_EVENTS.search, {
      "Search Field": searchFiled,
      "Search Term": properties.searchTerm,
    });
    juneAnalytics.track(
      USER_TRACKING_EVENTS.search,
      {
        "Search Field": searchFiled,
        "Search Term": properties.searchTerm,
      },
      { context }
    );
  };

  const trackDownload: UserTrackingContextValues["trackDownload"] = ({
    fileName,
    fileType = DownloadFileTypesEnum.excel, // default to csv
    pathname,
  }) => {
    const context = { groupId: user?.organizationId };

    mixpanel.track(USER_TRACKING_EVENTS.download, {
      "File Name": fileName,
      "File Type": fileType,
      Path: pathname,
    });
    if (fileType == DownloadFileTypesEnum.jpegImage) {
      // eventually remove calls to trackDownload for BE downloads
      // these are better tracked by the backend
      juneAnalytics.track(
        USER_TRACKING_EVENTS.download,
        {
          "File Name": fileName,
          "File Type": fileType,
          Path: pathname,
        },
        { context }
      );
    }
  };

  const trackDownloadError: UserTrackingContextValues["trackDownloadError"] = properties => {
    const context = { groupId: user?.organizationId };

    mixpanel.track(USER_TRACKING_EVENTS.downloadError, {
      "File Name": properties.fileName,
      Path: properties.pathname,
    });
    juneAnalytics.track(
      USER_TRACKING_EVENTS.downloadError,
      {
        "File Name": properties.fileName,
        Path: properties.pathname,
      },
      { context }
    );
  };

  const value = useMemo(
    () => ({
      trackPageView,
      trackFilters,
      trackDownload,
      trackDownloadError,
      trackExploreUnderlyingData,
      getSearchTermTracker,
      trackGSUsedResults,
      trackLogout,
    }),
    [user]
  );

  return <UserTrackingContext.Provider value={value}>{children}</UserTrackingContext.Provider>;
};

export default UserTrackingProvider;
