import { sectorBarsById, sectorDarkerBars, sectorLighterBars } from "@Context/Theme/chartsColors";
import { LegendUI } from "@Lib/types/sectorCompass";
import { generateDarkToLightColorShades } from "@Lib/utils/colors";

export const useGetLegendColorMap = (compassId: number, legend: LegendUI[] = []) => {
  const mainColor = sectorBarsById[compassId];
  const darkestColors = sectorDarkerBars[mainColor];
  const lightestColors = sectorLighterBars[mainColor];

  const count = legend.length;

  const colors = generateDarkToLightColorShades({
    baseColor: mainColor,
    endDarkColor: darkestColors,
    endLightColor: lightestColors,
    count,
  });

  const legendColorMap = legend.reduce((map, item, idx) => {
    map[item.id] = colors[idx];
    return map;
  }, {} as Record<string, string>);

  return { legendColorMap };
};
