import { useMemo, useCallback, FC } from "react";

import { useTranslation } from "react-i18next";

import CheckboxFilterBase from "@Components/charts/common/controls/CheckboxFilterBase";
import { useUserTrackingContext } from "@Context/UserTracking";
import { USER_TRACKING_PROPERTIES, SECTOR_COMPASS_PROJECT_OBSERVED_STATUSES } from "@Lib/constants";
import { useGetProjectStatusFilterData } from "@Lib/hooks/filters";
import { useDeploymentFilterStore } from "@Lib/store/sectorCompass/deployment";

const StatusFilter: FC = () => {
  const { t } = useTranslation();
  const { trackFilters } = useUserTrackingContext();

  const filterField = "project_progress_id";
  const { data } = useGetProjectStatusFilterData();

  const filterValue = useDeploymentFilterStore(useCallback(state => state.filters[filterField] || [], [filterField]));
  const setFilter = useDeploymentFilterStore(state => state.setFilter);
  const resetFilters = useDeploymentFilterStore(state => state.resetFilters);

  const selectOptions = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.filter(status => SECTOR_COMPASS_PROJECT_OBSERVED_STATUSES.includes(parseInt(status.value)));
  }, [data]);

  const hendleSetFilterIDs = useCallback((value: typeof filterValue) => {
    if (!value || !value.length) {
      resetFilters([filterField]);
      return;
    }

    setFilter(filterField, value);

    trackFilters({
      filter: filterField,
      value: value,
      mechanism: USER_TRACKING_PROPERTIES.filtersMechanism.sectorCompassDeployment,
    });
  }, []);

  return (
    <CheckboxFilterBase
      config={selectOptions}
      label={t("sectorCompassPage.sections.deployment.filters.status.placeholder")}
      filterIDs={filterValue}
      setFilterIDs={hendleSetFilterIDs}
      hightlight={Boolean(filterValue.length)}
    />
  );
};

export default StatusFilter;
