import { FC, CSSProperties } from "react";

import { Box, Tooltip, Text, createStyles } from "@mantine/core";

import { PROJECTS_PROGRESS_CHART_CONFIG } from "@Components/charts/config";
import { Z_INDEX_MAP } from "@Lib/constants/ui";
import { type RaceToScaleCompanyUI } from "@Lib/types/sectorCompass";
import { getContrastColor } from "@Lib/utils/colors";

const { capacityFormatter } = PROJECTS_PROGRESS_CHART_CONFIG;

const useStyles = createStyles(
  (
    theme,
    { capacityOffset, statusColor, dotDiameter }: { capacityOffset: number; statusColor: string; dotDiameter: number }
  ) => ({
    dot: {
      fontSize: theme.fontSizes.xs,
      fontWeight: theme.other.fontWeight.semiBold,
      color: getContrastColor(statusColor),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      top: "50%",
      left: `${capacityOffset * 100}%`,
      transform: "translateY(-50%)",
      width: dotDiameter,
      height: dotDiameter,
      borderRadius: "50%",
      backgroundColor: statusColor,
      cursor: "default",
      transition: "box-shadow 0.2s ease-in-out",
      zIndex: Z_INDEX_MAP.raceToScale.dot,
      "&:hover": {
        zIndex: Z_INDEX_MAP.raceToScale.dotHover,
        boxShadow: `0 0 8px 6px ${statusColor}3c`,
      },
    },
    tooltipRow: {
      display: "flex",
      alignItems: "center",
      fontWeight: theme.other.fontWeight.semiBold,
    },
    tooltipDot: {
      width: 10,
      height: 10,
      borderRadius: "50%",
      display: "inline-block",
      marginRight: 4,
    },
  })
);

type MultipleProjectsDotProps = {
  projects: RaceToScaleCompanyUI["projects"];
  dotDiameter: number;
  tooltipStyle: CSSProperties;
  handleMouseEnter: () => void;
  handleMouseLeave: () => void;
};

const MultipleProjectsDot: FC<MultipleProjectsDotProps> = ({
  projects,
  dotDiameter,
  tooltipStyle,
  handleMouseEnter,
  handleMouseLeave,
}) => {
  const { capacityOffset, statusColor } = projects[projects.length - 1];
  const { classes } = useStyles({
    capacityOffset: capacityOffset === null ? 0.5 : capacityOffset,
    statusColor,
    dotDiameter,
  });

  return (
    <Tooltip
      offset={8}
      label={projects.map(({ id, name, statusColor, capacityValue, capacityUnits }) => {
        const tooltipSuffix = capacityUnits ? `(${capacityFormatter(capacityValue)}${capacityUnits})` : "";
        return (
          <Text key={id} className={classes.tooltipRow}>
            <Box className={classes.tooltipDot} bg={statusColor} />
            {name}
            {tooltipSuffix.length > 0 && (
              <Text span weight={400}>
                &nbsp;{tooltipSuffix}
              </Text>
            )}
          </Text>
        );
      })}
      position="left"
      radius="sm"
      withArrow
      arrowPosition="side"
      arrowSize={8}
      style={tooltipStyle}
    >
      <Box className={classes.dot} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {projects.length}
      </Box>
    </Tooltip>
  );
};

export default MultipleProjectsDot;
