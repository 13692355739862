import { useState, useCallback } from "react";

export const useChartHoverState = <T>() => {
  const [activeIndex, setActiveIndex] = useState<number>();
  const [activeBar, setActiveBar] = useState<T | null>(null);
  const [isLineActive, setIsLineActive] = useState(false);

  const setActiveBarData = useCallback((input: { tooltipPayload: T[] }) => {
    setActiveBar(input.tooltipPayload[0]);
  }, []);

  const unsetActiveBarData = useCallback(() => {
    setActiveBar(null);
  }, []);

  const setLineActive = useCallback(() => {
    setIsLineActive(true);
  }, []);

  const setLineInactive = useCallback(() => {
    setIsLineActive(false);
  }, []);

  return {
    activeIndex,
    setActiveIndex,
    activeBar,
    setActiveBarData,
    unsetActiveBarData,
    isLineActive,
    setLineActive,
    setLineInactive,
  };
};
