import { createContext, useContext } from "react";

import { DownloadFileTypesEnum } from "@Lib/constants/api";

export type UserTrackingContextValues = {
  trackLogout: () => void;
  trackPageView: (properties: { path: string; pageName: string }) => void;
  trackFilters: (properties: { filter: string; value: string | string[]; mechanism: string }) => void;
  trackDownloadError: (properties: { fileName: string; pathname?: string }) => void;
  trackExploreUnderlyingData: (properties: { chartName: string; path: string; filters: string }) => void;
  trackGSUsedResults: (properties: {
    resultType: "company" | "investor" | "taxonomy" | "project";
    resultValue: string;
  }) => void;
  getSearchTermTracker: (searchFiled: string) => (properties: { searchTerm: string }) => void;
  trackDownload: (properties: { fileName: string; fileType?: DownloadFileTypesEnum; pathname?: string }) => void;
};

export const UserTrackingContext = createContext<UserTrackingContextValues>(null!);

UserTrackingContext.displayName = "UserTrackingContext";

export const useUserTrackingContext = () => useContext(UserTrackingContext);
