import { FC, PropsWithChildren, ReactNode } from "react";

import { Box, Group, Text, createStyles } from "@mantine/core";
import { Trans } from "react-i18next";

import { ProjectsGroupByEnum } from "@Lib/constants/api";
import { DOWNLOAD_IGNORE_CLASS } from "@Lib/constants/ui";

const useStyles = createStyles((theme, hasButtons: boolean) => ({
  container: {
    height: "40vh",
    width: "inherit",
    fontSize: theme.fontSizes.sm,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  titleRow: {
    padding: theme.spacing.sm,
    justifyContent: "space-between",
    flexWrap: "nowrap",
  },
  chartLabel: {
    fontSize: theme.fontSizes.sm,
    fontWeight: theme.other.fontWeight.semiBold,
  },
  chart: {
    flexGrow: 1,
    flexShrink: 1,
    paddingInline: theme.spacing.sm,
    height: hasButtons ? "calc(40vh - 62px)" : "calc(40vh - 50px)",
  },
}));

type ProjectsChartContainerProps = PropsWithChildren<{
  id: string;
  titleKey: ProjectsGroupByEnum | "operational";
  units?: string;
  buttons?: ReactNode;
  infoButton?: ReactNode;
}>;

const ProjectsChartContainer: FC<ProjectsChartContainerProps> = ({
  id,
  titleKey,
  units,
  buttons,
  infoButton,
  children,
}) => {
  const { classes } = useStyles(Boolean(buttons));

  return (
    <Box id={id} className={classes.container}>
      <Group className={classes.titleRow} position="apart" noWrap>
        <Group spacing={0}>
          <Text className={classes.chartLabel}>
            <Trans
              i18nKey={`sectorCompassPage.sections.deployment.${titleKey}`}
              values={{ appendix: units ? `(${units})` : "", interpolation: { escapeValue: false } }}
              components={{
                unbold: <Text span weight={400} />,
              }}
            />
          </Text>
          {infoButton && <Box className={DOWNLOAD_IGNORE_CLASS}>{infoButton}</Box>}
        </Group>
        <Group spacing="xs" className={DOWNLOAD_IGNORE_CLASS}>
          {buttons}
        </Group>
      </Group>
      <Box className={classes.chart}>{children}</Box>
    </Box>
  );
};

export default ProjectsChartContainer;
