import { Component, ErrorInfo, ReactNode } from "react";

import { Center, Stack, Text } from "@mantine/core";

const isDev = process.env.NODE_ENV === "development";

interface Props {
  isRoot?: boolean;
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  isReloadError: boolean;
}

const RELOAD_ERROR_MESSAGE = "failed to fetch dynamically imported module";
const isRefetchError = (error?: Error): boolean =>
  Boolean(error?.message.toLocaleLowerCase().includes(RELOAD_ERROR_MESSAGE));

class ErrorBoundary extends Component<Props, State> {
  public static defaultProps: Props;

  public state: State = {
    hasError: false,
    isReloadError: false,
  };

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, isReloadError: isRefetchError(error) };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { isRoot } = this.props;
    // eslint-disable-next-line no-console
    console.error("Uncaught error: ", error, errorInfo);

    if (isDev) {
      return;
    }

    if (isRoot && isRefetchError(error)) {
      window.location.reload();
      return;
    }
  }

  public render() {
    const { isRoot } = this.props;
    const { hasError, isReloadError } = this.state;

    if (isReloadError && isRoot) {
      return (
        <Center w={"100vw"} h={"100vh"} p={20}>
          <Text>A new version of the platform is available, reloading...</Text>
        </Center>
      );
    }

    if (hasError) {
      return (
        <Center w={"100vw"} h={"100vh"} p={20}>
          <Stack spacing={"xs"}>
            <Text color="red">An error occurred! We are looking into this...</Text>
            {isRoot && (
              <Text size={"sm"}>
                Please refresh the page, try again in a couple minutes, or contact{" "}
                <a href="mailto:support@sightlineclimate.com">support@sightlineclimate.com</a>.
              </Text>
            )}
          </Stack>
        </Center>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.defaultProps = {
  isRoot: false,
};

export default ErrorBoundary;
