import { useCallback } from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import fileDownload from "js-file-download";
import { useTranslation } from "react-i18next";

import { ReactComponent as DownloadFileIcon } from "@Assets/download-file.svg";
import { useAuthContext } from "@Context/Auth";
import { useUserTrackingContext } from "@Context/UserTracking";
import { QUERIES } from "@Lib/constants";
import { DealsGroupByEnum } from "@Lib/constants/api";
import { getDealsChartsDataDownload, requestDealsChartsDataDownload } from "@Lib/services/deals";
import { type DealsChartServiceInput } from "@Lib/types/deals";
import { getFileNameFromHeader } from "@Lib/utils/download";
import { DownloadNotifiers, showErrorNotification, showInfoNotification } from "@Lib/utils/notifications";

import { useGetAllNotifications, useStartPolling } from "../downloadNotifications";

const useRequestDownload = ({
  datasetType,
  storeFilters,
  primarySectorId,
}: Omit<DealsChartServiceInput, "chartVariant">) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { handleStartPolling } = useStartPolling();
  const { refetch: refetchNotifications } = useGetAllNotifications();

  return useMutation({
    mutationFn: () =>
      requestDealsChartsDataDownload({
        chartVariant: DealsGroupByEnum.stage,
        datasetType,
        storeFilters,
        primarySectorId,
      }),
    onSuccess: ({ id }) => {
      refetchNotifications();
      handleStartPolling(id);
      queryClient.invalidateQueries(QUERIES.getUserData());

      showInfoNotification({
        color: "grey.2",
        title: t("notifications.download.request.title"),
        message: t("notifications.download.request.message"),
        icon: <DownloadFileIcon color="grey" />,
      });
    },
    onError: () => {
      showErrorNotification({
        message: t("notifications.download.error.message"),
      });
    },
  });
};

const useDownload = ({ datasetType, storeFilters, primarySectorId }: Omit<DealsChartServiceInput, "chartVariant">) => {
  const { t } = useTranslation();
  const notifiers = new DownloadNotifiers("deals-stage-chart-download");
  const { trackDownload, trackDownloadError } = useUserTrackingContext();

  const fallbackName = `${t("download.fileNames.dealsStageChart")}.xlsx`;

  return useMutation({
    mutationFn: () => {
      notifiers.downloadStart();
      return getDealsChartsDataDownload({
        chartVariant: DealsGroupByEnum.stage,
        datasetType,
        storeFilters,
        primarySectorId,
      });
    },
    onSuccess: ({ data, headers }) => {
      const fileName = getFileNameFromHeader(headers, fallbackName);
      fileDownload(data, fileName);

      notifiers.downloadSuccess();
      trackDownload({ fileName });
    },
    onError: () => {
      notifiers.downloadError();
      trackDownloadError({ fileName: fallbackName });
    },
  });
};

export const useStageChartDownload = ({
  datasetType,
  storeFilters,
  primarySectorId,
}: Omit<DealsChartServiceInput, "chartVariant">) => {
  const { mutate, isLoading } = useRequestDownload({
    datasetType,
    storeFilters,
    primarySectorId,
  });
  const { mutate: fastMutate, isLoading: isFastMutateLoading } = useDownload({
    datasetType,
    storeFilters,
    primarySectorId,
  });

  const { user } = useAuthContext();
  const isTrialUser = user?.isTrialUser;

  const mutateSwicther = useCallback(() => {
    if (!isTrialUser) {
      return fastMutate();
    }

    return mutate();
  }, [mutate, fastMutate]);

  return { mutate: mutateSwicther, isLoading: isFastMutateLoading || isLoading };
};
