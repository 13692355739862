import { FC, PropsWithChildren } from "react";

import { Box, createStyles } from "@mantine/core";

const useStyles = createStyles(theme => ({
  charts: {
    maxWidth: "100%",
    "& > div:first-of-type": {
      borderBottom: theme.other.getDefaultBorder(theme),
    },
    [theme.fn.largerThan("md")]: {
      display: "flex",
      flexDirection: "row",
      "& > div": {
        flex: 1,
        width: "50%",
        "&:first-of-type": {
          borderRight: theme.other.getDefaultBorder(theme),
          borderBottom: "none",
        },
      },
    },
  },
}));

const ResponsiveWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { classes } = useStyles();
  return <Box className={classes.charts}>{children}</Box>;
};

export default ResponsiveWrapper;
