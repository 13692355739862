import { HmacSHA256, enc } from "crypto-js";

const ENCRYPTION_KEY = import.meta.env.VITE_ENCRYPTION_KEY;
const TIMESTAMP_OFFSET = parseInt(import.meta.env.VITE_API_TIMESTAMP_OFFSET);
const STRING_TO_SIGN_DELIMITER = ",";

const signWithSecretKey = (data: string) => {
  const signature = HmacSHA256(data, ENCRYPTION_KEY);

  return signature.toString(enc.Hex);
};

export const getCurrentTimestampUTC = (delta = 0) => {
  return Math.floor(Date.now() / 1000) + delta;
};

export const getSignatureHeaders = (
  requestedPath: string,
  serverTimeDelta: number,
  requestParams: Record<string, string> = {}
): Record<string, string> => {
  // Timestamps
  const currentTimestampUTC = getCurrentTimestampUTC(serverTimeDelta);
  const offsetTimestampUTC = currentTimestampUTC + TIMESTAMP_OFFSET;

  // Params
  const params: Record<string, string> = { timestamp: offsetTimestampUTC.toString(), ...requestParams };
  const paramsKeys: string[] = [];

  Object.keys(params).forEach(key => {
    const currParam = params[key];
    if (currParam === null || currParam === undefined || (Array.isArray(currParam) && currParam.length === 0)) {
      return;
    }

    if (Array.isArray(currParam) && currParam.length > 0) {
      currParam.forEach(() => {
        paramsKeys.push(key);
      });

      return;
    }

    if (currParam !== null && currParam !== "") {
      paramsKeys.push(key);
    }
  });

  const sortedParamsString = paramsKeys.sort().join(STRING_TO_SIGN_DELIMITER);
  const result = `${requestedPath}${STRING_TO_SIGN_DELIMITER}${sortedParamsString}${STRING_TO_SIGN_DELIMITER}${currentTimestampUTC}`;

  return { Signature: signWithSecretKey(result), "Request-timestamp": offsetTimestampUTC.toString() };
};
